import './styles.css';

import React from 'react';
import BrewingMethods from '../../../constants/brewingMethods';
import Icon from '@cxeweb/pattern-library/lib/components/icon';
import iconPaths from '@cxeweb/pattern-library/lib/icons/__index';
import { useTranslation } from 'react-i18next';

const BrewMethod = ({ method, isEnglish }) => {
    const { t } = useTranslation();
    const data = BrewingMethods[method] || '';

    const noLinkMethods = ['Café infusé à froid', 'Espresso'];
    const noLink = !isEnglish && noLinkMethods.some((m) => method === m);

    const onClick = () => {
        if (!!data.url && !noLink) {
            window.location.href = data.url;
        }
    };

    return (
        <div className={'bm-container pt3 pb3'} onClick={onClick}>
            <img src={data.icon} style={{ height: '60px' }} alt={'icon'} />
            <div className={'pl3 pr3'}>
                <p className={'text-sm mb2 text-semibold'}>{method}</p>
                <p className={'text-xxs color-textBlackSoft'}>{t(data.description)}</p>
            </div>
            {!noLink && (
                <div className='text-right' style={{ width: '5%' }}>
                    <Icon path={iconPaths.chevronRight} size='19px' className='color-textBlackSoft text-right' />
                </div>
            )}
        </div>
    );
};

export default BrewMethod;
