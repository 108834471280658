import { GET_ORIGIN_DETAILS, GET_ROASTING_PLANT, GET_PRODUCT_DETAILS } from './types';
import { createWebserviceRequestParams } from '../webservices';

export function getFinishedLotOriginDetails(productLotId) {
    const requestData = {};
    requestData.type = GET_ORIGIN_DETAILS;
    requestData.productId = productLotId;
    const requestParams = createWebserviceRequestParams(requestData);
    return {
        type: GET_ORIGIN_DETAILS,
        requestParams,
    };
}

export function getRoastingPlant(orgCode) {
    return {
        type: GET_ROASTING_PLANT,
        orgCode,
    };
}

export function getProductDetails(sku) {
    return {
        type: GET_PRODUCT_DETAILS,
        sku,
    };
}

export function testAction() {
    return {
        type: 'TESTING_GET_TEXT',
    };
}
