import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingIndicator from '@cxeweb/pattern-library/lib/components/loading-indicator';
import HomeView from './HomeView';
import { testAction, getFinishedLotOriginDetails } from '../../actions';
import { COUNTRY_DETAILS_PURGE, HIDE_WELCOME_MESSAGE, PROFILE_PURGE, STORIES_PURGE } from '../../actions/types';
import { Detail } from '../common/DetailPage';
import { ROASTING_PLANT_CODE_TO_STATE } from '../../constants/utilFuctions';
import { ai } from '../../telemetryService';
import AICustomEvents from '../../constants/appInsightEvents';
import { Navigate, useParams } from 'react-router-dom';
import { ERROR_PAGE_PATH } from '../../constants/paths';
import { useTranslation } from 'react-i18next';

const Home = ({ match }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { lotId = '' } = useParams();

    const roastInfo = useSelector((state) => state.roastInfo);
    const scanner = useSelector((state) => state.scanner);
    const loadingIndicator = useSelector((state) => state.loadingIndicator.showActivityIndicator);
    const showMessage = useSelector((state) => state.welcomeMessage.showMessage);
    const productSku = useSelector((state) => state.originData.productSku);
    const organizationCode = useSelector((state) => state.originData.organizationCode);
    const countryPercentList = useSelector((state) => state.originData.countryPercentList);
    const fetchOriginDetailsError = useSelector((state) => state.roastInfo.showErrorView);
    const language = useSelector((state) => state.translation.language);

    useEffect(() => {
        window.scrollTo(0, 0);
        ai.trackEvent({ name: AICustomEvents.homePage });
        dispatch(getFinishedLotOriginDetails(lotId));

        // Purging any data in stories and country state
        dispatch({ type: COUNTRY_DETAILS_PURGE });
        dispatch({ type: STORIES_PURGE });
        dispatch({ type: PROFILE_PURGE });
    }, [lotId, dispatch]);

    useEffect(() => {
        if (language) {
            dispatch(getFinishedLotOriginDetails(lotId));
        }
    }, [lotId, language, dispatch]);

    const renderHomePage = () => {
        const { coffeeName = '', description, roast, shortDescription, bagImageName } = roastInfo;

        const countries = countryPercentList.map((x) => x.country);

        const [countryName = ''] = countries;
        const originTitle =
            countries.length > 1
                ? t('home.originRegionTitle', { count: countries.length })
                : t('home.originCountryTitle', { country: countryName });

        const roastedLocation = t('home.roastedLocation', { location: ROASTING_PLANT_CODE_TO_STATE(organizationCode) });

        return (
            productSku !== '' &&
            coffeeName.length !== 0 && (
                <Detail title={t('home.pageHeader')} backTitle=' '>
                    <HomeView
                        productLotId={lotId}
                        showMessage={showMessage}
                        roastName={coffeeName}
                        roastDescription={description}
                        roastBlendType={roast}
                        coffeeBagImage={bagImageName}
                        originTitle={originTitle}
                        roastedLocation={roastedLocation}
                        coffeeDetails={shortDescription}
                        countryPercentList={countryPercentList}
                        isEnglish={language.includes('en')}
                    />
                </Detail>
            )
        );
    };

    if (scanner.scanError || fetchOriginDetailsError) {
        return <Navigate to={ERROR_PAGE_PATH} />;
    }

    return (
        <div style={{ paddingBottom: '1px' }}>
            {renderHomePage()}
            <LoadingIndicator active={loadingIndicator} />
        </div>
    );
};

export { Home };
