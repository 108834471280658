import './styles.css';

import React, { Component } from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Carousel from '@cxeweb/pattern-library/lib/components/carousel';
import PropTypes from 'prop-types';
import Images from '../../../images';

class CarouselView extends Component {
    constructor(props) {
        super(props);
        this.data = this.props.stories;
    }

    onClick = (link) => {
        window.location.assign(link);
    };

    getSlides(slideNum) {
        let totalSlides = slideNum;
        const slides = [];
        let i = 0;
        while (totalSlides--) {
            const sizeClasses = 'size10of12';
            let link = this.data[i].link;
            slides.push(
                <div className={`gridItem ${sizeClasses}`} key={totalSlides}>
                    <Card
                        style={{ minHeight: '120px', maxHeight: '130px' }}
                        imagePosition='left'
                        imageUrl={Images[this.data[i].image]}
                        contentClasses='pl2 pr2 pt1 pb1 cd-card-content-center'
                        containerClasses='ml3 mr2 mb1 mt2'
                        imageSize='sm'
                        imageFit='cover'
                        onClick={() => this.onClick(link)}
                    >
                        <p className='valign-middle'>{this.data[i].text}</p>
                    </Card>
                </div>
            );
            i++;
        }
        return slides;
    }

    render() {
        return (
            <div className='mt1'>
                <Carousel
                    className='px3 pb1'
                    messages={{
                        ariaLabelPrev: 'Previous',
                        ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                    }}
                    variableWidth
                    easingFunction='linear'
                    handleTouch
                >
                    {this.getSlides(this.data.length)}
                </Carousel>
            </div>
        );
    }
}

CarouselView.defaultProps = {
    index: 0,
};

CarouselView.propTypes = {
    stories: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export { CarouselView };
