import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import RegionView from './RegionsView';
import { getCafePracticeStats } from '../../actions';
import { CLEAR_ROAST_INFO } from '../../actions/types';

const RegionViewComponent = ({ fetchCafePracticeStats, cafePracticeStats, clearRoastInfo }) => {
    window.scrollTo(0, 0);

    let { region } = useParams();
    useEffect(() => {
        clearRoastInfo();
        fetchCafePracticeStats(region);
    }, [region, fetchCafePracticeStats, clearRoastInfo]);

    return <RegionView cafePracticeStats={cafePracticeStats} />;
};

function mapStateToProps(state) {
    return {
        cafePracticeStats: state.cafePracticeStats,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchCafePracticeStats: (region) => {
            dispatch(getCafePracticeStats(region));
        },
        clearRoastInfo: () => dispatch({ type: CLEAR_ROAST_INFO }),
    };
}

const RegionViewContainer = connect(mapStateToProps, mapDispatchToProps)(RegionViewComponent);

export default RegionViewContainer;
