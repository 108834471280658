import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ContentCrate from '@cxeweb/pattern-library/lib/components/content-crate';

import './styles.css';

const ContentArea = ({ children, className, size, ...otherProps }) => {
    return (
        <ContentCrate>
            <div
                className={classnames(`sm-pt3 md-pt3 lg-pt7 mx-auto`, {
                    narrow: size === 'narrow',
                    medium: size === 'medium',
                    wide: size === 'wide',
                    [className]: Boolean(className),
                })}
                {...otherProps}
            >
                <div>{children}</div>
            </div>
        </ContentCrate>
    );
};

ContentArea.propTypes = {
    size: PropTypes.oneOf(['narrow', 'medium', 'wide']).isRequired,
};

ContentArea.defaultProps = {
    size: 'wide',
};

export { ContentArea };
