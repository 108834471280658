import './styles.css';

import React from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Button from '@cxeweb/pattern-library/lib/components/button';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
//import Text from '@cxeweb/pattern-library/lib/components/text';
import Images from '../../images';
import { Footer } from '../common/Footer';
import Profile from '../common/Profile';
import { useTranslation } from 'react-i18next';
import { HATileCarousel } from '../HAFarmOverview/HATileCarousel';
import { HAFooter } from '../HAFarmOverview/HAFooter';
import cdnUrls from '../../constants/cdnUrls';
import translationData from '../../locales/en/HAFarm.json';
import { SocialShare } from '../common';
import Sticky from 'react-sticky-el';

const HAFarmRoastView = ({ roast }) => {
    const { t } = useTranslation();
    const stories = translationData.humanStory.stories;

    return (
        <>
            <Card style={{ background: '#edebe9' }} imagePosition='top'>
                <div>
                    <ImageLoader className='banner-image' src={cdnUrls.getHAFarmBannerImage(roast)} alt='roasting' />
                    {roast !== 'limited' && (
                        <div className='coffee-notes'>{t(`HAFarm:coffeeNotes.${roast}.flavorNotes`)}</div>
                    )}
                    <div className={`${roast}-seperator`}></div>
                    <div className='product-section-container' style={{ backgroundColor: 'rgb(237, 235, 233)' }}>
                        <table className={'table'}>
                            <tbody className='text-sm'>
                                <tr>
                                    <td className='text-bold'>{t('HAFarm:commonLabel.growingRegion')}</td>
                                    <td className='flex-grow'>{t(`HAFarm:coffeeNotes.${roast}.growingRegion`)}</td>
                                </tr>
                                {roast !== 'limited' && (
                                    <>
                                        <tr>
                                            <td className='text-bold'>{t('HAFarm:commonLabel.body')}</td>
                                            <td className='flex-grow'>{t(`HAFarm:coffeeNotes.${roast}.body`)}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-bold'>{t('HAFarm:commonLabel.processing')}</td>
                                            <td className='flex-grow'>{t(`HAFarm:coffeeNotes.${roast}.processing`)}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-bold'>{t('HAFarm:commonLabel.acidity')}</td>
                                            <td className='flex-grow'>{t(`HAFarm:coffeeNotes.${roast}.acidity`)}</td>
                                        </tr>
                                        <tr>
                                            <td className='text-bold'>{t('HAFarm:commonLabel.flavorPairing')}</td>
                                            <td className='flex-grow'>
                                                {t(`HAFarm:coffeeNotes.${roast}.flavorPairing`)}
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {roast !== 'limited' ? ( //blond, medium, dark
                    <>
                        <div>
                            <p className='text-sm pl3 mb3'>
                                {t('HAFarm:coffeeDesc.regularCoffee.decadesLong')}
                                <a
                                    class='color-greenAccent'
                                    href='https://www.starbuckscoffeefarm.com/en/'
                                    target='_blank'
                                >
                                    {t('HAFarm:coffeeDesc.regularCoffee.linkHA')}
                                </a>
                                {t('HAFarm:coffeeDesc.regularCoffee.onlyFarm')}
                                <a
                                    class='color-greenAccent'
                                    href='https://stories.starbucks.com/press/2018/starbucks-farmer-support-centers/'
                                    target='_blank'
                                >
                                    {t('HAFarm:coffeeDesc.regularCoffee.oneOfTen')}
                                </a>{' '}
                                {t('HAFarm:coffeeDesc.regularCoffee.purpose')}
                            </p>
                        </div>
                        <div>
                            <Heading
                                disableBoxShadow
                                style={{ background: '#f7f7f7' }}
                                className='pl3 pt3 pb2 heading-xs text-semibold'
                                tagName='h3'
                                size='md'
                            >
                                {t('HAFarm:growingRegion.header')}
                            </Heading>
                            <div>
                                <ImageLoader src={Images.CRIBanner} alt='country banner' />
                                <div className={'flex flex-row pt2'}>
                                    <div className={'flex flex-column items-center p1'}>
                                        <img src={Images.keyElevation} alt={'keyElevation'} />
                                        <p className='text-center text-xxs'>{t('country.keys.elevation')}</p>
                                    </div>
                                    <div className={'flex flex-column items-center p1'}>
                                        <img src={Images.keyGrowArea} alt={'keyGrowArea'} />
                                        <p className='text-center text-xxs'>{t('country.keys.region')}</p>
                                    </div>
                                    <div className={'flex flex-column items-center p1'}>
                                        <img src={Images.keyFSC} alt={'keyFSC'} />
                                        <p className='text-center text-xxs'>{t('country.keys.fsc')}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className='text-sm p3 mb3'>{t('HAFarm:growingRegion.text')}</p>
                            </div>
                        </div>
                        <div>
                            <Heading className='pl3 heading-xs text-semibold' tagName='h3' size='md'>
                                {t('HAFarm:humanStory.header')}
                            </Heading>

                            {stories.map(({ img }, idx) => (
                                <Profile
                                    picture={cdnUrls.getHAFarmRoastProfileImage(img)}
                                    story={t(`HAFarm:humanStory.stories.${idx}.desc`)}
                                    preferredName={t(`HAFarm:humanStory.stories.${idx}.personName`)}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    //limited release
                    <>
                        <div>
                            <p className='text-sm pl3 mb3'>{t('HAFarm:coffeeDesc.limitedCoffee')}</p>
                        </div>
                        <div>
                            <Heading
                                className='pl3 pt3 pb3 heading-xs text-semibold'
                                tagName='h3'
                                size='md'
                                style={{ background: '#f7f7f7' }}
                            >
                                {t('HAFarm:limitedContext.plot.header')}
                            </Heading>
                            <div>
                                <ImageLoader src={cdnUrls.getHAFarmImage(`blonde/CarlosMario.jpg`)} alt='CarlosMario' />
                            </div>
                            <div>
                                <p className='text-sm p3'>{t('HAFarm:limitedContext.plot.desc')}</p>
                            </div>
                        </div>
                        <div className='pl3 pr3 pt3'>
                            <Heading className='pb3 heading-xs text-semibold' tagName='h3' size='md'>
                                {t('HAFarm:limitedContext.director.header')}
                            </Heading>
                            <div>
                                <ImageLoader
                                    src={cdnUrls.getHAFarmImage(`blonde/CarlosMarioNursery.jpg`)}
                                    alt='CarlosMarioNursery'
                                />
                            </div>
                            <div>
                                <p className='pt3 pb3 text-sm'>{t('HAFarm:limitedContext.director.desc1')}</p>
                            </div>
                            <div>
                                <p className='text-sm'>{t('HAFarm:limitedContext.director.desc2')}</p>
                            </div>
                        </div>
                        <div className='pl3 pr3 pt3'>
                            <Heading className='pb3 heading-xs text-semibold' tagName='h3' size='md'>
                                {t('HAFarm:limitedContext.ResearchDevelopment.header')}
                            </Heading>
                            <div>
                                <ImageLoader
                                    src={cdnUrls.getHAFarmImage(`blonde/SaraBogantes.jpg`)}
                                    alt='CarlosMarioNursery'
                                />
                            </div>
                            <div>
                                <p className='text-sm pt3 pb3'>
                                    {t('HAFarm:limitedContext.ResearchDevelopment.desc1')}
                                </p>
                            </div>
                            <div>
                                <ImageLoader
                                    src={cdnUrls.getHAFarmImage(`blonde/CrossPollination.jpg`)}
                                    alt='CrossPollination'
                                />
                            </div>
                            <div>
                                <p className='text-sm pt3 pb3'>
                                    {t('HAFarm:limitedContext.ResearchDevelopment.desc2')}
                                </p>
                            </div>
                        </div>
                    </>
                )}
                <div>
                    <div>
                        <ImageLoader src={cdnUrls.getHAFarmImage(`HAJourney.jpg`)} alt='HA Farm' />
                    </div>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('HAFarm:moreThanFarm.header')}
                    </Heading>
                    <div>
                        <p className='text-sm p3'>{t('HAFarm:moreThanFarm.desc')}</p>
                    </div>
                    <div className='text-center mb4'>
                        <a
                            href='https://traceability.starbucks.com/#/hacienda-alsacia/overview'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <Button visualStyle='positive' className='pl6 pr6 pt2 pb2'>
                                {t('HAFarm:moreThanFarm.button')}
                            </Button>
                        </a>
                    </div>
                </div>
            </Card>
            <div className='hm-social-share'>
                <Sticky
                    className='story-stick'
                    mode='bottom'
                    stickyClassName='story-stick--sticky'
                    positionRecheckInterval={25}
                >
                    <SocialShare
                        postTitle={t('home.socialShare')}
                        postUrl={`https://traceability.starbucks.com/#/`}
                        image={Images.globalAcdemy}
                        style={{
                            paddingBottom: '60px',
                        }}
                    />
                </Sticky>
            </div>
            <Card disableBoxShadow style={{ background: '#f7f7f7' }}>
                <HATileCarousel />
            </Card>
            <HAFooter />
        </>
    );
};

export { HAFarmRoastView };
