import React, { Component } from 'react';
import LoadingIndicator from '@cxeweb/pattern-library/lib/components/loading-indicator';
import { HAFarmOverviewView } from './HAFarmOverviewView';
import { Detail } from '../common/DetailPage';
import { withTranslation } from 'react-i18next';

class HAFarmOverviewContainer extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <div style={{ paddingBottom: '1px' }}>
                <Detail title={''} backTitle={' '}>
                    <HAFarmOverviewView />
                </Detail>
                <LoadingIndicator active={this.props.loadingIndicator} />
            </div>
        );
    }
}

const HAFarmOverview = withTranslation()(HAFarmOverviewContainer);

export { HAFarmOverview };
