export const LATIN_AMERICA_PRODUCTS = [
    { sku: '11130114', name: 'product:pikePlaceRoast' }, //11028470 - mojito: 11130114
    { sku: '11130112', name: 'product:espressoRoast' }, //11028472 - mojito: 11130112
    { sku: '11130113', name: 'product:fairTradeItalianRoast' }, //11028506 - mojito: 11130113
    { sku: '11130046', name: 'product:verandaBlend' }, //11028510 - mojito: 11130046
    { sku: '00195704', name: 'product:iceCoffeeBlend' },
];

export const AFRICA_PRODUCTS = [
    { sku: '11130108', name: 'product:sirenBlend' }, //11104434 - mojito: 11130108
    { sku: '11130045', name: 'product:blondeEspresso' }, //11099682 - mojito: 11130045
    { sku: '11048714', name: 'product:coldBrew' },
];

export const ASIA_PACIFIC_PRODUCTS = [
    { sku: '11130111', name: 'product:komodoDragonBlend' }, //11028501 -mojito: 11130111
    { sku: '11130110', name: 'product:sumatra' }, //11028479 -mojito: 11130110
    { sku: '11130115', name: 'product:verona' }, //11028503 -mojito: 11130115
    { sku: '11130107', name: 'product:yukon' }, //11028476 -mojito: 11130107
];
