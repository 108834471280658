import React from 'react';

const Text = ({ children, className = '', style }) => {
    return (
        <div style={style}>
            <p style={styles.textStyle} className={`text-sm ${className}`}>
                {children}
            </p>
        </div>
    );
};

export default Text;

const styles = {
    textStyle: {
        lineHeight: '1.5em',
    },
};
