import { PURGE } from 'redux-persist/es/constants';
import { CAFE_PRACTICE_STATS_FAIL, CAFE_PRACTICE_STATS_SUCCESS } from '../actions/types';

const INITIAL_STATE = {
    totalFarmCount: 0,
    smallFarmPer: 0,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CAFE_PRACTICE_STATS_SUCCESS: {
            let per = action.smallFarmPer || 0;

            return {
                ...state,
                totalFarmCount: action.totalFarmCount,
                smallFarmPer: per.toFixed(1),
            };
        }
        case CAFE_PRACTICE_STATS_FAIL:
        case PURGE: {
            return {
                ...state,
                totalFarmCount: 0,
                smallFarmPer: 0,
            };
        }
        default:
            return state;
    }
};
