import { GET_STORIES } from './types';
import { createWebserviceRequestParams } from '../webservices';

export function getStoriesAction (topic) {
    const requestData = {};
    requestData.type = GET_STORIES;
    requestData.topic = topic;
    const requestParams = createWebserviceRequestParams(requestData);
    return {
        type: GET_STORIES,
        requestParams
    };
}