import { GET_CAFE_PRACTICE_STATS } from './types';
import { createWebserviceRequestParams } from '../webservices';

export function getCafePracticeStats(region) {
    const requestData = {};
    requestData.type = GET_CAFE_PRACTICE_STATS;
    requestData.region = region;
    const requestParams = createWebserviceRequestParams(requestData);
    return {
        type: GET_CAFE_PRACTICE_STATS,
        requestParams,
        region,
    };
}
