import Images from '../images';

export const COUNTRY_BANNERS = [
    { code: 'BDI', image: Images.BDIBanner },
    { code: 'BOL', image: Images.BOLBanner },
    { code: 'BRA', image: Images.BRABanner },
    { code: 'CHN', image: Images.CHNBanner },
    { code: 'COD', image: Images.CODBanner },
    { code: 'COL', image: Images.COLBanner },
    { code: 'CPV', image: Images.CPVBanner },
    { code: 'CRI', image: Images.CRIBanner },
    { code: 'DOM', image: Images.DOMBanner },
    { code: 'ECU', image: Images.ECUBanner },
    { code: 'ETH', image: Images.ETHBanner },
    { code: 'GPS', image: Images.GPSBanner },
    { code: 'GTM', image: Images.GTMBanner },
    { code: 'HND', image: Images.HNDBanner },
    { code: 'HTI', image: Images.HTIBanner },
    { code: 'IDN', image: Images.IDNBALBanner },
    { code: 'IDNAGS', image: Images.IDNAGSBanner },
    { code: 'IDNBAL', image: Images.IDNBALBanner },
    { code: 'IDNJAV', image: Images.IDNJAVBanner },
    { code: 'IDNSUL', image: Images.IDNSULBanner },
    { code: 'IDNSUM', image: Images.IDNSUMBanner },
    { code: 'IND', image: Images.INDBanner },
    { code: 'JAM', image: Images.JAMBanner },
    { code: 'KEN', image: Images.KENBanner },
    { code: 'LAO', image: Images.LAOBanner },
    { code: 'MEX', image: Images.MEXBanner },
    { code: 'MWI', image: Images.MWIBanner },
    { code: 'NIC', image: Images.NICBanner },
    { code: 'PAN', image: Images.PANBanner },
    { code: 'PER', image: Images.PERBanner },
    { code: 'PNG', image: Images.PNGBanner },
    { code: 'PRI', image: Images.PRIBanner },
    { code: 'RWA', image: Images.RWABanner },
    { code: 'SHN', image: Images.SHNBanner },
    { code: 'SLV', image: Images.SLVBanner },
    { code: 'THA', image: Images.THABanner },
    { code: 'TLS', image: Images.TLSBanner },
    { code: 'TZA', image: Images.TZABanner },
    { code: 'UGA', image: Images.UGABanner },
    { code: 'USA', image: Images.USABanner },
    { code: 'VNM', image: Images.VNMBanner },
    { code: 'YEM', image: Images.YEMBanner },
    { code: 'ZMB', image: Images.ZMBBanner },
];
