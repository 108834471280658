import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loadingIndicator from './loadingReducer';
import roastInfo from './roastInfoReducer';
import imageScanner from './scannerReducer';
import welcomeMessage from './welcomeMessageReducer';
import originData from './originReducer';
import vault from './vaultReducer';
import roastingPlantInfo from './roastingPlantReducer';
import countryInfo from './countryReducer';
import stories from './storiesReducer';
import profile from './profileReducer';
import locations from './locationReducer';
import cafePracticeStats from './cafePracticeStatsReducer';
import translation from './translationReducer';

const scannerConfig = {
    key: 'scanner',
    storage,
    blacklist: ['isRemoveCamera', 'isFetchingText', 'isScanning', 'scanRetryCounter'],
    timeout: null,
};

const vaultConfig = {
    key: 'vaultInfo',
    storage,
    blacklist: ['isFetching'], //Specify
    timeout: null,
};

const translationConfig = {
    key: 'translation',
    storage,
    timeout: null,
};

export default combineReducers({
    roastInfo,
    scanner: persistReducer(scannerConfig, imageScanner),
    loadingIndicator,
    welcomeMessage,
    originData,
    vaultInfo: persistReducer(vaultConfig, vault),
    roastingPlantInfo,
    countryInfo,
    stories,
    profile,
    locations,
    cafePracticeStats,
    translation: persistReducer(translationConfig, translation),
});
