import { call, put, takeLatest, select } from 'redux-saga/effects';
import { GET_PROFILE, PROFILE_FAIL, PROFILE_SUCCESS } from '../actions/types';
import Webservice from '../webservices';
import { convertUnicode } from '../constants/utilFuctions';
import cdnUrls from '../constants/cdnUrls';
import { QUERY_FARMER, QUERY_ROASTER, QUERY_PARTNER } from '../constants/queryStrings';
import { logger } from '../utils';

function* getProfileDataService({ code, filter, requestParams: { requestType } }) {
    switch (requestType) {
        case QUERY_FARMER:
            yield call(getFarmerProfile, code, filter);
            break;
        case QUERY_ROASTER:
            yield call(getRoasterProfile, code, filter);
            break;
        case QUERY_PARTNER:
            yield call(getPartnerProfile, code);
            break;
        default:
            return;
    }
}

function* getFarmerProfile(countryCode, filter = false) {
    try {
        const lng = yield select((state) => state.translation.language);
        const farmerProfileUrl = cdnUrls.getFarmerProfile(countryCode, lng);

        const profiles = yield call(Webservice.requestCDN, farmerProfileUrl);

        const filteredProfiles = filter
            ? profiles.filter((profiles) => (profiles.gender || '').toLowerCase() === 'female')
            : profiles;

        const { images, story, preferred_name } = filteredProfiles[Math.floor(Math.random() * filteredProfiles.length)];

        yield put({
            type: PROFILE_SUCCESS,
            images: images.map((id) => cdnUrls.getFarmerProfileImage(id)),
            story: convertUnicode(story),
            preferredName: preferred_name,
            requestType: QUERY_FARMER,
        });
    } catch (e) {
        logger.log('Error : ' + e);
        yield put({ type: PROFILE_FAIL });
    }
}

function* getRoasterProfile(organizationCode, filter = false) {
    try {
        const lng = yield select((state) => state.translation.language);
        const roasterProfileUrl = cdnUrls.getRoasterProfile(organizationCode, lng);

        const profiles = yield call(Webservice.requestCDN, roasterProfileUrl);

        const filteredProfiles = filter
            ? profiles.filter((profiles) => (profiles.gender || '').toLowerCase() === 'female')
            : profiles;

        const { images, story, preferred_name } = filteredProfiles[Math.floor(Math.random() * filteredProfiles.length)];

        yield put({
            type: PROFILE_SUCCESS,
            images: images.map((id) => cdnUrls.getRoasterProfileImage(id)),
            story: convertUnicode(story),
            preferredName: preferred_name,
            requestType: QUERY_ROASTER,
        });
    } catch (e) {
        logger.log('Error : ' + e);
        yield put({ type: PROFILE_FAIL });
    }
}

function* getPartnerProfile(sku) {
    try {
        const lng = yield select((state) => state.translation.language);
        const partnerProfileUrl = cdnUrls.getPartnerProfile(sku, lng);

        const profiles = yield call(Webservice.requestCDN, partnerProfileUrl);

        const { images, story, preferred_name } = profiles[Math.floor(Math.random() * profiles.length)];

        yield put({
            type: PROFILE_SUCCESS,
            images: images.map((id) => cdnUrls.getPartnerProfileImage(id)),
            story: convertUnicode(story),
            preferredName: preferred_name,
            requestType: QUERY_PARTNER,
        });
    } catch (e) {
        logger.log('Error : ' + e);
        yield put({ type: PROFILE_FAIL });
    }
}

export function* watchProfileData() {
    yield takeLatest(GET_PROFILE, getProfileDataService);
}
