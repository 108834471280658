import { PURGE } from 'redux-persist/es/constants';
import {
    STORIES_FAIL,
    STORIES_PURGE,
    STORIES_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    posts:[]
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case STORIES_SUCCESS: {
            return {
                ...state,
                posts: action.posts
            };
        }
        case STORIES_FAIL:
        case STORIES_PURGE:
        case PURGE: {
            return {
                ...state,
                posts: []
            };
        }
        default:
            return state;
    }
}
