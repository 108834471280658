import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from '@cxeweb/pattern-library/lib/components/select';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import { setLngAction } from '../../actions';
import Images from '../../images';

const HAFooter = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const toggleLng = (lng) => {
        dispatch(setLngAction(lng));
        i18n.changeLanguage(lng);
    };

    const lanInLocalStorage = window.localStorage.getItem('i18nextLng');
    const isFrench = !!lanInLocalStorage && lanInLocalStorage.includes('fr');

    let defaultLng = t('en');
    if (!!lanInLocalStorage) {
        if (lanInLocalStorage.includes('fr')) {
            defaultLng = t('fr');
        }
        if (lanInLocalStorage.includes('es')) {
            defaultLng = t('es');
        }
    }

    console.log('lanInLocalStorage ===>', lanInLocalStorage);
    console.log('defaultLng ===>', defaultLng);
    const [lng, setLng] = React.useState(defaultLng);

    const toggleLan = (ev) => {
        if (ev.target.value === t('en')) {
            setLng(t('en'));
            toggleLng('en');
        }

        if (ev.target.value === t('es')) {
            setLng(t('es'));
            toggleLng('es');
        }

        if (ev.target.value === t('fr')) {
            setLng(t('fr'));
            toggleLng('fr');
        }
    };

    return (
        <div className='footer-container'>
            <div className='footer-select flex items-center'>
                <div className='footer-select-icon'>
                    <ImageLoader src={Images.iconGlobe} alt={`translation`} />
                </div>

                <Select id='language' displayStatus={false} label='' value={lng} onChange={toggleLan}>
                    <option>{t('en')}</option>
                    <option>{t('es')}</option>
                    <option>{t('fr')}</option>
                </Select>
            </div>
            <div className='footer-link-container'>
                <a
                    className='text-xxs footer-link'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={
                        isFrench
                            ? 'https://fr.starbucks.ca/about-us/company-information/online-policies/privacy-statement'
                            : 'https://www.starbucks.com/about-us/company-information/online-policies/privacy-policy'
                    }
                >
                    {t('privacy')}
                </a>
                <a
                    className='text-xxs footer-link footer-term-of-use'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={
                        isFrench
                            ? 'https://fr.starbucks.ca/about-us/company-information/online-policies/terms-of-use'
                            : 'https://www.starbucks.com/about-us/company-information/online-policies/terms-of-use'
                    }
                >
                    {t('termOfUse')}
                </a>
            </div>

            <div className='footer-disclaimer'>{t('disclaimer')}</div>
        </div>
    );
};

export { HAFooter };
