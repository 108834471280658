import 'react-responsive-modal/styles.css';
//import './style.css';

import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Carousel from '@cxeweb/pattern-library/lib/components/carousel';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import cdnUrls from '../../constants/cdnUrls';

const HATileCarousel = () => {
    const { t } = useTranslation();
    const language = useSelector((state) => state.translation.language);

    let HAcoffeeAcademyUrl;
    switch (language) {
        case 'es':
            HAcoffeeAcademyUrl = 'https://lac.starbucksglobalacademy.com/es/explore/?type=Coffee%20Academy';
            break;
        case 'fr':
            HAcoffeeAcademyUrl = 'https://ca.starbucksglobalacademy.com/fr/explore/?type=Coffee%20Academy';
            break;
        default:
            // 'en' or any other language
            HAcoffeeAcademyUrl = 'https://www.starbucksglobalacademy.com/coffee-academy/';
    }

    const getSlides = () => {
        const slides = [
            {
                title: t('HAFarm:carousel.card1.header'),
                imageUrl: cdnUrls.getHAFarmImage(`CarouselCAFEPractices.jpg`),
                text: t('HAFarm:carousel.card1.desc'),
                buttonText: t('HAFarm:carousel.card1.button'),
                onClick: () =>
                    window.open(
                        'https://stories.starbucks.com/press/2020/cafe-practices-starbucks-approach-to-ethically-sourcing-coffee/',
                        '_blank'
                    ),
            },
            {
                title: t('HAFarm:carousel.card2.header'),
                imageUrl: cdnUrls.getHAFarmImage(`CarouselHaciendaAlsacia.jpg`),
                text: t('HAFarm:carousel.card2.desc'),
                buttonText: t('HAFarm:carousel.card2.button'),
                onClick: () => window.open('https://coffeeexperiences.starbucks.com/', '_blank'),
            },
            {
                title: t('HAFarm:carousel.card3.header'),
                imageUrl: cdnUrls.getHAFarmImage(`CarouselCoffeeAcademy.jpg`),
                text: t('HAFarm:carousel.card3.desc'),
                buttonText: t('HAFarm:carousel.card3.button'),
                onClick: () => {
                    window.open(HAcoffeeAcademyUrl, '_blank');
                    console.log('WHAT IS HAcoffeeAcademyUrl============', HAcoffeeAcademyUrl); // Log the value to the console
                },
            },
        ];

        return slides.map(({ title, imageUrl, text, buttonText, onClick }, idx) => (
            <div className={`gridItem ${idx} pl4 pr4 card-container`} key={idx}>
                <Card imagePosition='top' imageUrl={imageUrl} contentClasses='pl3 pr3 pt4 pb2  carousel-card'>
                    <Heading tagName='h4' size={'xs'} className='text-semibold mb3'>
                        {t(title)}
                    </Heading>
                    <p className='text-xxs mb3 mt3'>{t(text)}</p>
                    <div className='carousel-btn pb4'>
                        <Button className='pl6 pr6 pt2 pb2' onClick={onClick}>
                            {t(buttonText)}
                        </Button>
                    </div>
                </Card>
            </div>
        ));
    };

    return (
        <div className='mt3'>
            <Carousel
                className='pb1'
                messages={{
                    ariaLabelPrev: 'Previous',
                    ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                }}
                variableWidth
            >
                {getSlides()}
            </Carousel>
        </div>
    );
};

export { HATileCarousel };
