import { UPDATE_LOCATION } from '../actions/types';

const INITIAL_STATE = {
    //Latin America
    Bolivia: { lat: -16.290154, lng: -63.58865299999999 },
    Brazil: { lat: -14.235004, lng: -51.92528 },
    Colombia: { lat: 4.570868, lng: -74.297333 },
    'Costa Rica': { lat: 9.748916999999999, lng: -83.753428 },
    Ecuador: { lat: -1.831239, lng: -78.18340599999999 },
    'El Salvador': { lat: 13.794185, lng: -88.89653 },
    Guatemala: { lat: 15.783471, lng: -90.23075899999999 },
    Honduras: { lat: 15.199999, lng: -86.241905 },
    Jamaica: { lat: 18.109581, lng: -77.297508 },
    Mexico: { lat: 23.634501, lng: -102.552784 },
    Nicaragua: { lat: 12.865416, lng: -85.207229 },
    Panama: { lat: 8.537981, lng: -80.782127 },
    Peru: { lat: -9.189967, lng: -75.015152 },
    'Puerto Rico': { lat: 18.220833, lng: -66.590149 },

    //Afria
    Burundi: { lat: -3.373056, lng: 29.918886 },
    Ethiopia: { lat: 9.145000000000001, lng: 40.489673 },
    Kenya: { lat: -0.023559, lng: 37.906193 },
    Malawi: { lat: -13.254308, lng: 34.301525 },
    Rwanda: { lat: -1.940278, lng: 29.873888 },
    Tanzania: { lat: -6.369028, lng: 34.888822 },
    Uganda: { lat: 1.373333, lng: 32.290275 },
    Zambia: { lat: -13.133897, lng: 27.849332 },
    'Democratic Republic of the Congo': { lat: -4.038333, lng: 21.758664 },

    //Asia Pacific
    'United States': { lat: 37.09024, lng: -95.712891 },
    'Papua New Guinea': { lat: -6.314992999999999, lng: 143.95555 },
    China: { lat: 35.86166, lng: 104.195397 },
    India: { lat: 20.593684, lng: 78.96288 },
    'East Timor': { lat: -8.874217, lng: 125.727539 },
    Indonesia: { lat: -0.789275, lng: 113.921327 },
    Vietnam: { lat: 14.058324, lng: 108.277199 },
    Thailand: { lat: 15.870032, lng: 100.992541 },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_LOCATION:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
