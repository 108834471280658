import { EXPLORE_LATIN_AMERICA_PATH, EXPLORE_AFRICA_PATH, EXPLORE_ASIAPACIFIC_PATH } from './paths';

export const ROASTING_PLANT_CODES = [
    'ASP',
    'CRP',
    'GRP',
    'KRP',
    'KSP',
    'KTP',
    'RRP',
    'SRP',
    'YRP',
    'ARP',
    'NRP',
    'R04',
    'R06',
];

/**
 * @return {string}
 */
export function ROASTING_PLANT_CODE_TO_STATE(code) {
    switch (code) {
        case 'CRP':
            return 'Nevada';
        case 'KRP':
            return 'Washington';
        case 'RRP':
            return 'Washington';
        case 'ARP':
            return 'Georgia';
        case 'GRP':
            return 'Georgia';
        case 'SRP':
            return 'South Carolina';
        case 'YRP':
            return 'Pennsylvania';
        case 'NRP':
            return 'New York';
        case 'R04':
            return 'New York';
        case 'R06':
            return 'Chicago';
        default:
            return 'Unknown...';
    }
}

export function convertUnicode(input) {
    return input.replace(/\\u(\w{4,4})/g, function (a, b) {
        const charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
}

export function ROASTING_PLANT_CODE_TO_NAME(code) {
    switch (code) {
        case 'CRP':
            return 'Carson Valley Roasting Plant';
        case 'KRP':
            return 'Kent Roasting Plant';
        case 'ARP':
            return 'Augusta Roasting Plant';
        case 'GRP':
            return 'Augusta Roasting Plant';
        case 'SRP':
            return 'Sandy Run Roasting Plant';
        case 'YRP':
            return 'York Roasting Plant';
        case 'RRP':
            return 'Seattle Roastery';
        case 'NRP':
            return 'New York Reserve Roastery';
        case 'R06':
            return 'Chicago Reserve Roastery';
        default:
            return 'Unknown...';
    }
}

export function countryCodeToRegionPath(code) {
    switch (code) {
        case 'BOL':
        case 'BRA':
        case 'COL':
        case 'CRI':
        case 'ECU':
        case 'SLV':
        case 'GTM':
        case 'HND':
        case 'JAM':
        case 'MEX':
        case 'NIC':
        case 'PAN':
        case 'PER':
        case 'PRI':
            return EXPLORE_LATIN_AMERICA_PATH;
        case 'BDI':
        case 'ETH':
        case 'KEN':
        case 'MWI':
        case 'RWA':
        case 'TZA':
        case 'UGA':
        case 'ZMB':
        case 'COD':
            return EXPLORE_AFRICA_PATH;
        case 'USA':
        case 'PNG':
        case 'CHN':
        case 'IND':
        case 'TLS':
        case 'IDN':
        case 'IDNAGS':
        case 'IDNBAL':
        case 'IDNJAV':
        case 'IDNSUL':
        case 'IDNSUM':
        case 'VNM':
        case 'THA':
            return EXPLORE_ASIAPACIFIC_PATH;
        default:
            return '/';
    }
}

export function mapRoastType(urlRoastType) {
    const roastTypeMapping = {
        'blonde-roast': 'blonde',
        'medium-roast': 'medium',
        'dark-roast': 'dark',
        'limited-release': 'limited',
    };

    return roastTypeMapping[urlRoastType.toLowerCase()] || 'Unknown';
}
