import './style.css';

import React, { Component } from 'react';
import { DARK, BLONDE, MEDIUM } from '../../../constants/strings';

class Spectrum extends Component {
    getRoastPercentageFromType = (roastType, roastScale) => {
        if (roastScale !== '') {
            return parseFloat(roastScale) * 100;
        }

        switch (roastType) {
            case MEDIUM:
                return 53;
            case BLONDE:
                return 15;
            case DARK:
                return 83;
            default:
                return 15;
        }
    };

    getColor = (color) => {
        return color !== '' && color;
    };

    render() {
        const { roastType, backgroundColor, roastScale } = this.props;
        return (
            <div className='prod-roast-spectrum'>
                <div
                    className='prod-roast-needle'
                    style={{ left: this.getRoastPercentageFromType(roastType, roastScale) + '%', right: 'auto' }}
                >
                    <div
                        className='spectrum-needle'
                        style={{ borderBottom: `10px solid ${this.getColor(backgroundColor)}` }}
                    />
                </div>
                <div className='spectrum-bar-wrapper' />
            </div>
        );
    }
}

export default Spectrum;
