import { HIDE_WELCOME_MESSAGE } from '../actions/types';
import { PURGE } from 'redux-persist/es/constants';

const INITIAL_STATE = {
    showMessage: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HIDE_WELCOME_MESSAGE:
            return {...state, showMessage: false};
        case PURGE : {
            return {...state, showMessage: false};
        }
        default:
            return state;
    }
}