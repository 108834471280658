import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import {
    GET_SECRET_KEY,SECRET_KEY_SUCCESS, SECRET_KEY_FAIL, GET_ALL_SECRET_KEY
} from '../actions/types';
import Webservice from '../webservices';
import { fetchSecretKeyFor } from '../actions/keyVaultAction';
import { GOOGLE_MAP_KEY, GOOGLE_VISION_KEY} from '../constants/keys';

function * getSecretData (data) {
    try {
        const secret = yield call(Webservice.request, data.requestParams);
        if (secret) {

            const name = data.requestParams.params.secret;
            yield put({
                type: SECRET_KEY_SUCCESS + name,
                name: name,
                value: secret

            });
        } else {
            yield put({type: SECRET_KEY_FAIL});
        }

    } catch (e) {
        yield put({type: SECRET_KEY_FAIL});
    }
}

function * getAllSecretKey (data) {
    yield put((fetchSecretKeyFor(GOOGLE_MAP_KEY, data.access_token)));
    yield put((fetchSecretKeyFor(GOOGLE_VISION_KEY, data.access_token)));

}

export function * watchSecretKeyValue () {
    yield takeEvery(GET_SECRET_KEY, getSecretData);
}

export function * watchAllSecretKeyValue () {
    yield takeLatest(GET_ALL_SECRET_KEY, getAllSecretKey);
}