import React, { Component } from 'react';
import LoadingIndicator from '@cxeweb/pattern-library/lib/components/loading-indicator';
import { CAFEPracticesView } from './CAFEPracticesView';
import { Detail } from '../common/DetailPage';
import { withTranslation } from 'react-i18next';

class CAFEPracticesContainer extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <div style={{ paddingBottom: '1px' }}>
                <Detail title={this.props.t('cafePractices.pageHeading')} backTitle={' '}>
                    <CAFEPracticesView />
                </Detail>
                <LoadingIndicator active={this.props.loadingIndicator} />
            </div>
        );
    }
}

const CAFEPractices = withTranslation()(CAFEPracticesContainer);

export { CAFEPractices };
