import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { setLngAction } from './actions';
import {
    WELCOME_PATH,
    HOME_PATH,
    COUNTRY_DETAIL_PATH,
    CAM_SCANNER_PATH,
    ERROR_PAGE_PATH,
    CAMERA_NOT_WORKING_ERROR_PATH,
    PRODUCT_DETAIL_PATH,
    RP_DETAIL_PATH,
    EXPLORE_OVERVIEW_PATH,
    EXPLORE_REGIONS_PATH,
    EXPLORE_ROASTING_PATH,
    COUNTY_LIST_PATH,
    EXPLORE_PRODUCTS_PATH,
    EXPLORE_PRODUCTS_WITH_COFFEE_TYPE_PATH,
    CAFE_PRACTICES_PATH,
    HA_FARM_ROAST_PATH,
    HA_FARM_OVERVIEW_PATH,
} from './constants/paths';
import { Home } from './components/Home';
import { CountryDetail } from './components/CountryDetail';
import Welcome from './components/Welcome';
import { Scanner } from './components/Scanner';
import { ExploreOverview, RoastingPlantsView, ProductListView, ProductList } from './components/ExploreOverview';
import RegionsViewContainer from './components/ExploreOverview/RegionViewContainer';
import { ErrorPage } from './components/common/ErrorPage';
import { CameraErrorPage } from './components/common/CameraErrorPage';
import { createHashHistory } from 'history';
import { Product } from './components/Product';
import { RoastingPlant } from './components/RoastingPlant';
import { CountryList } from './components/CountryList/CountryListPageView';
import { CAFEPractices } from './components/CAFEPractices';
import { HAFarmRoast } from './components/HAFarmRoast';
import { HAFarmOverview } from './components/HAFarmOverview';
import TelemetryProvider from './components/common/TelemetryProvider';
import './utils/i18n';

export const history = createHashHistory();

function Root({ i18n }) {
    const language = useSelector((state) => state.translation.language);
    const dispatch = useDispatch();

    useEffect(() => {
        const lanInLocalStorage = window.localStorage.getItem('i18nextLng');

        if (!!lanInLocalStorage && lanInLocalStorage !== language) {
            i18n.changeLanguage(lanInLocalStorage);
            const lan = lanInLocalStorage.includes('en') ? 'en' : lanInLocalStorage;
            dispatch(setLngAction(lan));
        }
    }, [dispatch, i18n, language]);

    return (
        <HashRouter>
            <TelemetryProvider>
                <Routes>
                    <Route path={WELCOME_PATH} element={<Welcome />} />
                    <Route exact path={CAM_SCANNER_PATH} element={<Scanner />} />
                    <Route exact path={HOME_PATH} element={<Home />} />
                    <Route exact path={COUNTRY_DETAIL_PATH} element={<CountryDetail />} />
                    <Route exact path={PRODUCT_DETAIL_PATH} element={<Product />} />
                    <Route exact path={RP_DETAIL_PATH} element={<RoastingPlant />} />
                    <Route exact path={ERROR_PAGE_PATH} element={<ErrorPage />} />
                    <Route exact path={CAMERA_NOT_WORKING_ERROR_PATH} element={<CameraErrorPage />} />
                    <Route exact path={EXPLORE_OVERVIEW_PATH} element={<ExploreOverview />} />
                    <Route exact path={EXPLORE_REGIONS_PATH} element={<RegionsViewContainer />} />
                    <Route exact path={EXPLORE_ROASTING_PATH} element={<RoastingPlantsView />} />
                    <Route exact path={COUNTY_LIST_PATH} element={<CountryList />} />
                    <Route exact path={EXPLORE_PRODUCTS_PATH} element={<ProductListView />} />
                    <Route exact path={EXPLORE_PRODUCTS_WITH_COFFEE_TYPE_PATH} element={<ProductList />} />
                    <Route exact path={CAFE_PRACTICES_PATH} element={<CAFEPractices />} />
                    <Route exact path={HA_FARM_ROAST_PATH} element={<HAFarmRoast />} />
                    <Route exact path={HA_FARM_OVERVIEW_PATH} element={<HAFarmOverview />} />
                </Routes>
            </TelemetryProvider>
        </HashRouter>
    );
}

const TransRoot = withTranslation()(Root);
export default function ConnectedApp() {
    return <TransRoot />;
}
