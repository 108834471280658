import React, { useEffect } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import AI, { ai } from '../../../telemetryService';

const TelemetryProvider = ({ children }) => {
    useEffect(() => {
        ai.initialize();
    });

    return <>{children}</>;
};

class TelemetryProviderWrapper extends React.Component {
    render() {
        const { children } = this.props;
        return <TelemetryProvider children={children} />;
    }
}

export default withAITracking(AI.reactPlugin, TelemetryProviderWrapper);
