import './styles.css';

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CAFE_PRACTICES_PATH } from '../../../constants/paths';

const ImageHeader = ({ image, title, content, detail, coffeeDetails }) => {
    return (
        <>
            <div className='ih-container'>
                <div className='ih-imgContainer'>
                    <img style={{ height: '125px' }} src={image} alt='coffee bag' />
                </div>
                <div style={{ flex: '2' }}>
                    <div className='ih-lotIdText text-semibold heading-md'>{title}</div>
                    <div className='ih-lotIdContent'>{content}</div>
                    <div style={{ marginTop: '15px' }}>
                        <Link className='color-greenStarbucks' to={CAFE_PRACTICES_PATH}>
                            {detail}
                        </Link>
                    </div>
                </div>
            </div>
            {coffeeDetails && <p className='ih-description-container pl4 pr3 pb3'>{coffeeDetails}</p>}
        </>
    );
};

ImageHeader.defaultProps = {
    lotId: '',
    coffeeNote: '',
    image: '',
};

ImageHeader.propTypes = {
    lotId: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default ImageHeader;
