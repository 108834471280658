import './styles.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Button from '@cxeweb/pattern-library/lib/components/button';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import { Stories } from '../common/Stories';
import Text from '../common/Text';
import Images from '../../images';
import { EXPLORE_ROASTING_PATH, EXPLORE_PRODUCTS_PATH } from '../../constants/paths';
import { coffeeRegions } from '../../constants/exploreRegions';
import { Footer } from '../common/Footer';

const ExploreOverviewPageView = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const goToStorePage = (url) => navigate(url);
    const goToRoastingPlantsPage = () => navigate(EXPLORE_ROASTING_PATH);
    const goToProductsPage = () => navigate(EXPLORE_PRODUCTS_PATH);

    return (
        <>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                <div style={{ maxWidth: '700px' }}>
                    <ImageLoader
                        wrapInFlex={{
                            enabled: true,
                            width: 700,
                            height: 300,
                        }}
                        src={Images.exploreOverviewGrow}
                        alt={t('explore.regions.header')}
                    />
                </div>
                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('explore.regions.header')}
                </Heading>
                <p className='text-sm p3'>{t('explore.regions.text1')}</p>
                <p className='text-sm pr3 pl3'>{t('explore.regions.text2')}</p>
                <p className='text-sm p3'>{t('explore.regions.text3')}</p>
                <Stories stories={coffeeRegions} showTitle={false} onClick={goToStorePage} />
            </Card>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                <div style={{ maxWidth: '700px' }}>
                    <ImageLoader
                        wrapInFlex={{
                            enabled: true,
                            width: 700,
                            height: 300,
                        }}
                        src={Images.exploreOverviewRoast}
                        alt={t('explore.roastingPlants.header')}
                    />
                </div>
                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('explore.roastingPlants.header')}
                </Heading>
                <Text className='text-sm p3'>{t('explore.roastingPlants.text1')}</Text>
                <Text className='text-sm pl3 pr3 '>{t('explore.roastingPlants.text2')}</Text>
                <div className='flex justify-center'>
                    <Button visualStyle='positive' className='mt5 mb6' onClick={goToRoastingPlantsPage}>
                        {t('explore.roastingPlants.button')}
                    </Button>
                </div>
            </Card>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                <div style={{ maxWidth: '700px' }}>
                    <ImageLoader
                        wrapInFlex={{
                            enabled: true,
                            width: 700,
                            height: 300,
                        }}
                        src={Images.exploreOverviewBrew}
                        alt={t('explore.products.header')}
                    />
                </div>
                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('explore.products.header')}
                </Heading>
                <Text className='text-sm p3'>{t('explore.products.text1')}</Text>
                <Text className='text-sm pl3 pr3'>{t('explore.products.text2')}</Text>
                <div className='flex justify-center'>
                    <Button visualStyle='positive' className='mt5 mb6' onClick={goToProductsPage}>
                        {t('explore.products.button')}
                    </Button>
                </div>
            </Card>
            <Footer />
        </>
    );
};

export default ExploreOverviewPageView;
