import { EXPLORE_LATIN_AMERICA_PATH, EXPLORE_AFRICA_PATH, EXPLORE_ASIAPACIFIC_PATH } from './paths';
import Images from '../images';

export const coffeeRegions = [
    {
        heading: 'latinAmerica',
        title: 'latinAmericaDesc',
        image: Images.growRegionLatinAmericaThumbnail,
        link: EXPLORE_LATIN_AMERICA_PATH,
    },
    {
        heading: 'africa',
        title: 'africaDesc',
        image: Images.growRegionAfricaThumbnail,
        link: EXPLORE_AFRICA_PATH,
    },
    {
        heading: 'asiaPacific',
        title: 'asiaPacificDesc',
        image: Images.growRegionAsiaPacificThumbnail,
        link: EXPLORE_ASIAPACIFIC_PATH,
    },
];
