import './styles.css';

import React, { Component } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from './Camera';
import CameraOverlay from './ScannerOverlay';
import { ai } from '../../telemetryService';
import AICustomEvents from '../../constants/appInsightEvents';

class ScannerView extends Component {
    componentDidMount() {
        ai.trackEvent({ name: AICustomEvents.scanPage });
    }

    render() {
        return this.props.isRemoveCamera ? null : (
            <div className='sc-main-container'>
                <div style={{ height: window.innerHeight }}>
                    <Camera
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        idealResolution={{
                            width: window.innerWidth,
                            height: window.innerHeight,
                        }}
                        imageType={IMAGE_TYPES.JPG}
                        isMaxResolution={false}
                        isImageMirror={false}
                        isDisplayStartCameraError={true}
                    />
                </div>
                <CameraOverlay />
            </div>
        );
    }
}

export default ScannerView;
