import 'react-responsive-modal/styles.css';
import './style.css';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Carousel from '@cxeweb/pattern-library/lib/components/carousel';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Images from '../../../images';
import { useNavigate } from 'react-router-dom';
import { CAM_SCANNER_PATH, EXPLORE_OVERVIEW_PATH, getHomePath } from '../../../constants/paths';
import { Modal } from 'react-responsive-modal';
import seeJourney from './seeJourney.mp4';
import seeJourneyFr from './seeJourney-fr.mp4';

const TileCarousel = () => {
    const { t } = useTranslation();
    const language = useSelector((state) => state.translation.language);
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const videoSrc = language === 'fr' ? seeJourneyFr : seeJourney;
    const coffeeAcademyUrl =
        language !== 'fr' ? 'https://starbuckscoffeeacademy.com/' : 'https://www.starbucksglobalacademy.com/';

    const getSlides = () => {
        const slides = [
            {
                title: 'tileCarousel.traceTile.title',
                imageUrl: Images.traceTile,
                text: 'tileCarousel.traceTile.text',
                buttonText: 'tileCarousel.traceTile.buttonText',
                onClick: () => navigate(CAM_SCANNER_PATH),
            },
            {
                title: 'tileCarousel.exploreTile.title',
                imageUrl: Images.exploreTile,
                text: 'tileCarousel.exploreTile.text',
                buttonText: 'tileCarousel.exploreTile.buttonText',
                onClick: () => navigate(EXPLORE_OVERVIEW_PATH),
            },
            {
                title: 'tileCarousel.coffeeAcademyTile.title',
                imageUrl: Images.coffeeAcademyTile,
                text: 'tileCarousel.coffeeAcademyTile.text',
                buttonText: 'tileCarousel.coffeeAcademyTile.buttonText',
                onClick: () => (window.location.href = coffeeAcademyUrl),
            },
            {
                title: 'tileCarousel.traceabilityTile.title',
                imageUrl: Images.traceabilityTile,
                text: 'tileCarousel.traceabilityTile.text',
                buttonText: 'tileCarousel.traceabilityTile.buttonText',
                onClick: () =>
                    (window.location.href =
                        'https://stories.starbucks.com/stories/2020/new-starbucks-traceability-tool-explores-bean-to-cup-journey/'),
            },
            {
                title: 'tileCarousel.seeJourneyTile.title',
                imageUrl: Images.seeJourneyTile,
                text: 'tileCarousel.seeJourneyTile.text',
                buttonText: 'tileCarousel.seeJourneyTile.buttonText',
                onClick: () => setOpen(true),
            },
        ];

        return slides.map(({ title, imageUrl, text, buttonText, onClick }, idx) => (
            <div className={`gridItem ${idx} pl4 pr4 card-container`} key={idx}>
                <Card imagePosition='top' imageUrl={imageUrl} contentClasses='pl3 pr3 pt4 pb2  carousel-card'>
                    <Heading tagName='h4' size={'xs'} className='text-semibold mb3'>
                        {t(title)}
                    </Heading>
                    <p className='text-xxs mb3 mt3'>{t(text)}</p>
                    <div className='carousel-btn pb4'>
                        <Button className='pl6 pr6 pt2 pb2' onClick={onClick}>
                            {t(buttonText)}
                        </Button>
                    </div>
                </Card>
            </div>
        ));
    };

    const onVideoEnd = () => {
        const finishedLots = [
            'YRP486544', // newSku:11130112 oldSKU:11028472-2025-02-02 espresso
            'YRP486946', //newSku:11130110, oldSKU:11028479-2025-02-25 sumatra
        ];
        const random = finishedLots[Math.floor(Math.random() * finishedLots.length)];
        setOpen(false);
        navigate(getHomePath(random));
    };

    return (
        <div className='mt3'>
            <Carousel
                className='pb1'
                messages={{
                    ariaLabelPrev: 'Previous',
                    ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                }}
                variableWidth
            >
                {getSlides()}
            </Carousel>
            <Modal
                open={open}
                center
                classNames={{
                    overlay: 'videoOverlay',
                    modal: 'videoModal',
                }}
                onClose={() => setOpen(false)}
            >
                <video src={videoSrc} width='320' height='240' autoplay='autoplay' muted onEnded={() => onVideoEnd()} />
            </Modal>
        </div>
    );
};

export { TileCarousel };
