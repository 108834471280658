import React, { useEffect } from 'react';
import RoastingPlantView from './RoastingPlantView';
import { getStoriesAction } from '../../actions/storiesAction';
import { getProfileDataAction, getRoastingPlant } from '../../actions';
import { QUERY_ROASTER } from '../../constants/queryStrings';
import { ai } from '../../telemetryService';
import AICustomEvents from '../../constants/appInsightEvents';
import { ROASTING_PLANT_CODE_TO_NAME } from '../../constants/utilFuctions';
import LoadingIndicator from '@cxeweb/pattern-library/lib/components/loading-indicator';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from '../common/hooks/usePrevious';

const RoastingPlant = () => {
    const { orgCode } = useParams();
    const prevOrgCode = usePrevious(orgCode);
    const language = useSelector((state) => state.translation.language);
    const prevLanguage = usePrevious(language);
    const loadingIndicator = useSelector((state) => state.loadingIndicator.showActivityIndicator);
    const roastingPlant = useSelector((state) => state.roastingPlantInfo);
    const profile = useSelector((state) => state.profile);
    const posts = useSelector((state) => state.stories.posts);
    const filterOnProfile = useSelector((state) => state.roastInfo.coffeeName === "Siren's Blend");

    const dispatch = useDispatch();

    useEffect(() => {
        ai.trackEvent({ name: AICustomEvents.roastingPlantDetailPage });
        window.scrollTo(0, 0);

        loadData();
    }, []);

    useEffect(() => {
        if (prevOrgCode !== orgCode || prevLanguage !== language) {
            loadData();
        }
    }, [orgCode, language]);

    const loadData = () => {
        dispatch(getRoastingPlant(orgCode));
        dispatch(getProfileDataAction(QUERY_ROASTER, orgCode, filterOnProfile));
        dispatch(getStoriesAction(ROASTING_PLANT_CODE_TO_NAME(orgCode)));
    };

    return loadingIndicator ? (
        <LoadingIndicator active />
    ) : (
        <RoastingPlantView roastingPlant={roastingPlant} profile={profile} posts={posts} />
    );
};

export { RoastingPlant };
