import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTextFromImageAction, purgeStoredDataAction, getSecretKeys } from '../../actions';
import ScannerView from './ScannerView';
import { CHECK_MANUALLY_ENTERED_TEXT } from '../../actions/types';
import { GOOGLE_VISION_KEY, GOOGLE_MAP_KEY } from '../../constants/keys';
import { toggleCameraScanningAction, updateScanningCountAction } from '../../actions/scannerAction';
import { Navigate } from 'react-router-dom';
import { ERROR_PAGE_PATH, getHomePath } from '../../constants/paths';

class ScannerContainer extends Component {
    componentDidMount() {
        this.props.purgeStoredData();
        if (
            !this.props.isFetching &&
            (this.props.googleVisionKey.length === 0 || this.props.googleMapKey.length === 0)
        ) {
            this.props.getSecretKeys(this.props.vaultInfo);
        }
    }

    componentWillUnmount() {
        this.props.purgeStoredData();
    }

    toggleScanning = (isScanning) => {
        this.props.toggleScanning(isScanning);
    };

    render() {
        const { isRemoveCamera, scanError, prodLotId, enterItemIdManually } = this.props;

        if (scanError) {
            return <Navigate push to={ERROR_PAGE_PATH} />;
        }

        if (prodLotId) {
            return <Navigate push to={getHomePath(prodLotId)} />;
        }

        return (
            <ScannerView
                toggleScanning={this.toggleScanning}
                isRemoveCamera={isRemoveCamera}
                onManualEntry={enterItemIdManually}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isRemoveCamera: state.scanner.isRemoveCamera,
        vaultInfo: state.vaultInfo,
        isFetching: state.vaultInfo.isFetching,
        googleVisionKey: state.vaultInfo[GOOGLE_VISION_KEY],
        googleMapKey: state.vaultInfo[GOOGLE_MAP_KEY],
        isScanning: state.scanner.isScanning,
        scanError: state.scanner.scanError,
        prodLotId: state.scanner.prodLotId,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getSecretKeys: (vaultInfo) => {
            dispatch(getSecretKeys(vaultInfo));
        },
        getTextFromImage: (value, key) => {
            dispatch(getTextFromImageAction(value, key));
        },
        purgeStoredData: () => {
            dispatch(purgeStoredDataAction());
        },
        enterItemIdManually: (lotId) => {
            dispatch({
                type: CHECK_MANUALLY_ENTERED_TEXT,
                lotId,
            });
        },
        toggleScanning: (isScanning) => {
            dispatch(toggleCameraScanningAction(isScanning));
        },
        updateCounter: (count) => {
            dispatch(updateScanningCountAction(count));
        },
    };
}

const Scanner = connect(mapStateToProps, mapDispatchToProps)(ScannerContainer);

export { Scanner };
