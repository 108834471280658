import { GET_COUNTRY_DETAILS } from './types';
import { createWebserviceRequestParams } from '../webservices';

export function getCountryDetails (countryCode) {
    const requestData = {};
    requestData.type = GET_COUNTRY_DETAILS;
    requestData.countryCode = countryCode;
    const requestParams = createWebserviceRequestParams(requestData);
    return {
        type: GET_COUNTRY_DETAILS,
        requestParams,
        countryCode
    };
}