import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
    GET_TEXT_FROM_IMAGE,
    TEXT_FROM_IMAGE_FAIL,
    LOT_ID_RECOGNITION_SUCCESS,
    CHECK_MANUALLY_ENTERED_TEXT,
    TEXT_FROM_IMAGE_RESPONSE_SUCCESS,
    TEXT_FROM_IMAGE_TIMEOUT,
    SCAN_IDLE,
    UPDATE_EMPTY_RESULT_COUNTER,
} from '../actions/types';
import Webservice from '../webservices';
import { ROASTING_PLANT_CODES } from '../constants/utilFuctions';
import { SCAN_RETRY_COUNT } from '../constants/autoScan';
import * as selectors from '../selectors';
import { ERROR_TIMEOUT } from '../constants/strings';

function getCurrentDate() {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    return mm + '/' + dd + '/' + yyyy;
}

/**
 * Checking if camera is still in scan mode.
 * If not, response from vision api should be discarded
 **/
function* stopScanning() {
    const scanner = yield select(selectors.scannerData);
    return !scanner.isScanning;
}

function* scanError() {
    if (yield stopScanning()) return;

    yield put({
        type: TEXT_FROM_IMAGE_FAIL,
        isRemoveCamera: true,
    });
    // history.push('home');
}

function* getImageToTextService(data) {
    const scanner = yield select(selectors.scannerData);
    if (scanner.emptyResultCounter === SCAN_RETRY_COUNT) {
        yield put({
            type: SCAN_IDLE,
            scanIdle: true,
            isScanning: false,
            scanRetryCounter: 0,
            isFetchingText: false,
        });
        return;
    }

    if (scanner.scanRetryCounter > SCAN_RETRY_COUNT) {
        yield scanError();
        return;
    }

    try {
        const response = yield call(Webservice.request, data.requestParams);
        let text = (response.responses[0].textAnnotations || [{}])[0].description || '';

        if (!text) {
            yield put({
                type: UPDATE_EMPTY_RESULT_COUNTER,
                emptyResultCounter: scanner.emptyResultCounter + 1,
            });
        }

        let lotIdIndex = -1;
        let lotId = '';
        for (let i = 0; i < ROASTING_PLANT_CODES.length; i++) {
            lotIdIndex = text.indexOf(ROASTING_PLANT_CODES[i]);
            if (lotIdIndex !== -1) {
                lotId = text.substring(lotIdIndex, lotIdIndex + 9);
                break;
            }
        }

        const lotData = {
            lotId: lotId,
            scanner: true,
        };
        yield verifyScannedLotId(lotData);
    } catch (e) {
        if (yield stopScanning()) return;

        if (e.message === ERROR_TIMEOUT) {
            yield put({
                type: TEXT_FROM_IMAGE_TIMEOUT,
            });
        } else {
            yield scanError();
        }
    }
}

function* verifyScannedLotId(data) {
    const regex = RegExp('[A-Z][A-Z0-9]{8}');
    if (regex.test(data.lotId)) {
        if (data.scanner !== undefined) {
            if (yield stopScanning()) return;
        }

        yield put({
            type: LOT_ID_RECOGNITION_SUCCESS,
            lotId: data.lotId,
            isRemoveCamera: true,
            scanDate: getCurrentDate(),
        });
    } else {
        if (data.scanner !== undefined) {
            if (yield stopScanning()) return;

            yield put({
                type: TEXT_FROM_IMAGE_RESPONSE_SUCCESS,
            });
        } else {
            yield scanError();
        }
    }
}

export function* watchImageToText() {
    yield takeLatest(GET_TEXT_FROM_IMAGE, getImageToTextService);
}

export function* watchManualEntryText() {
    yield takeLatest(CHECK_MANUALLY_ENTERED_TEXT, verifyScannedLotId);
}
