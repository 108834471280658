import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTextFromImageAction, purgeStoredDataAction, getSecretKeys } from '../../actions';
import LibCameraPhoto, { FACING_MODES, IMAGE_TYPES } from './CameraPhoto';
import { SCAN_RETRY_COUNT, SCAN_RETRY_TIMER } from '../../constants/autoScan';
import { GOOGLE_VISION_KEY } from '../../constants/keys';
import { updateScanningCountAction } from '../../actions/scannerAction';
import { logger } from '../../utils';

import './styles.css';

function isDynamicPropsUpdate(props, nextProps) {
    return (
        props.idealFacingMode !== nextProps.idealFacingMode ||
        props.idealResolution !== nextProps.idealResolution ||
        props.isMaxResolution !== nextProps.isMaxResolution ||
        props.scanIdle !== nextProps.scanIdle ||
        (!props.isScanning && nextProps.isScanning)
    );
}

class CameraContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.libCameraPhoto = null;
        this.timer = null;
        this.videoRef = React.createRef();
        this.state = {
            dataUri: '',
            isShowVideo: true,
            isCameraStarted: false,
            startCameraErrorMsg: '',
        };
        this.handleTakePhoto = this.handleTakePhoto.bind(this);
    }

    componentDidMount() {
        this.props.purgeStoredData();
        this.libCameraPhoto = new LibCameraPhoto(this.videoRef.current);
        const { idealFacingMode, idealResolution, isMaxResolution } = this.props;
        if (isMaxResolution) {
            this.startCameraMaxResolution(idealFacingMode);
        } else {
            this.startCameraIdealResolution(idealFacingMode, idealResolution);
        }
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (isDynamicPropsUpdate(this.props, nextProps)) {
            this.setState(
                {
                    isCameraStarted: true,
                    startCameraErrorMsg: '',
                },
                () => this.onCameraScan()
            );
        }
    }

    componentWillUnmount() {
        this.stopCamera().catch((error) => {
            logger.error(error.message);
        });
    }

    restartCamera(idealFacingMode, idealResolution, isMaxResolution) {
        this.stopCamera()
            .then()
            .catch((error) => {
                logger.error(error.message);
            })
            .then(() => {
                if (isMaxResolution) {
                    this.startCameraMaxResolution(idealFacingMode);
                } else {
                    this.startCameraIdealResolution(idealFacingMode, idealResolution);
                }
            });
    }

    startCamera(promiseStartCamera) {
        promiseStartCamera
            .then((stream) => {
                this.setState(
                    {
                        isCameraStarted: true,
                        startCameraErrorMsg: '',
                    },
                    () => this.onCameraScan()
                );
            })
            .catch((error) => {
                this.setState({
                    startCameraErrorMsg: `${error.name} ${error.message}`,
                });
                if (typeof this.props.onCameraError === 'function') {
                    this.props.onCameraError(error);
                }
            });
    }

    onCameraScan = () => {
        if (this.timer !== null) {
            logger.log('&&&&& KILLING TIMER ' + this.timer);
            clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
            logger.log('********BEEP ' + this.props.scanRetryCounter, this.props);
            if (this.props.scanRetryCounter > SCAN_RETRY_COUNT || !this.props.isScanning) {
                logger.log('******** CLEAR RETURN');
                clearInterval(this.timer);
                return;
            }

            if (this.props.isScanning && !this.props.isFetchingText && this.props.googleVisionKey !== '') {
                logger.log('******** TAKE PHOTO');
                this.handleTakePhoto();
            }
        }, SCAN_RETRY_TIMER);
        logger.log('&&&&& NEW TIMER ' + this.timer);
    };

    startCameraIdealResolution(idealFacingMode, idealResolution) {
        let promiseStartCamera = this.libCameraPhoto.startCamera(idealFacingMode, idealResolution);
        this.startCamera(promiseStartCamera);
    }

    startCameraMaxResolution(idealFacingMode) {
        let promiseStartCamera = this.libCameraPhoto.startCameraMaxResolution(idealFacingMode);
        this.startCamera(promiseStartCamera);
    }

    stopCamera() {
        if (this.timer !== null) {
            logger.log('&&&&& KILLING TIMER ' + this.timer);
            clearInterval(this.timer);
        }
        return new Promise((resolve, reject) => {
            this.libCameraPhoto
                .stopCamera()
                .then(() => {
                    if (typeof this.props.onCameraStop === 'function') {
                        this.props.onCameraStop();
                    }
                    resolve();
                })
                .catch((error) => {
                    if (typeof this.props.onCameraError === 'function') {
                        this.props.onCameraError(error);
                    }
                    reject(error);
                });
        });
    }

    handleTakePhoto() {
        const {
            sizeFactor,
            imageType,
            imageCompression,
            isImageMirror,
            scanRetryCounter,
            updateCounter,
            getTextFromImage,
        } = this.props;
        const configDataUri = {
            sizeFactor,
            imageType,
            imageCompression,
            isImageMirror,
        };
        updateCounter(scanRetryCounter + 1);

        let dataUri = this.libCameraPhoto.getDataUri(configDataUri);
        let BASE64_MARKER = 'data:image/jpeg;base64,';
        let base64 = dataUri.substring(BASE64_MARKER.length, dataUri.length);
        getTextFromImage(base64, this.props.googleVisionKey);
    }

    render() {
        return (
            <div className='react-html5-camera-photo react-html5-camera-photo-fullscreen'>
                <video ref={this.videoRef} autoPlay={true} muted='muted' playsInline />
            </div>
        );
    }
}

export { CameraContainer, FACING_MODES, IMAGE_TYPES };

const mapStateToProps = (state) => {
    return {
        isRemoveCamera: state.scanner.isRemoveCamera,
        isFetchingText: state.scanner.isFetchingText,
        // vaultInfo: state.vaultInfo,
        // isFetching: state.vaultInfo.isFetching,
        googleVisionKey: state.vaultInfo[GOOGLE_VISION_KEY],
        isScanning: state.scanner.isScanning,
        scanRetryCounter: state.scanner.scanRetryCounter,
        scanIdle: state.scanner.scanIdle,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getSecretKeys: (vaultInfo) => {
            dispatch(getSecretKeys(vaultInfo));
        },
        getTextFromImage: (value, key) => {
            dispatch(getTextFromImageAction(value, key));
        },
        purgeStoredData: () => {
            dispatch(purgeStoredDataAction());
        },
        updateCounter: (count) => {
            dispatch(updateScanningCountAction(count));
        },
    };
}

const Camera = connect(mapStateToProps, mapDispatchToProps)(CameraContainer);

export default Camera;

Camera.propTypes = {
    onTakePhoto: PropTypes.func,
    onTakePhotoAnimationDone: PropTypes.func,
    onCameraError: PropTypes.func,
    idealFacingMode: PropTypes.string,
    idealResolution: PropTypes.object,
    imageType: PropTypes.string,
    isImageMirror: PropTypes.bool,
    isSilentMode: PropTypes.bool,
    isDisplayStartCameraError: PropTypes.bool,
    imageCompression: PropTypes.number,
    isMaxResolution: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    sizeFactor: PropTypes.number,
    onCameraStart: PropTypes.func,
    onCameraStop: PropTypes.func,
};

Camera.defaultProps = {
    isImageMirror: true,
    isDisplayStartCameraError: true,
};
