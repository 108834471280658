import './styles.css';

import React from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';

import Images from '../../images';
import { useNavigate } from 'react-router-dom';
import { getRoastingPlantDetailPath } from '../../constants/paths';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Text from '../common/Text';
import { useTranslation } from 'react-i18next';

const RoastingPlantView = ({ roastingPlant: { name, description, address }, roastedLocation, organizationCode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const roastingPantName = {
        [address]: name,
    };

    const redirectToRPDetaisPage = () => navigate(getRoastingPlantDetailPath(organizationCode));

    return (
        <>
            <div className='roasting-plant-container'>
                <div className='icon-container'>
                    <img src={Images.journeyBarBean} alt='bean' />
                </div>
                <div style={{ paddingLeft: '12px' }}>
                    <p className='journey-title journey-title-spacing heading-sm text-semibold'>{roastedLocation}</p>
                </div>
            </div>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                {address && (
                    <div className='pb1 pl4 pt2' style={{ width: '100%' }} onClick={redirectToRPDetaisPage}>
                        <img src={Images[organizationCode]} alt='roasting plant map' />
                    </div>
                )}
                <Text className='text-sm pt4 pb3 pl6 pr3'>{description}</Text>
                <Button className=' ml6 mb3' onClick={redirectToRPDetaisPage}>
                    {t('details')}
                </Button>
            </Card>
        </>
    );
};

export default RoastingPlantView;
