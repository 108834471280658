import React from 'react';
import { useParams } from 'react-router-dom';
import Card from '@cxeweb/pattern-library/lib/components/card';
import { Detail } from '../common/DetailPage';
import Profile from '../common/Profile';
import QuickFact from '../common/QuickFact';
import Carousel from '@cxeweb/pattern-library/lib/components/carousel';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import { useNavigate } from 'react-router-dom';
import { getRoastingPlantDetailPath } from '../../constants/paths.js';
import { roastingPlants, reserveRoasteries } from '../../constants/roastingPlants';
import { Footer } from '../common/Footer';
import { useTranslation } from 'react-i18next';
import { TileCarousel } from '../common/TileCarousel';

const RoastingPlantView = (props) => {
    const { t } = useTranslation();
    const { orgCode } = useParams();
    const navigate = useNavigate();
    const showReserveRoasteries = reserveRoasteries.some(({ organizationCode }) => organizationCode === orgCode);
    const heading = showReserveRoasteries ? t('roastingPlant.moreRoasteries') : t('roastingPlant.moreRoastingPlants');
    const carouselItems = showReserveRoasteries ? reserveRoasteries : roastingPlants;
    const { name, quickFacts, roastingPlantImageName } = props.roastingPlant;
    const { images, story, requestType, preferredName } = props.profile;

    return (
        <Detail title={name} backTitle=' '>
            <div className={'pb3'}>
                <Card>
                    <img style={{ marginBottom: '-5px' }} src={roastingPlantImageName} alt='roasting plant' />
                </Card>
            </div>
            {quickFacts !== undefined && quickFacts.length !== 0 && (
                <div className={'pb4 pl3 pr3'}>
                    <table>
                        <tbody>
                            {quickFacts.map((data, index) => {
                                return <QuickFact key={index} text={data} />;
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {story !== undefined && story !== '' && images !== undefined && images.length !== 0 && (
                <Profile picture={images[0]} story={story} type={requestType} preferredName={preferredName} />
            )}
            <div className='pb6'>
                <Heading className='pl3 pt3 pb3 heading-xs text-semibold' tagName='h4' size='sm'>
                    {heading}
                </Heading>
                <Carousel
                    slidesPerPage={3}
                    messages={{
                        ariaLabel: 'roasting plants carousel',
                        ariaLabelNext: 'Next',
                        ariaLabelPrev: 'Previous',
                        ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                    }}
                    controlClassName='mt3'
                    showPagerButtons={false}
                >
                    {carouselItems.map(({ name, image, organizationCode }, idx) => (
                        <div
                            className='flex flex-column items-center'
                            key={idx}
                            onClick={() => {
                                navigate(getRoastingPlantDetailPath(organizationCode));
                            }}
                        >
                            <img src={image} alt={`${t(name)} Roasting plant`}></img>
                            <div className='pt2'>{t(name)}</div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                <TileCarousel />
            </Card>
            <Footer />
        </Detail>
    );
};

export default RoastingPlantView;
