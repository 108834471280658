export const ROAST_DETAILS_FAIL = 'ROAST_DETAILS_FAIL';
export const ROAST_DETAILS_SUCCESS = 'ROAST_DETAILS_SUCCESS';
export const ORIGIN_DETAILS_SUCCESS = 'ORIGIN_DETAILS_SUCCESS';
export const CLEAR_ROAST_INFO = 'CLEAR_ROAST_INFO';
export const ORIGIN_DETAILS_FAIL = 'ORIGIN_DETAILS_FAIL';
export const GET_ORIGIN_DETAILS = 'GET_ORIGIN_DETAILS';
export const GET_TEXT_FROM_IMAGE = 'GET_TEXT_FROM_IMAGE';
export const TEXT_FROM_IMAGE_RESPONSE_SUCCESS = 'TEXT_FROM_IMAGE_RESPONSE_SUCCESS';
export const TEXT_FROM_IMAGE_TIMEOUT = 'TEXT_FROM_IMAGE_TIMEOUT';
export const LOT_ID_RECOGNITION_SUCCESS = 'LOT_ID_RECOGNITION_SUCCESS';
export const TEXT_FROM_IMAGE_FAIL = 'TEXT_FROM_IMAGE_FAIL';
export const HIDE_WELCOME_MESSAGE = 'HIDE_WELCOME_MESSAGE';
export const CHECK_MANUALLY_ENTERED_TEXT = 'CHECK_MANUALLY_ENTERED_TEXT';
export const GET_SECRET_KEY = 'GET_SECRET_KEY';
export const GET_ALL_SECRET_KEY = 'GET_ALL_SECRET_KEY';
export const SECRET_KEY_SUCCESS = 'SECRET_KEY_SUCCESS';
export const SECRET_KEY_FAIL = 'SECRET_KEY_FAIL';
export const TOGGLE_CAMERA_SCANNING = 'TOGGLE_CAMERA_SCANNING';
export const UPDATE_SCAN_COUNTER = 'UPDATE_SCAN_COUNTER';
export const ROASTING_PLANT_DETAIL_SUCCESS = 'ROASTING_PLANT_DETAIL_SUCCESS';
export const GET_COUNTRY_DETAILS = 'GET_COUNTRY_DETAILS';
export const COUNTRY_DETAILS_SUCCESS = 'COUNTRY_DETAILS_SUCCESS';
export const COUNTRY_DETAILS_FAIL = 'COUNTRY_DETAILS_FAIL';
export const COUNTRY_DETAILS_PURGE = 'COUNTRY_DETAILS_PURGE';
export const GET_STORIES = 'GET_STORIES';
export const STORIES_SUCCESS = 'STORIES_SUCCESS';
export const STORIES_FAIL = 'STORIES_FAIL';
export const STORIES_PURGE = 'STORIES_PURGE';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';
export const PROFILE_PURGE = 'PROFILE_PURGE';
export const SCAN_IDLE = 'SCAN_IDLE';
export const RESET_SCAN_IDLE = 'RESET_SCAN_IDLE';
export const UPDATE_EMPTY_RESULT_COUNTER = 'UPDATE_EMPTY_RESULT_COUNTER';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const GET_ROASTING_PLANT = 'GET_ROASTING_PLANT';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const CAFE_PRACTICE_STATS_FAIL = 'CAFE_PRACTICE_STATS_FAIL';
export const CAFE_PRACTICE_STATS_SUCCESS = 'CAFE_PRACTICE_STATS_SUCCESS';
export const GET_CAFE_PRACTICE_STATS = 'GET_CAFE_PRACTICE_STATS';
export const PURGE = 'PURGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
