import { GET_ALL_SECRET_KEY, GET_SECRET_KEY } from './types';
import { createWebserviceRequestParams } from '../webservices';

export function getSecretKeys (vaultInfo) {
    return {
        type: GET_ALL_SECRET_KEY,
        access_token: vaultInfo.access_token
    };
}

export function fetchSecretKeyFor (secret) {
    const requestData = {};
    requestData.type = GET_SECRET_KEY;
    requestData.secretName = secret;
    const requestParams = createWebserviceRequestParams(requestData);
    return {
        type: GET_SECRET_KEY,
        requestParams
    };
}