import { PROFILE_FAIL, PROFILE_PURGE, PROFILE_SUCCESS } from '../actions/types';
import { PURGE } from 'redux-persist/es/constants';

const INITIAL_STATE = {
    images: [],
    story: '',
    requestType: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROFILE_SUCCESS:
            return {
                ...state,
                images: action.images,
                story: action.story,
                requestType: action.requestType,
                preferredName: action.preferredName,
            };
        case PROFILE_FAIL:
        case PROFILE_PURGE:
        case PURGE:
            return {
                ...state,
                images: [],
                story: '',
                requestType: '',
            };
        default:
            return state;
    }
};
