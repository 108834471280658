import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFinishedLotOriginDetails } from '../../actions';
import { JourneyView } from './JourneyView';

const Journey = ({ originTitle, roastedLocation }) => {
    const dispatch = useDispatch();
    const { scanner, roastInfo, originData, roastingPlantInfo, locations } = useSelector((state) => state);

    const getOriginDetails = (lotId) => {
        dispatch(getFinishedLotOriginDetails(lotId));
    };

    const props = {
        scanner,
        coffeeName: roastInfo.coffeeName,
        shortDescription: roastInfo.shortDescription,
        description: roastInfo.description,
        blendType: roastInfo.roast,
        regions: roastInfo.regions,
        productSku: originData.productSku,
        totalFarms: originData.totalFarms,
        originData,
        roastingPlant: roastingPlantInfo,
        organizationCode: originData.organizationCode,
        singleOrBlend: roastInfo.singleOrBlend,
        coffeeBannerImage: roastInfo.bannerImageName,
        decafText: roastInfo.decafText,
        cache: locations,
        specialFarmerCountry: roastInfo.specialFarmerCountry,
        getOriginDetails,
        roastedLocation,
        originTitle,
    };

    return <JourneyView {...props} />;
};

export { Journey };
