import { all } from 'redux-saga/effects';
import { watchOriginDetails, watchFetchRoastingPlant, watchProductDetails } from './originDetailSaga';
import { watchImageToText, watchManualEntryText } from './imageTextScanSaga';
import { watchAllSecretKeyValue, watchSecretKeyValue } from './secretKeySaga';
import { watchCountryDetails } from './countryDetailSaga';
import { watchStories } from './storiesSaga';
import { watchProfileData } from './profileSaga';
import { watchCafePracticeStats } from './cafePracticeStats';

// single entry point to start all Sagas at once
export default function* root() {
    yield all([
        watchOriginDetails(),
        watchImageToText(),
        watchManualEntryText(),
        watchSecretKeyValue(),
        watchAllSecretKeyValue(),
        watchCountryDetails(),
        watchStories(),
        watchProfileData(),
        watchFetchRoastingPlant(),
        watchProductDetails(),
        watchCafePracticeStats(),
    ]);
}
