import './style.css';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@cxeweb/pattern-library/lib/components/button';

import { purgeStoredDataAction } from '../../../actions';
import Images from '../../../images';
import { ai } from '../../../telemetryService';
import AICustomEvents from '../../../constants/appInsightEvents';
import { WELCOME_PATH, CAM_SCANNER_PATH } from '../../../constants/paths';
import { useTranslation } from 'react-i18next';

export const ErrorPage = () => {
    ai.trackEvent({ name: AICustomEvents.scanErrorPage });
    let navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(purgeStoredDataAction());
        };
    }, [dispatch]);

    return (
        <div className='error-page-container'>
            <div className='error-page-header'>
                <img className='error-image' src={Images.spillCup} alt='spill-cup' />
                <div className='error-page-header-text'>{t('scan.error.whoops')}</div>
            </div>
            <div className='error-page-text-container'>
                <div className='error-page-text-desc'>{t('scan.error.desc')}</div>
                <div className='error-page-text-title'>{t('scan.error.title')}</div>
                <ul className='error-text-list'>
                    {[
                        t('scan.error.text.one'),
                        t('scan.error.text.two'),
                        t('scan.error.text.three'),
                        t('scan.error.text.four'),
                    ].map((s, idx) => (
                        <li key={idx}>{s}</li>
                    ))}
                </ul>
            </div>
            <div className='error-trace-again-button-container'>
                <Button
                    visualStyle='positive'
                    color='white'
                    className='button-shadow'
                    onClick={() => navigate(WELCOME_PATH)}
                >
                    {t('traceabilityHome')}
                </Button>
                <Button visualStyle='positive' className='button-shadow' onClick={() => navigate(CAM_SCANNER_PATH)}>
                    {t('tryAgain')}
                </Button>
            </div>
        </div>
    );
};
