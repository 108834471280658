import { ROAST_DETAILS_SUCCESS, ORIGIN_DETAILS_FAIL, ORIGIN_DETAILS_SUCCESS, CLEAR_ROAST_INFO } from '../actions/types';

import { PURGE } from 'redux-persist';

const INITIAL_STATE = {
    coffeeName: '',
    shortDescription: '',
    description: '',
    roast: '',
    roastScale: '',
    body: '',
    acidity: '',
    foodPairing: '',
    regions: '',
    processing: '',
    flavorNotes: '',
    suggestedBrewingMethod: [],
    bagImageName: '',
    showErrorView: false,
    posterImageName: '',
    singleOrBlend: '',
    decafText: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ROAST_DETAILS_SUCCESS: {
            return {
                ...state,
                coffeeName: action.coffeeName,
                shortDescription: action.shortDescription,
                description: action.description,
                roast: action.roast,
                roastScale: action.roastScale,
                body: action.body,
                acidity: action.acidity,
                foodPairing: action.foodPairing,
                regions: action.regions,
                processing: action.processing,
                flavorNotes: action.flavorNotes,
                bagImageName: action.bagImageName,
                bannerImageName: action.bannerImageName,
                showErrorView: false,
                posterImageName: action.posterImageName,
                suggestedBrewingMethod: action.suggestedBrewingMethod,
                singleOrBlend: action.singleOrBlend,
                decafText: action.decafText,
                specialFarmerCountry: action.specialFarmerCountry,
                sku: action.sku,
            };
        }
        case ORIGIN_DETAILS_FAIL: {
            return { ...state, showErrorView: true };
        }
        case ORIGIN_DETAILS_SUCCESS: {
            return { ...state, showErrorView: false, originTitle: action.originTitle };
        }
        case CLEAR_ROAST_INFO: {
            return INITIAL_STATE;
        }
        case PURGE: {
            return {
                ...state,
                coffeeName: '',
                shortDescription: '',
                description: '',
                roast: '',
                roastScale: '',
                body: '',
                acidity: '',
                foodPairing: '',
                regions: '',
                processing: '',
                flavorNotes: '',
                suggestedBrewingMethod: [],
                bagImageName: '',
                showErrorView: false,
                posterImageName: '',
                singleOrBlend: '',
                decafText: '',
            };
        }

        default:
            return state;
    }
};
