import {
    GET_ORIGIN_DETAILS,
    GET_TEXT_FROM_IMAGE,
    GET_SECRET_KEY,
    GET_STORIES
} from '../actions/types';

export function getWebserviceConfigData (type) {
    switch (type) {
        case GET_ORIGIN_DETAILS:
            return {
                url: process.env.REACT_APP_ORIGIN_API_ENDPOINT,
                method: 'GET'
            };
        case GET_STORIES:
            return {
                url: 'https://storiesapi.starbucks.com/wp-json/starbucks/v1/search/?per_page=3&search=',
                method: 'GET'
            };
        case GET_TEXT_FROM_IMAGE:
            return {
                url: 'https://vision.googleapis.com/v1/images:annotate?key=',
                method: 'POST'
            };
        case GET_SECRET_KEY:
            return {
                url: process.env.REACT_APP_VAULT_API_ENDPOINT,
                method: 'GET'
            };
        default:
            return {
                url: 'http://someurl.com',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };
    }
}