import './styles.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from '@cxeweb/pattern-library/lib/components/card';
import Images from '../../images';
import Button from '@cxeweb/pattern-library/lib/components/button';
import { getProductDetailPath } from '../../constants/paths';
import Text from '../common/Text';

const CoffeeView = (props) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const redirectHandler = () => {
        navigate(getProductDetailPath(props.productSku));
    };

    return (
        <div className='coffee-container'>
            <div className='icon-container'>
                <img src={Images.journeyBarCup} alt='cup' />
            </div>
            <div className='coffee-content'>
                <p className='journey-title journey-title-spacing heading-sm text-semibold'>
                    {t('home.createdForYou')}
                </p>
            </div>
            <div>
                <Card
                    imagePosition='top'
                    imageUrl={props.coffeeBannerImage}
                    disableBoxShadow
                    style={{ background: '#f7f7f7' }}
                    onClick={redirectHandler}
                />
                <Card disableBoxShadow style={{ background: '#f7f7f7' }} contentClasses={'pb7'}>
                    <Text className='pt4 pl6 pb3 pr4'>{props.description}</Text>
                    <Button className='ml6' onClick={redirectHandler}>
                        {t('learnMore')}
                    </Button>
                </Card>
            </div>
        </div>
    );
};

export default CoffeeView;
