import './style.css';
import React from 'react';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Card from '@cxeweb/pattern-library/lib/components/card';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Stories = ({ stories, onClick, showTitle = true }) => {
    const { t } = useTranslation();
    return (
        stories.length !== 0 && (
            <div className={'ml3 mr3'} style={{ paddingTop: '20px', paddingBottom: '36px' }}>
                {showTitle && (
                    <Heading className={'mb2 text-semibold'} tagName={'h4'}>
                        {t('stories')}
                    </Heading>
                )}
                {stories.map((story, index) => {
                    return (
                        <Card
                            key={index}
                            style={{ minHeight: '120px', maxHeight: '130px' }}
                            imagePosition='left'
                            imageUrl={story.image}
                            contentClasses='pl2 pr2 pt1 pb1 card-content-center flex-column justify-center'
                            containerClasses='mb2'
                            imageSize='sm'
                            imageFit='cover'
                            onClick={() => onClick(story.link)}
                        >
                            {story.heading && (
                                <p className='text-sm mb1' size='md'>
                                    {t(story.heading)}
                                </p>
                            )}
                            <p className='text-xxs valign-middle'>{t(story.title)}</p>
                        </Card>
                    );
                }, {})}
            </div>
        )
    );
};

Stories.propTypes = {
    stories: PropTypes.array.isRequired,
};

Stories.defaultProps = {
    stories: [],
};

export { Stories };
