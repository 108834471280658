import './styles.css';

import React, { useState, useCallback } from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Images from '../../images';
import Text from '../common/Text';
import { CupBagGraph } from '../common/CupBagGraph';
import _ from 'lodash';
import { getCountryDetailPath } from '../../constants/paths';
import { useTranslation } from 'react-i18next';
import { COUNTRY_BANNERS } from '../../constants/countryBanners';
import { useNavigate } from 'react-router-dom';

const OriginView = ({
    coffeeName,
    originData: { countryPercentList, productSku } = {},
    originTitle,
    singleOrBlend,
    specialFarmerCountry = '',
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState(undefined);

    const onSelectCountry = useCallback(({ selectedCountry }) => {
        setSelectedCountry(selectedCountry);
    }, []);

    const calculateHighestPercentCountry = (countryPercentList) => {
        const maxCountryPercentObject = _.maxBy(countryPercentList, function (x) {
            return x.percent;
        });
        const index = _.findIndex(countryPercentList, { percent: maxCountryPercentObject.percent });
        const name = handleSpecialCountryNames(countryPercentList[index].country);
        const highestContributingCountry = name.replace(/ *\([^)]*\) */g, '');
        return highestContributingCountry;
    };

    const handleSpecialCountryNames = (name = '') => {
        return name.includes('Indonesia') ? 'Indonesia' : name;
    };

    const countries = countryPercentList.map((x) => {
        const name = handleSpecialCountryNames(x.country);
        return name.replace(/ *\([^)]*\) */g, '');
    });

    const uniqueCountries = _.sortedUniq(countries);
    const description = t('home.originDesc', { coffeeName });
    const isSingleBlend = singleOrBlend.toLowerCase() === 'single' && countries.length === 1;
    const [singleCountry] = countryPercentList;
    const highestPercentCountryName = isSingleBlend ? countries[0] : calculateHighestPercentCountry(countryPercentList);

    const path = !specialFarmerCountry
        ? getCountryDetailPath(singleCountry.countryCode)
        : getCountryDetailPath(specialFarmerCountry, productSku);
    const toCountryDetails = () => navigate(path);

    const cupData = !specialFarmerCountry
        ? countryPercentList
        : countryPercentList.map((data) => ({
              ...data,
              sku: specialFarmerCountry.includes(data.countryCode) ? productSku : '',
          }));

    const banner = COUNTRY_BANNERS.find((x) => x.code === countryPercentList[0].countryCode);

    return (
        <div className='origin-container'>
            <div className='icon-container'>
                <img src={Images.journeyBarCherry} alt='cherry' />
            </div>
            <div style={{ marginLeft: '14px' }}>
                <p className='journey-title journey-title-spacing heading-sm text-semibold'>
                    {countryPercentList.length > 1 ? t('home.origins') : originTitle}
                </p>
            </div>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                <Text className='text-sm pt3 pl6 pr2'>{isSingleBlend ? t('home.singleOrigin') : description}</Text>
                {isSingleBlend && (
                    <div className='pb1 pl4 pt2' style={{ width: '100%' }}>
                        <img src={banner.image} alt={'country banner'} />
                    </div>
                )}
                {isSingleBlend && <Text className='text-sm pt1 pl6 pr2'>{t('home.singleOriginLearnMore')}</Text>}
                {isSingleBlend ? (
                    <Button className='ml6 mb3 mt3' onClick={toCountryDetails}>
                        {t('details')}
                    </Button>
                ) : (
                    <div className='pb3 pl4 pt1' style={{ width: '100%' }}>
                        <CupBagGraph dataList={cupData} specialFarmerCountry={specialFarmerCountry} />
                    </div>
                )}
            </Card>
        </div>
    );
};

export default OriginView;
