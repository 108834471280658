import './styles.css';

import React, { useEffect, useRef } from 'react';
import CoffeeView from './CoffeeView';
import RoastingPlantView from './RoastingPlantView';
import OriginView from './OriginView';

const JourneyView = (props) => {
    const scrollBar = useRef();

    useEffect(() => {
        const handleScroll = () => {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight - 450;
            const scrolled = (winScroll / height) * 100;
            if (scrollBar.current) {
                scrollBar.current.style.height = `${scrolled > 100 ? 100 : scrolled}%`;
            }
        };

        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className='content-container'>
                <div className='progress-container'>
                    <div className='progress-bar' ref={scrollBar} style={{ height: '0%' }} />
                </div>
                <div>
                    <OriginView {...props} />
                    <RoastingPlantView {...props} />
                </div>
            </div>
            <CoffeeView {...props} />
        </>
    );
};

export { JourneyView };
