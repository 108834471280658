import React, { Component } from 'react';
import { RoastingPlantsPageView } from './RoastingPlantsPageView';

class RoastingPlantsContainer extends Component {
    componentDidMount = () => {
        // ai.trackEvent({ name: AICustomEvents.explorePage });
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <div style={{ paddingBottom: '1px' }}>
                <RoastingPlantsPageView />
            </div>
        );
    }
}

const RoastingPlantsView = RoastingPlantsContainer;

export { RoastingPlantsView };
