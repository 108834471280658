import { ORIGIN_DETAILS_SUCCESS } from '../actions/types';
import { PURGE } from "redux-persist/es/constants";

const INITIAL_STATE = {
    organizationCode: '',
    productSku: '',
    countryPercentList: []
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case ORIGIN_DETAILS_SUCCESS: {
            return {
                ...state,
                organizationCode: action.organizationCode,
                productSku: action.productSku,
                countryPercentList: action.countryPercentList
            };
        }
        case PURGE:
        {
            return { ...state,
                organizationCode: '',
                productSku: '',
                countryPercentList: []
            } ;
        }
        default:
            return state;
    }
}
