import './styles.css';

import React from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Card from '@cxeweb/pattern-library/lib/components/card';
import { Detail } from '../common';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import LoadingIndicator from '@cxeweb/pattern-library/lib/components/loading-indicator';
import Profile from '../common/Profile';
import { Stories } from '../common/Stories';
import QuickFact from '../common/QuickFact';
import Text from '../common/Text';
import Images from '../../images';
import { coffeeRegions } from '../../constants/exploreRegions';
import { COUNTRY_BANNERS } from '../../constants/countryBanners';
import { countryCodeToRegionPath } from '../../constants/utilFuctions';
import { Footer } from '../common/Footer';
import { EXPLORE_LATIN_AMERICA_PATH, EXPLORE_AFRICA_PATH, EXPLORE_ASIAPACIFIC_PATH } from '../../constants/paths';
import { useTranslation } from 'react-i18next';

const CountryDetailView = (props) => {
    window.scrollTo(0, 0);
    let navigate = useNavigate();
    let { countryCode } = useParams();
    const { t } = useTranslation();

    const regionPath = countryCodeToRegionPath(countryCode);

    const goToRegionPage = (url) => navigate(url);

    const getRegionName = () => {
        switch (regionPath) {
            case EXPLORE_LATIN_AMERICA_PATH:
                return t('latinAmerica');
            case EXPLORE_AFRICA_PATH:
                return t('africa');
            case EXPLORE_ASIAPACIFIC_PATH:
                return t('asiaPacific');
            default:
                return '';
        }
    };

    const { profile, countryData, isEnglish } = props;
    const { quickFacts } = countryData;
    const { images, story, requestType, preferredName } = profile;

    const gotoStories = () => {
        window.location.href = isEnglish
            ? 'https://stories.starbucks.com/stories/2019/building-a-sustainable-future-for-coffee-together'
            : 'https://fr.starbucks.ca/responsibility/sourcing/farmer-support';
    };

    const gotoCovidSupport = () => {
        window.location.href = isEnglish
            ? 'https://stories.starbucks.com/press/2020/what-customers-need-to-know-about-starbucks-response-to-covid-19/'
            : 'https://stories.starbucks.ca/fr/stories/2020/ce-que-les-clients-doivent-savoir-a-propos-des-mesures-que-prend-starbucks-face-a-la-covid-19/';
    };

    const banner = COUNTRY_BANNERS.find((x) => x.code === props.countryCode);

    return (
        <Detail title={t(`country:${props.countryCode}`)} backTitle={' '}>
            {!!banner && (
                <div>
                    <Card>
                        <img src={banner.image} alt={'country banner'} />
                    </Card>
                    <div className={'flex flex-row pt2'}>
                        <div className={'flex flex-column items-center p1'}>
                            <img src={Images.keyElevation} alt={'keyElevation'} />
                            <p className='text-center text-xxs'>{t('country.keys.elevation')}</p>
                        </div>
                        <div className={'flex flex-column items-center p1'}>
                            <img src={Images.keyGrowArea} alt={'keyGrowArea'} />
                            <p className='text-center text-xxs'>{t('country.keys.region')}</p>
                        </div>
                        <div className={'flex flex-column items-center p1'}>
                            <img src={Images.keyFSC} alt={'keyFSC'} />
                            <p className='text-center text-xxs'>{t('country.keys.fsc')}</p>
                        </div>
                    </div>
                </div>
            )}
            {quickFacts !== undefined && quickFacts.length !== 0 && (
                <div className={'pt3 pl3 pr3'}>
                    <table>
                        <tbody>
                            {quickFacts.map((data, index) => {
                                return <QuickFact key={index} text={data} />;
                            })}
                            <tr>
                                <td style={{ verticalAlign: 'top' }}>
                                    <img style={{ marginRight: '20px' }} src={Images.farmIcon} alt='pointer' />
                                </td>
                                <td className='flex'>
                                    <Text>{t('country.growRegion')}</Text>
                                    <Link className='ml1 color-greenStarbucks' to={regionPath}>
                                        {getRegionName()}
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            {!props.loadingIndicator && (
                <div className={'pt5'}>
                    {story !== undefined && story !== '' && images !== undefined && images.length !== 0 && (
                        <Profile picture={images[0]} story={story} type={requestType} preferredName={preferredName} />
                    )}
                    <div className='cd-help-support-farmers-container'>
                        <p className='cd-support-farmers-title mb2'>{t('country.investingCoffeeCommunities.header')}</p>
                        <Text className='mb4' style={{ textAlign: 'left' }}>
                            {t('country.investingCoffeeCommunities.text')}
                        </Text>
                        <Card
                            imagePosition='left'
                            style={{ minHeight: '120px', maxHeight: '130px' }}
                            contentClasses='pl2 pr2 pt1 pb1 card-content-center'
                            containerClasses='mb2'
                            imageSize='sm'
                            imageUrl={Images.helpFarmers2}
                            imageFit='cover'
                            onClick={gotoStories}
                        >
                            <p className='text-left '>{t('country.investingCoffeeCommunities.story1')}</p>
                        </Card>
                        <Card
                            imagePosition='left'
                            style={{ minHeight: '120px', maxHeight: '130px' }}
                            contentClasses='pl2 pr2 pt1 pb1 card-content-center'
                            containerClasses='mb2'
                            imageSize='sm'
                            imageUrl={Images.covidSupportThumbnail}
                            imageFit='cover'
                            onClick={gotoCovidSupport}
                        >
                            <p className='text-left '>{t('country.investingCoffeeCommunities.story2')}</p>
                        </Card>
                    </div>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('exploreCoffeeRegions')}
                    </Heading>
                    <Stories stories={coffeeRegions} showTitle={false} onClick={goToRegionPage} />
                </div>
            )}
            <LoadingIndicator active={props.loadingIndicator} />
            <Footer />
        </Detail>
    );
};

export { CountryDetailView };
