import { call, put, takeLatest } from 'redux-saga/effects';
import {
    STORIES_SUCCESS,
    STORIES_FAIL,
    GET_STORIES
} from '../actions/types';
import Webservice from '../webservices';
import { logger } from "../utils";

function * getStoriesService (data) {
    try {

        const response = yield call(Webservice.request, data.requestParams);
        logger.log(response);
        if ((Object.entries(response).length === 0 && response.constructor === Object) || response.posts.length === 0) {
            yield put({type: STORIES_FAIL});
        }

        const posts = response.posts;
        const images = response.images;
        const storyList = posts.map((post)=>{
            const imageObj = images.find((image)=> image.id === post.featured_media);
            let thumbnail = "https://stories.starbucks.com/static/media/SBux-Stories-Default-Image.bcf013fc.jpg";
            if(imageObj!== undefined){
                try {
                    thumbnail = imageObj.media_details.sizes.thumbnail.source_url;
                }catch{
                    thumbnail = "https://stories.starbucks.com/static/media/SBux-Stories-Default-Image.bcf013fc.jpg"
                }
            }

            return {
                title: post.title.rendered,
                image: thumbnail,
                link: post.link
            }
        });

        yield put({
            type: STORIES_SUCCESS,
            posts: storyList
        });

    } catch (e) {
        logger.log('Error : ', {e});
        yield put({type: STORIES_FAIL});
    }
}

export function * watchStories () {
    yield takeLatest(GET_STORIES, getStoriesService);
}