import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
    GET_ORIGIN_DETAILS,
    GET_ROASTING_PLANT,
    GET_PRODUCT_DETAILS,
    ORIGIN_DETAILS_FAIL,
    ORIGIN_DETAILS_SUCCESS,
    ROAST_DETAILS_SUCCESS,
    ROASTING_PLANT_DETAIL_SUCCESS,
} from '../actions/types';
import Webservice from '../webservices';
import Countries from '../constants/countryCodes';
import { convertUnicode } from '../constants/utilFuctions';
import cdnUrls from '../constants/cdnUrls';
import { logger } from '../utils';

function* getOriginDataService(data) {
    try {
        const response = yield call(Webservice.request, data.requestParams);

        const { item_no: productSku, finish_lot: finishedLotId, lots } = response;

        // fetch product JSON from CDN
        yield call(fetchProduct, productSku);

        //Extracting Roasting Plant CODE from finishedLotId
        const organizationCode = finishedLotId.substring(0, 3);
        yield call(fetchRoastingPlant, organizationCode);

        //Extract list of origin countries and discard the ones that have contributed less then 3%
        const countryPercentList = lots
            .filter((countryData) => {
                return parseFloat(countryData.percentage_per_country).toFixed(2) >= 3;
            })
            .map((countryData) => {
                const countryCode = countryData.country_of_origin_code;
                const countryName = Countries[countryCode];
                return {
                    countryCode: countryCode,
                    country: countryName,
                    percent: parseInt(countryData.percentage_per_country),
                };
            });

        countryPercentList.sort(function (a, b) {
            return a.percent - b.percent;
        });

        yield put({
            type: ORIGIN_DETAILS_SUCCESS,
            organizationCode,
            productSku,
            countryPercentList,
        });
    } catch (e) {
        logger.error('Error : ', { e });
        yield put({ type: ORIGIN_DETAILS_FAIL });
    }
}

function* fetchRoastingPlant(data) {
    const code = typeof data === 'string' ? data : data.orgCode;
    const lng = yield select((state) => state.translation.language);
    const roastingPlantUrl = cdnUrls.getRoastingPlant(code, lng);
    const { name, quick_facts, description, address } = yield call(Webservice.requestCDN, roastingPlantUrl);
    const roastingPlantImgUrl = cdnUrls.getRoastingPlantImage(code);
    yield put({
        type: ROASTING_PLANT_DETAIL_SUCCESS,
        name: convertUnicode(name),
        address: address,
        description: convertUnicode(description),
        quickFacts: quick_facts.map((a) => convertUnicode(a)),
        roastingPlantImageName: roastingPlantImgUrl,
    });
}

function* fetchProduct(data) {
    const sku = typeof data === 'string' ? data : data.sku;
    const lng = yield select((state) => state.translation.language);
    const productJsonUrl = cdnUrls.getProductJson(sku, lng);
    const productBagImgUrl = cdnUrls.getBagImage(sku);
    const productBannerImgUrl = cdnUrls.getBannerImage(sku);
    const productPosterImgUrl = cdnUrls.getPosterImage(sku);
    const {
        product_name,
        short_description,
        description,
        roast,
        roast_scale,
        body,
        acidity,
        food_pairing,
        regions,
        processing,
        flavor_notes,
        suggested_brewing_method,
        blend_single_origin,
        decaf_text,
        special_farmer_country,
    } = yield call(Webservice.requestCDN, productJsonUrl);

    yield put({
        type: ROAST_DETAILS_SUCCESS,
        coffeeName: convertUnicode(product_name),
        shortDescription: convertUnicode(short_description),
        description: convertUnicode(description),
        roast: roast,
        roastScale: roast_scale,
        body: body,
        acidity: acidity,
        foodPairing: food_pairing,
        regions: regions,
        processing: processing,
        flavorNotes: flavor_notes,
        suggestedBrewingMethod: suggested_brewing_method,
        bagImageName: productBagImgUrl,
        bannerImageName: productBannerImgUrl,
        posterImageName: productPosterImgUrl,
        singleOrBlend: blend_single_origin,
        decafText: decaf_text,
        specialFarmerCountry: special_farmer_country,
        sku,
    });
}

export function* watchOriginDetails() {
    yield takeLatest(GET_ORIGIN_DETAILS, getOriginDataService);
}

export function* watchFetchRoastingPlant() {
    yield takeLatest(GET_ROASTING_PLANT, fetchRoastingPlant);
}

export function* watchProductDetails() {
    yield takeLatest(GET_PRODUCT_DETAILS, fetchProduct);
}
