import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './../locales/en/translation.json';
import productEN from './../locales/en/product.json';
import countryEN from './../locales/en/country.json';
import translationFR from './../locales/fr/translation.json';
import productFR from './../locales/fr/product.json';
import countryFR from './../locales/fr/country.json';
import translationES from './../locales/es/translation.json';
import HAFarmEN from './../locales/en/HAFarm.json';
import HAFarmES from './../locales/es/HAFarm.json';
import HAFarmFR from './../locales/fr/HAFarm.json';

const resources = {
    en: {
        translation: translationEN,
        product: productEN,
        country: countryEN,
        HAFarm: HAFarmEN,
    },
    fr: {
        translation: translationFR,
        product: productFR,
        country: countryFR,
        HAFarm: HAFarmFR,
    },
    es: {
        translation: translationES,
        HAFarm: HAFarmES,
    },
};

const supportedLngs = ['en', 'fr', 'es'];

i18n.use(detector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'en',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

i18n.languages = supportedLngs;

export default i18n;
export { supportedLngs };
