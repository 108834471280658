import { call, put, takeLatest } from 'redux-saga/effects';
import { CAFE_PRACTICE_STATS_FAIL, CAFE_PRACTICE_STATS_SUCCESS, GET_CAFE_PRACTICE_STATS } from '../actions/types';
import Webservice from '../webservices';
import cdnUrls from '../constants/cdnUrls';
import { logger } from '../utils';

function* getCafePracticeStatsService({ region }) {
    try {
        const cafePracticeStatsUrl = cdnUrls.getCafePracticeStats(region);

        const response = yield call(Webservice.requestCDN, cafePracticeStatsUrl);

        if (Object.entries(response).length === 0 && response.constructor === Object) {
            yield put({ type: CAFE_PRACTICE_STATS_FAIL });
        }

        yield put({
            type: CAFE_PRACTICE_STATS_SUCCESS,
            totalFarmCount: response.total_farm_count.toLocaleString(),
            smallFarmPer: response.small_farms_per,
        });
    } catch (e) {
        logger.log('Error : ', { e });
        yield put({ type: CAFE_PRACTICE_STATS_FAIL });
    }
}

export function* watchCafePracticeStats() {
    yield takeLatest(GET_CAFE_PRACTICE_STATS, getCafePracticeStatsService);
}
