import { ROASTING_PLANT_DETAIL_SUCCESS } from '../actions/types';
import { PURGE } from "redux-persist/es/constants";

const INITIAL_STATE = {
    name :'',
    address: '',
    description:''
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case ROASTING_PLANT_DETAIL_SUCCESS: {
            return {
                ...state,
                name: action.name,
                address: action.address,
                description: action.description,
                quickFacts: action.quickFacts,
                roastingPlantImageName: action.roastingPlantImageName,
            };
        }
        case PURGE:
        {
            return { ...state,
                name :'',
                address: '',
                description:''
            } ;
        }
        default:
            return state;
    }
}
