import Images from '../images';

export const roastingPlants = [
    {
        organizationCode: 'ASP',
        name: 'roastingPlant.location.ASP',
        image: Images.exploreASPThumbnail,
    },
    {
        organizationCode: 'CRP',
        name: 'roastingPlant.location.CRP',
        image: Images.exploreCRPThumbnail,
    },
    {
        organizationCode: 'KRP',
        name: 'roastingPlant.location.KRP',
        image: Images.exploreKRPThumbnail,
    },
    {
        organizationCode: 'SRP',
        name: 'roastingPlant.location.SRP',
        image: Images.exploreSRPThumbnail,
    },
    {
        organizationCode: 'YRP',
        name: 'roastingPlant.location.YRP',
        image: Images.exploreYRPThumbnail,
    },
];

export const reserveRoasteries = [
    { organizationCode: 'R06', name: 'roastingPlant.location.R06', image: Images.exploreR06Thumbnail },
    { organizationCode: 'NRP', name: 'roastingPlant.location.NRP', image: Images.exploreNYRPThumbnail },
    { organizationCode: 'RRP', name: 'roastingPlant.location.RRP', image: Images.exploreRRPThumbnail },
];
