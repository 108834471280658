import { GET_PROFILE } from './types';
import { createWebserviceRequestParams } from '../webservices';
import { QUERY_FARMER, QUERY_ROASTER } from '../constants/queryStrings';

export function getProfileDataAction(requestType, data, filter) {
    const requestData = {};
    requestData.type = GET_PROFILE;
    requestData.requestType = requestType;
    if (requestType === QUERY_FARMER) {
        requestData.queryString = `${requestType}&CountryCode=${data}`;
    } else if (requestType === QUERY_ROASTER) {
        requestData.queryString = `${requestType}&RoastingPlantCode=${data}`;
    } else {
        requestData.queryString = `${requestType}&CountryCode=${data}`;
    }

    const requestParams = createWebserviceRequestParams(requestData);
    return {
        type: GET_PROFILE,
        requestParams,
        code: data,
        filter,
    };
}
