import {
    ROAST_DETAILS_SUCCESS,
    GET_ORIGIN_DETAILS,
    ORIGIN_DETAILS_FAIL,
    GET_COUNTRY_DETAILS,
    COUNTRY_DETAILS_SUCCESS,
    COUNTRY_DETAILS_FAIL,
    GET_ROASTING_PLANT,
    ROASTING_PLANT_DETAIL_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    showActivityIndicator: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORIGIN_DETAILS:
        case GET_COUNTRY_DETAILS:
        case GET_ROASTING_PLANT:
            return { ...state, showActivityIndicator: true };
        case ROAST_DETAILS_SUCCESS:
        case ORIGIN_DETAILS_FAIL:
        case COUNTRY_DETAILS_SUCCESS:
        case COUNTRY_DETAILS_FAIL:
        case ROASTING_PLANT_DETAIL_SUCCESS:
            return { ...state, showActivityIndicator: false };
        default:
            return state;
    }
};
