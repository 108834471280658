import './styles.css';

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@cxeweb/pattern-library/lib/components/card';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Carousel from '@cxeweb/pattern-library/lib/components/carousel';
import { Stories } from '../common/Stories';
import { TileCarousel } from '../common/TileCarousel';
import * as countries from '../../constants/exploreRegionCountryList';
import * as products from '../../constants/exploreRegionProducts';
import Text from '../common/Text';
import Images from '../../images';
import { Detail } from '../common/DetailPage';
import {
    getProductDetailPath,
    getCountryDetailPath,
    getCountryListPath,
    EXPLORE_LATIN_AMERICA_PATH,
    EXPLORE_AFRICA_PATH,
    EXPLORE_ASIAPACIFIC_PATH,
    CAFE_PRACTICES_PATH,
} from '../../constants/paths';
import cdnUrls from '../../constants/cdnUrls';
import { coffeeRegions } from '../../constants/exploreRegions';
import { Footer } from '../common/Footer';
import { useTranslation } from 'react-i18next';

const regions = {
    latinamerica: {
        title: 'region.latinamerica.title',
        growRegionImage: Images.growRegionLatinAmerica,
        descriptions: ['region.latinamerica.descOne', 'region.latinamerica.descTwo'],
        flavorProfile: 'region.latinamerica.flavor',
        harvestSeason: [],
        countries: countries.LATIN_AMERICA_COUNTRIES,
        profileImage: Images.growRegionLatinAmericaProfile,
        quote: 'region.latinamerica.quote',
        quoteSignature1: 'region.latinamerica.signature.one',
        quoteSignature2: 'region.latinamerica.signature.two',
        quoteSignature3: 'region.latinamerica.signature.three',
        quoteSignature4: 'region.latinamerica.signature.four',
        products: products.LATIN_AMERICA_PRODUCTS.map(({ name, sku }) => ({
            name,
            sku,
            image: cdnUrls.getBagImage(sku),
        })),
        stories: coffeeRegions.filter(({ link }) => link !== EXPLORE_LATIN_AMERICA_PATH),
    },
    africa: {
        title: 'region.africa.title',
        growRegionImage: Images.growRegionAfrica,
        descriptions: ['region.africa.desc'],
        flavorProfile: 'region.africa.flavor',
        harvestSeason: [],
        countries: countries.AFRICA_COUNTRIES,
        profileImage: Images.growRegionAfricaProfile,
        quote: 'region.africa.quote',
        quoteSignature1: 'region.africa.signature.one',
        quoteSignature2: 'region.africa.signature.two',
        quoteSignature3: 'region.africa.signature.three',
        quoteSignature4: 'region.africa.signature.four',
        products: products.AFRICA_PRODUCTS.map(({ name, sku }) => ({
            name,
            sku,
            image: cdnUrls.getBagImage(sku),
        })),
        stories: coffeeRegions.filter(({ link }) => link !== EXPLORE_AFRICA_PATH),
    },
    asiapacific: {
        title: 'region.asiaPacific.title',
        growRegionImage: Images.growRegionAsiaPacific,
        descriptions: ['region.asiaPacific.desc'],
        flavorProfile: 'region.asiaPacific.flavor',
        harvestSeason: [],
        countries: countries.ASIA_PACIFIC_COUNTRIES,
        profileImage: Images.growRegionAsiaPacificProfile,
        quote: 'region.asiaPacific.quote',
        quoteSignature1: 'region.asiaPacific.signature.one',
        quoteSignature2: 'region.asiaPacific.signature.two',
        quoteSignature3: 'region.asiaPacific.signature.three',
        quoteSignature4: 'region.asiaPacific.signature.four',
        products: products.ASIA_PACIFIC_PRODUCTS.map(({ name, sku }) => ({
            name,
            sku,
            image: cdnUrls.getBagImage(sku),
        })),
        stories: coffeeRegions.filter(({ link }) => link !== EXPLORE_ASIAPACIFIC_PATH),
    },
};

const RegionsView = ({ cafePracticeStats }) => {
    window.scrollTo(0, 0);
    const { t } = useTranslation();
    let { region } = useParams();
    let navigate = useNavigate();
    const {
        title,
        growRegionImage,
        descriptions,
        flavorProfile,
        products,
        profileImage,
        quote,
        quoteSignature1,
        quoteSignature2,
        quoteSignature3,
        quoteSignature4,
        countries,
        stories,
    } = regions[region];

    const goToCountryListPage = () => {
        navigate(getCountryListPath(region));
    };

    return (
        <Detail title={t(title)} backTitle={' '}>
            <Card imagePosition='top' disableBoxShadow>
                <div style={{ maxWidth: '700px' }}>
                    <ImageLoader
                        wrapInFlex={{
                            enabled: true,
                            width: 700,
                            height: 350,
                        }}
                        src={growRegionImage}
                        alt={`${t(title)} grow region`}
                    />
                </div>
                {descriptions.map((text, idx) => (
                    <Text key={idx} className='text-sm p3'>
                        {t(text)}
                    </Text>
                ))}
                <div className='pt3 text-center text-semibold color-greenStarbucks' tagName='h3' size='md'>
                    {t('region.flavorProfile')}
                </div>
                <Text className='text-sm text-center SoDoSans-Regular'>{t(flavorProfile)}</Text>
                <div
                    className='pt5 text-center text-semibold color-greenStarbucks'
                    tagName='h3'
                    size='md'
                    onClick={() => navigate(CAFE_PRACTICES_PATH)}
                >
                    {t('region.practiceFarms')}
                </div>
                <div className='p2 flex'>
                    <div className='flex-grow flex flex-column items-center'>
                        <p className='text-xxl text-center text-semibold color-greenStarbucks'>{`${cafePracticeStats.totalFarmCount}`}</p>
                        <span className='coffee-production-title'>{t('region.verifiedFarms')}</span>
                    </div>
                    <div className='flex-grow flex flex-column items-center'>
                        <p className='text-xxl text-center text-semibold color-greenStarbucks'>{`${cafePracticeStats.smallFarmPer}%`}</p>
                        <span className='coffee-production-title'>{t('region.smallFarms')}</span>
                    </div>
                </div>
                <div className='pb5'>
                    <p className='text-xxs p3 text-center'>{t('region.practicesText')}</p>
                    <p
                        className='text-xxs text-center color-greenStarbucks text-underline'
                        onClick={() => navigate(CAFE_PRACTICES_PATH)}
                    >
                        {t('learnMore')}
                    </p>
                </div>
            </Card>
            <Card imagePosition='top' disableBoxShadow className='mt3 mb3' style={{ background: '#f7f7f7' }}>
                <div className='flex justify-between items-end pr3 pb3'>
                    <Heading className='pl3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('region.featuredOrigins')}
                    </Heading>
                    <button className='text-semibold color-greenStarbucks' onClick={goToCountryListPage}>
                        {t('region.seeAll', { count: countries.length })}
                    </button>
                </div>
                <Carousel
                    slidesPerPage={3}
                    messages={{
                        ariaLabel: 'product detail carousel',
                        ariaLabelNext: 'Next',
                        ariaLabelPrev: 'Previous',
                        ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                    }}
                    carouselClassName='mt3'
                    containerClassName='carousel-container'
                    showPagerButtons={false}
                >
                    {countries.map(({ name, image, code }, idx) => (
                        <div className='flex flex-column items-center mr2 ml2' key={idx}>
                            <div
                                className='country-carousel-image-container'
                                onClick={() => {
                                    navigate(getCountryDetailPath(code));
                                }}
                            >
                                <img src={image} alt={t(name)}></img>
                            </div>
                            <div>{t(name)}</div>
                        </div>
                    ))}
                </Carousel>
            </Card>
            <div className='story-container mb5' style={{ textAlign: 'center' }}>
                <img src={profileImage} alt={'region quote'} />
                <Card
                    style={{ marginTop: '-40px', position: 'relative' }}
                    contentClasses='pl3 pr3 pt4 pb2 cd-card-content-center'
                    containerClasses={'ml2 mr2'}
                >
                    <Text style={{ textAlign: 'left', fontStyle: 'italic' }}>{t(quote)}</Text>
                    <div className='flex flex-column flex-grow items-end'>
                        <p className='text-md color-greenStarbucks'>{t(quoteSignature1)}</p>
                        <p className='text-xxs text-right'>{t(quoteSignature2)}</p>
                        <p className='text-xxs'>{t(quoteSignature3)}</p>
                        <p className='text-xxs'>{t(quoteSignature4)}</p>
                    </div>
                </Card>
            </div>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#edebe9' }} containerClasses='pb3'>
                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('region.featureCoffee', { region: `${t(title)}` })}
                </Heading>
                <Carousel
                    slidesPerPage={3}
                    messages={{
                        ariaLabel: 'product detail carousel',
                        ariaLabelNext: 'Next',
                        ariaLabelPrev: 'Previous',
                        ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                    }}
                    controlClassName='mt3'
                    containerClassName='carousel-container'
                    showPagerButtons={false}
                >
                    {products.map(({ name, image, sku }, idx) => (
                        <div className='flex flex-column items-center mr2 ml2' key={idx}>
                            <div
                                className='product-detail-carousel-image-container'
                                onClick={() => {
                                    navigate(getProductDetailPath(sku));
                                }}
                            >
                                <img src={image} style={{ width: '50%' }} alt={t(name)}></img>
                            </div>
                            <div className='text-center'>{t(name)}</div>
                        </div>
                    ))}
                </Carousel>
            </Card>
            <div style={{ background: '#f7f7f7' }} className='mt3'>
                <Heading className='pl3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('region.tourOtherRegions')}
                </Heading>
                <Stories
                    stories={stories}
                    showTitle={false}
                    onClick={(url) => {
                        navigate(url);
                    }}
                />
            </div>
            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                <TileCarousel />
            </Card>
            <Footer />
        </Detail>
    );
};

export default RegionsView;
