import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@cxeweb/pattern-library/lib/components/card';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import * as countries from '../../constants/exploreRegionCountryList';
import Images from '../../images';
import { Detail } from '../common/DetailPage';
import { getCountryDetailPath } from '../../constants/paths';
import { Footer } from '../common/Footer';
import { TileCarousel } from '../common/TileCarousel';

import './styles.css';
import { useTranslation } from 'react-i18next';

const getCountryList = (name) => {
    if (!name) return [];
    switch (name) {
        case 'africa':
            return countries.AFRICA_COUNTRIES;
        case 'latinamerica':
            return countries.LATIN_AMERICA_COUNTRIES;
        case 'asiapacific':
            return countries.ASIA_PACIFIC_COUNTRIES;
        default:
            return;
    }
};

const getRegionName = (name) => {
    switch (name) {
        case 'africa':
            return 'africa';
        case 'latinamerica':
            return 'latinAmerica';
        case 'asiapacific':
            return 'asiaPacific';
        default:
            return '';
    }
};

const CountryListItem = ({ name, image, code }) => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const goToCountryDetails = () => {
        navigate(getCountryDetailPath(code));
    };

    return (
        <div className='country-list-item' onClick={goToCountryDetails}>
            <img src={image} style={{ width: '90%' }} alt={t(name)}></img>
            <div className='text-center'>{t(name)}</div>
        </div>
    );
};

const CountryList = () => {
    window.scrollTo(0, 0);
    const { t } = useTranslation();
    let { region } = useParams();
    const countries = getCountryList(region);
    const regionName = getRegionName(region);
    const title = t('country.featuredOrigion', { name: t(regionName) });

    return (
        <Detail title={title} backTitle={' '}>
            <Card contentClasses='pt4 pb2' style={{ background: '#edebe9' }}>
                <div className='pl3 pr3 country-list-container'>
                    {countries.map(({ name, image, code }, idx) => (
                        <CountryListItem name={name} image={image} code={code} key={idx} />
                    ))}
                </div>
            </Card>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#edebe9' }}>
                <div style={{ maxWidth: '700px' }}>
                    <ImageLoader
                        wrapInFlex={{
                            enabled: true,
                            width: 700,
                            height: 380,
                        }}
                        src={Images.exploreRegionCherries}
                        alt='seasonal availability'
                    />
                </div>
                <Heading className='pl3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('country.seasonalAvailability.header')}
                </Heading>
                <p className='text-sm p3'>{t('country.seasonalAvailability.text')}</p>
            </Card>
            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                <TileCarousel />
            </Card>
            <Footer />
        </Detail>
    );
};

export { CountryList };
