import React from 'react';
import PropTypes from 'prop-types';
import HeaderCrate from '@cxeweb/pattern-library/lib/components/header-crate';
import Subnav from '@cxeweb/pattern-library/lib/components/subnav';

import { ContentArea } from '../ContentArea';
import './style.css';
import { useNavigate } from 'react-router-dom';

const Detail = ({ title, children, backTitle }) => {
    let navigate = useNavigate();
    return (
        <div>
            <HeaderCrate>
                <HeaderCrate.Inner style={{ paddingBottom: '0px' }}>
                    <div className='pt3 pb3'>
                        <Subnav
                            breadcrumb={{
                                children: `${backTitle}`,
                                onClick: () => {
                                    navigate(-1);
                                },
                            }}
                            primary={{
                                children: `${title}`,
                            }}
                        />
                    </div>
                </HeaderCrate.Inner>
            </HeaderCrate>
            <ContentArea className='mainContentArea'>{children}</ContentArea>
        </div>
    );
};

Detail.propTypes = {
    title: PropTypes.string.isRequired,
    backTitle: PropTypes.string.isRequired,
};

Detail.defaultProps = {
    backTitle: ' ',
};

export { Detail };
