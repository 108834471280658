import {
    TOGGLE_CAMERA_SCANNING,
    UPDATE_SCAN_COUNTER,
    UPDATE_EMPTY_RESULT_COUNTER,
    RESET_SCAN_IDLE,
    CHECK_MANUALLY_ENTERED_TEXT
} from './types';

export function toggleCameraScanningAction(isScanning) {
    return {
        type: TOGGLE_CAMERA_SCANNING,
        isScanning: isScanning
    };
}

export function updateScanningCountAction(count) {
    return {
        type: UPDATE_SCAN_COUNTER,
        scanRetryCount: count
    };
}

export function updateEmptyResultAction(count) {
    return {
        type: UPDATE_EMPTY_RESULT_COUNTER,
        emptyResultCounter: count
    };
}

export function resetScanIdleAction() {
    return {
        type: RESET_SCAN_IDLE
    };
}

export function enterLotIdManually(lotId) {
    return {
        type: CHECK_MANUALLY_ENTERED_TEXT,
        lotId
    };
}
