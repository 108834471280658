import { getWebserviceConfigData } from './config/apiConfig';
import {
    GET_COUNTRY_DETAILS,
    GET_ORIGIN_DETAILS,
    GET_PROFILE,
    GET_SECRET_KEY,
    GET_STORIES,
    GET_TEXT_FROM_IMAGE,
} from './actions/types';
import { ERROR_TIMEOUT } from './constants/strings';
import { logger } from './utils';

const Webservice = {
    request(data) {
        logger.log('URL: ' + data.url);
        return promiseTimeout(data.timeout, fetch(data.url, data.params)) // webservice call..
            .then(statusHelper)
            .then((response) => response.text())
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (err) {
                    return text;
                }
            })
            .catch((error) => {
                throw error;
            })
            .then((data) => data)
            .then((text) => {
                logger.log('response : ', JSON.stringify(text));
                return text;
            });
    },

    requestCDN(url) {
        logger.log('URL: ' + url);
        return promiseTimeout(20000, fetch(url, { method: 'GET' })) // webservice call..
            .then(statusHelper)
            .then((response) => response.text())
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (err) {
                    return text;
                }
            })
            .catch((error) => {
                logger.error('request cdn error: ', error);
            });
    },
};

function promiseTimeout(ms, promise) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error(ERROR_TIMEOUT));
        }, ms);
        promise.then(resolve).catch(reject);
    });
}

function statusHelper(response) {
    logger.log('Response from API : ' + JSON.stringify(response));
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error('Server error with status: ' + response.status));
    }
}

export default Webservice;

export function createWebserviceRequestParams(data) {
    const type = data.type;
    const webserviceData = getWebserviceConfigData(type);
    switch (type) {
        case GET_ORIGIN_DETAILS:
            // let productId = 'SRP198349';  /*TODO TESTING - TO BE REMOVED */
            return {
                url: webserviceData.url + data.productId + '.json',
                //?sp=racwdl&st=2020-05-29T05:02:32Z&se=2050-01-31T05:02:00Z&sv=2019-10-10&sr=c&sig=YIkKVsC5%2Fi73Yj5sQ2w906oxmdhJfEEuRlBLsX6AksY%3D',
                params: {
                    method: webserviceData.method,
                },
                timeout: 20000,
            };
        case GET_COUNTRY_DETAILS: {
            return {
                url: webserviceData.url + data.countryCode,
                params: {
                    method: webserviceData.method,
                },
                timeout: 20000,
                countryCode: data.countryCode,
            };
        }
        case GET_STORIES: {
            return {
                url: encodeURI(webserviceData.url + data.topic),
                params: {
                    method: webserviceData.method,
                },
                timeout: 15000,
            };
        }
        case GET_PROFILE: {
            return {
                url: webserviceData.url + data.queryString,
                params: {
                    method: webserviceData.method,
                },
                timeout: 15000,
                requestType: data.requestType,
            };
        }
        case GET_SECRET_KEY: {
            return {
                url: webserviceData.url + data.secretName,
                params: {
                    method: webserviceData.method,
                    secret: data.secretName,
                },
                timeout: 10000,
            };
        }
        case GET_TEXT_FROM_IMAGE: {
            return {
                url: webserviceData.url + data.key,
                params: {
                    method: webserviceData.method,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        requests: [
                            {
                                image: {
                                    content: data.base64,
                                },
                                features: [
                                    {
                                        type: 'TEXT_DETECTION',
                                        maxResults: 1,
                                    },
                                ],
                            },
                        ],
                    }),
                },
                timeout: 10000,
            };
        }
        default:
            return {
                url: 'http://someurl.com',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            };
    }
}
