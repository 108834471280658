import './styles.css';

import React from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Carousel from '@cxeweb/pattern-library/lib/components/carousel';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Text from '../common/Text';
import Images from '../../images';
import { Detail } from '../common/DetailPage';
import { getRoastingPlantDetailPath } from '../../constants/paths.js';
import { roastingPlants, reserveRoasteries } from '../../constants/roastingPlants';
import { Footer } from '../common/Footer';
import { TileCarousel } from '../common/TileCarousel';

import './styles.css';

const RoastingPlantsPageView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Detail title={t('roastingPlant.pageHeading')} backTitle={' '}>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                <div>
                    <ImageLoader src={Images.exploreRoastingBanner} alt='roasting' />
                </div>
                <div className='p3'>
                    <p className='text-sm pb3'>{t('roastingPlant.description.one')}</p>
                    <p className='text-sm '>{t('roastingPlant.description.two')}</p>
                </div>
            </Card>
            <Card className='pb3' imagePosition='top' disableBoxShadow style={{ background: '#edebe9' }}>
                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('roastingPlant.northAmerica.title')}
                </Heading>
                <div>
                    <p className='text-sm p3'>{t('roastingPlant.northAmerica.text')}</p>
                </div>
                <Heading className='pl3 pt3 pb3 heading-xs text-semibold' tagName='h4' size='sm'>
                    {t('roastingPlant.fiveRoastingPlants')}
                </Heading>
                <Carousel
                    slidesPerPage={3}
                    messages={{
                        ariaLabel: 'roasting plants carousel',
                        ariaLabelNext: 'Next',
                        ariaLabelPrev: 'Previous',
                        ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                    }}
                    controlClassName='mt3'
                    showPagerButtons={false}
                >
                    {roastingPlants.map(({ name, image, organizationCode }, idx) => (
                        <div
                            className='flex flex-column items-center'
                            key={idx}
                            onClick={() => {
                                navigate(getRoastingPlantDetailPath(organizationCode));
                            }}
                        >
                            <img src={image} alt={`${t(name)} Roasting plant`}></img>
                            <div className='pt2'>{t(name)}</div>
                        </div>
                    ))}
                </Carousel>
                <Heading className='pl3 pt6 pb3 heading-xs text-semibold' tagName='h4' size='sm'>
                    {t('roastingPlant.threeReserveRoasteries')}
                </Heading>
                <Carousel
                    slidesPerPage={3}
                    messages={{
                        ariaLabel: 'reserve roasteries carousel',
                        ariaLabelNext: 'Next',
                        ariaLabelPrev: 'Previous',
                        ariaLabelPages: (pageNumber) => `go to page ${pageNumber}`,
                    }}
                    controlClassName='mt3'
                    showPagerButtons={false}
                >
                    {reserveRoasteries.map(({ name, image, organizationCode }, idx) => (
                        <div
                            className='flex flex-column items-center'
                            key={idx}
                            onClick={() => {
                                navigate(getRoastingPlantDetailPath(organizationCode));
                            }}
                        >
                            <img src={image} alt={`${t(name)} reserve roastery`}></img>
                            <div>{t(name)}</div>
                        </div>
                    ))}
                </Carousel>
            </Card>
            <div className='story-container mb5' style={{ textAlign: 'center' }}>
                <img src={Images.exploreRoastingPlantsQuote} alt={'region quote'} />
                <Card
                    style={{ marginTop: '-40px', position: 'relative' }}
                    contentClasses='pl3 pr3 pt4 pb2 cd-card-content-center'
                    containerClasses={'ml2 mr2'}
                >
                    <Text style={{ textAlign: 'left', fontStyle: 'italic' }}>{t('roastingPlant.quote')}</Text>
                    <div className='flex flex-column flex-grow items-end'>
                        <p className='text-md color-greenStarbucks'>{t('roastingPlant.quoteSignature.one')}</p>
                        <p className='text-xxs text-right'>{t('roastingPlant.quoteSignature.two')}</p>
                        <p className='text-xxs'>{t('roastingPlant.quoteSignature.three')}</p>
                        <p className='text-xxs'>{t('roastingPlant.quoteSignature.four')}</p>
                    </div>
                </Card>
            </div>
            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                <TileCarousel />
            </Card>
            <Footer />
        </Detail>
    );
};

export { RoastingPlantsPageView };
