import './styles.css';

import * as React from 'react';
import Sticky from 'react-sticky-el';

// Components
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

// Icons
import { ReactComponent as Share } from '../../../images/share.svg';
import { ReactComponent as Facebook } from '../../../images/facebook.svg';
import { ReactComponent as Twitter } from '../../../images/twitter.svg';
import { ReactComponent as Linkedin } from '../../../images/linkedin.svg';
import { ReactComponent as Mail } from '../../../images/mail.svg';

/**
 * @todo document this component
 */
class SocialShare extends React.Component {
    state = {
        socialOpen: false
    };

    constructor(props) {
        super(props);

        this.state = {
            socialOpen: true
        };
        this.timer = null;
    }

    componentDidMount() {
        this.timer = setTimeout(() => this.setState({ socialOpen: false }), 5000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    triggerClick = e => {
        e.preventDefault();

        this.setState({
            socialOpen: !this.state.socialOpen
        });
    };

    render() {
        const { style } = this.props;

        return (
            <div className='scrollarea'>
                <Sticky mode='bottom' bottomOffset={200} positionRecheckInterval={25}>
                    <div
                        id='sb-social-share'
                        className={`sb-social-share${this.state.socialOpen ? ' sb-social-share--open' : ''}`}
                        aria-labelledby='sb-social-share-button'
                        role='region'
                        style={style || {}}
                    >
                        <div className='sb-social-share__wrapper'>
                            <button
                                id='sb-social-share-button'
                                className='sb-social-share__button'
                                aria-expanded='true'
                                onClick={e => {
                                    this.triggerClick(e);
                                }}
                            >
                                <span className='screen-reader-text'>{'Share This Journey'}</span>
                                <Share className='sb-icon sb-social-share__icon' />
                            </button>
                            <ul className='sb-social-share__list' aria-hidden='false'>
                                <li className='sb-social-share__item'>
                                    <FacebookShareButton
                                        className='sb-social-share__link  sb-social-share__link--facebook'
                                        url={this.props.postUrl}
                                    >
                                        <Facebook className='sb-icon sb-social-share__icon' />
                                        <span className='screen-reader-text'>{'Share on Facebook'}</span>
                                    </FacebookShareButton>
                                </li>
                                <li className='sb-social-share__item'>
                                    <TwitterShareButton
                                        className='sb-social-share__link  sb-social-share__link--twitter'
                                        url={this.props.postUrl}
                                        title={this.props.postTitle}
                                    >
                                        <Twitter className='sb-icon sb-social-share__icon' />
                                        <span className='screen-reader-text'>{'Share on Twitter'}</span>
                                    </TwitterShareButton>
                                </li>
                                <li className='sb-social-share__item'>
                                    <LinkedinShareButton
                                        className='sb-social-share__link  sb-social-share__link--linkedin'
                                        url={this.props.postUrl}
                                        title={this.props.postTitle}
                                    >
                                        <Linkedin className='sb-icon sb-social-share__icon' />
                                        <span className='screen-reader-text'>{'Share on LinkedIn'}</span>
                                    </LinkedinShareButton>
                                </li>
                                <li className='sb-social-share__item'>
                                    <a
                                        href={`mailto:?subject=${encodeURIComponent(
                                            this.props.postTitle
                                        )}&body=${encodeURIComponent(
                                            `I just traced my coffee: ${this.props.postTitle} ${this.props.postUrl}`
                                        )}`}
                                        className='sb-social-share__link  sb-social-share__link--mail'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <Mail className='sb-icon sb-social-share__icon' />
                                        <span className='screen-reader-text'>{'Share in email'}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Sticky>
            </div>
        );
    }
}

export { SocialShare };
