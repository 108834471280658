import './styles.css';

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@cxeweb/pattern-library/lib/components/icon';
import iconPaths from '@cxeweb/pattern-library/lib/icons/__index';

const Bar = ({ title, percentage, index, onClick, onSelectCountry, origin }) => {
    const colors = ['#BEB71E', '#CB2F24', '#6D0113', '#34313B', '#C89076', '#DF7B23', '#9D537B'];

    const pickedColor = colors[index];

    return (
        <div className='mt2 mb3' style={{ height: '20pt' }}>
            <div className='bc-row'>
                <div
                    className='bc-row bc-column bc-barContainer'
                    onClick={() => onSelectCountry({ selectedCountry: { name: origin, color: pickedColor } })}
                >
                    <div className='bc-column' style={{ backgroundColor: `${pickedColor}`, width: `${percentage}%` }} />
                    <div className='bc-column bc-right' style={{ width: `${100 - percentage}%` }} />
                </div>
                <div onClick={onClick} className='bc-column' style={{ width: '38%' }}>
                    <div className='bc-column bc-titleContainer pr2 text-right' style={{ fontSize: '1.6rem' }}>
                        {title}
                    </div>
                    <div className='bc-column bc-arrowContainer items-right'>
                        <Icon path={iconPaths.chevronRight} size='17px' className='color-textBlackSoft' />
                    </div>
                </div>
            </div>
        </div>
    );
};

Bar.defaultProps = {
    index: 0,
};

Bar.propTypes = {
    percentage: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};

export { Bar };
