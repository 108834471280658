import Images from '../images';

export const LATIN_AMERICA_COUNTRIES = [
    { code: 'BOL', name: 'country:BOL', image: Images.mapBolivia },
    { code: 'BRA', name: 'country:BRA', image: Images.mapBrazil },
    { code: 'COL', name: 'country:COL', image: Images.mapColombia },
    { code: 'CRI', name: 'country:CRI', image: Images.mapCostaRica },
    { code: 'ECU', name: 'country:ECU', image: Images.mapEecuador },
    { code: 'SLV', name: 'country:SLV', image: Images.mapElSalvador },
    { code: 'GTM', name: 'country:GTM', image: Images.mapGuatemala },
    { code: 'HND', name: 'country:HND', image: Images.mapHonduras },
    { code: 'JAM', name: 'country:JAM', image: Images.mapJamaica },
    { code: 'MEX', name: 'country:MEX', image: Images.mapMexico },
    { code: 'NIC', name: 'country:NIC', image: Images.mapNicaragua },
    { code: 'PAN', name: 'country:PAN', image: Images.mapPanama },
    { code: 'PER', name: 'country:PER', image: Images.mapPeru },
    { code: 'PRI', name: 'country:PRI', image: Images.mapPuertoRico },
];

export const AFRICA_COUNTRIES = [
    { code: 'BDI', name: 'country:BDI', image: Images.mapBurundi },
    { code: 'ETH', name: 'country:ETH', image: Images.mapEthiopia },
    { code: 'KEN', name: 'country:KEN', image: Images.mapKenya },
    { code: 'MWI', name: 'country:MWI', image: Images.mapMalawi },
    { code: 'RWA', name: 'country:RWA', image: Images.mapRwanda },
    { code: 'TZA', name: 'country:TZA', image: Images.mapTanzania },
    { code: 'UGA', name: 'country:UGA', image: Images.mapUganda },
    { code: 'ZMB', name: 'country:ZMB', image: Images.mapZambia },
    { code: 'COD', name: 'country:COD', image: Images.mapDemocraticRepublicCongo },
];

export const ASIA_PACIFIC_COUNTRIES = [
    { code: 'USA', name: 'hawaii', image: Images.mapHawaii },
    { code: 'PNG', name: 'country:PNG', image: Images.mapPapuaNewGuinea },
    { code: 'CHN', name: 'country:CHN', image: Images.mapChina },
    { code: 'IND', name: 'country:IND', image: Images.mapIndia },
    { code: 'TLS', name: 'country:TLS', image: Images.mapEastTimor },
    { code: 'IDN', name: 'country:IDN', image: Images.mapIndonesia },
    { code: 'IDNAGS', name: 'agedSumatra', image: Images.mapSumatraIndonesia },
    { code: 'IDNJAV', name: 'java', image: Images.mapJavaIndonesia },
    { code: 'IDNSUL', name: 'sulawesi', image: Images.mapSulawesiIndonesia },
    { code: 'IDNSUM', name: 'sumatra', image: Images.mapSumatraIndonesia },
    { code: 'VNM', name: 'country:VNM', image: Images.mapVietnam },
    { code: 'THA', name: 'country:THA', image: Images.mapThailand },
    // { code: 'LAO', name: "Lao People's Democratic Republic", image: Images.mapLaos },
];
