import './styles.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@cxeweb/pattern-library/lib/components/card';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import { EXPLORE_LIST } from '../../constants/exploreProducts';
import Images from '../../images';
import { Detail } from '../common/DetailPage';
import { getProductDetailPath } from '../../constants/paths';
import { Footer } from '../common/Footer';
import { TileCarousel } from '../common/TileCarousel';
import cdnUrls from '../../constants/cdnUrls';
import { useTranslation } from 'react-i18next';

const productList = EXPLORE_LIST.map(({ name, sku }) => ({
    name,
    sku,
    image: cdnUrls.getBagImage(sku),
}));

const ProductListView = () => {
    window.scrollTo(0, 0);
    let navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Detail title={t('product.pageHeader')} backTitle={' '}>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                <div style={{ maxWidth: '700px' }}>
                    <ImageLoader src={Images.productGeneralBanner} alt={`product general banner`} />
                </div>
                <div>
                    <p className='text-sm p3'>{t('product.description')}</p>
                </div>
                <div className='story-container mb5' style={{ textAlign: 'left' }}>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('product.featuredCoffees.title')}
                    </Heading>
                    <div>
                        <p className='text-sm p3'>{t('product.featuredCoffees.text')}</p>
                    </div>
                    <Card contentClasses='pl3 pr3 pt4 pb2 cd-card-content-center'>
                        <div className='product-list-container'>
                            {productList.map(({ name, image, sku }, idx) => (
                                <div className='flex flex-column items-center mb5 ml2 mr2' key={idx}>
                                    <div
                                        className='product-detail-carousel-image-container'
                                        onClick={() => navigate(getProductDetailPath(sku))}
                                    >
                                        <img src={image} style={{ width: '50%' }} alt={t(name)}></img>
                                    </div>
                                    <div className='mt1 text-center'>{t(name)}</div>
                                </div>
                            ))}
                        </div>
                    </Card>
                </div>
            </Card>
            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                <TileCarousel />
            </Card>
            <Footer />
        </Detail>
    );
};

export { ProductListView };
