import './styles.css';

import React from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Images from '../../images';
import { HAFooter } from './HAFooter';
import { getHAFarmRoastPath } from '../../constants/paths';
import { useTranslation } from 'react-i18next';
import cdnUrls from '../../constants/cdnUrls';
import { useNavigate } from 'react-router-dom';
import { HATileCarousel } from './HATileCarousel';
import { SocialShare } from '../common';
import Sticky from 'react-sticky-el';

const HAFarmOverviewView = () => {
    const { t } = useTranslation();
    const [playVideo, setPlayVideo] = React.useState(false);

    let navigate = useNavigate();
    return (
        <>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                {playVideo ? (
                    <video width='100%' height='auto' autoPlay controls>
                        <source src={cdnUrls.getHAFarmImage(`HAOverviewVideo.mp4`)} type='video/mp4' />
                        Your browser does not support the video tag or the file format of this video.
                    </video>
                ) : (
                    <div
                        className='video-frame'
                        style={{ backgroundImage: `url(${cdnUrls.getHAFarmImage('videoBanner.jpg')})` }}
                        onClick={() => setPlayVideo(true)}
                    ></div>
                )}
                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('HAFarm:overview.header')}
                </Heading>

                <div className='p3'>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ verticalAlign: 'top' }}>
                                    <img style={{ marginRight: '20px' }} src={Images.farmIcon} alt='pointer' />
                                </td>
                                <td style={{ paddingLeft: '5px' }}>{t('HAFarm:overview.text1')}</td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: 'top' }}>
                                    <img style={{ marginRight: '20px' }} src={Images.farmIcon} alt='pointer' />
                                </td>
                                <td style={{ paddingLeft: '5px' }}>
                                    {t('HAFarm:overview.text2_1')}
                                    <a
                                        class='color-greenAccent'
                                        href='https://stories.starbucks.com/people-in-coffee/2023/photo-essay-partners-visit-costa-rica-for-the-2023-origin-experience/'
                                        target='_blank'
                                    >
                                        {t('HAFarm:overview.text2_2')}
                                    </a>
                                    {t('HAFarm:overview.text2_3')}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: 'top' }}>
                                    <img style={{ marginRight: '20px' }} src={Images.farmIcon} alt='pointer' />
                                </td>
                                <td style={{ paddingLeft: '5px' }}>{t('HAFarm:overview.text3')}</td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: 'top' }}>
                                    <img style={{ marginRight: '20px' }} src={Images.farmIcon} alt='pointer' />
                                </td>
                                <td style={{ paddingLeft: '5px' }}>{t('HAFarm:overview.text4')}</td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: 'top' }}>
                                    <img style={{ marginRight: '20px' }} src={Images.farmIcon} alt='pointer' />
                                </td>
                                <td style={{ paddingLeft: '5px' }}>{t('HAFarm:overview.text5')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>

            <div className='pb3' style={{ background: '#edebe9' }}>
                <Heading className='pl3 pt3 pb3 heading-xs text-semibold' tagName='h3' size='md'>
                    {t('HAFarm:overview.visitOthers')}
                </Heading>
                <Card
                    imagePosition='left'
                    style={{ minHeight: '120px', maxHeight: '130px' }}
                    contentClasses='pl2 pr2 pt1 pb1 card-content-center'
                    containerClasses='mb2'
                    imageSize='sm'
                    imageUrl={cdnUrls.getHAFarmImage(`blonde/thumbnail.jpg`)}
                    imageFit='cover'
                    imageClasses='thumbnal-image-class'
                    onClick={() => navigate(getHAFarmRoastPath('blonde-roast'))}
                >
                    <div className='text-left text-semibold'> {t('HAFarm:coffeeNotes.blonde.coffeeName')}</div>
                    <div className='text-left'> {t('HAFarm:coffeeNotes.blonde.flavorNotes')}</div>
                </Card>
                <Card
                    imagePosition='left'
                    style={{ minHeight: '120px', maxHeight: '130px' }}
                    contentClasses='pl2 pr2 pt1 pb1 card-content-center'
                    containerClasses='mb2'
                    imageSize='sm'
                    imageUrl={cdnUrls.getHAFarmImage(`medium/thumbnail.jpg`)}
                    imageFit='cover'
                    imageClasses='thumbnal-image-class'
                    onClick={() => navigate(getHAFarmRoastPath('medium-roast'))}
                >
                    <p className='text-left text-semibold'> {t('HAFarm:coffeeNotes.medium.coffeeName')}</p>
                    <p className='text-left'> {t('HAFarm:coffeeNotes.medium.flavorNotes')}</p>
                </Card>
                <Card
                    imagePosition='left'
                    style={{ minHeight: '120px', maxHeight: '130px' }}
                    contentClasses='pl2 pr2 pt1 pb1 card-content-center'
                    containerClasses='mb2'
                    imageSize='sm'
                    imageUrl={cdnUrls.getHAFarmImage(`dark/thumbnail.jpg`)}
                    imageFit='cover'
                    imageClasses='thumbnal-image-class'
                    onClick={() => navigate(getHAFarmRoastPath('dark-roast'))}
                >
                    <p className='text-left text-semibold'> {t('HAFarm:coffeeNotes.dark.coffeeName')}</p>
                    <p className='text-left'> {t('HAFarm:coffeeNotes.dark.flavorNotes')}</p>
                </Card>
                <Card
                    imagePosition='left'
                    style={{ minHeight: '120px', maxHeight: '130px' }}
                    contentClasses='pl2 pr2 pt1 pb1 card-content-center'
                    containerClasses='mb2'
                    imageSize='sm'
                    imageUrl={cdnUrls.getHAFarmImage(`limited/thumbnail.jpg`)}
                    imageFit='cover'
                    imageClasses='thumbnal-image-class'
                    onClick={() => navigate(getHAFarmRoastPath('limited-release'))}
                >
                    <p className='text-left text-semibold'> {t('HAFarm:coffeeNotes.limited.coffeeName')}</p>
                    <p className='text-left'> {t('HAFarm:coffeeNotes.limited.flavorNotes')}</p>
                </Card>
            </div>
            <div className='hm-social-share'>
                <Sticky
                    className='story-stick'
                    mode='bottom'
                    stickyClassName='story-stick--sticky'
                    positionRecheckInterval={25}
                >
                    <SocialShare
                        postTitle={t('home.socialShare')}
                        postUrl={`https://traceability.starbucks.com/#/`}
                        image={Images.globalAcdemy}
                        style={{
                            paddingBottom: '60px',
                        }}
                    />
                </Sticky>
            </div>
            <Card disableBoxShadow style={{ background: '#ffffff' }}>
                <HATileCarousel />
            </Card>
            <HAFooter />
        </>
    );
};

export { HAFarmOverviewView };
