import './styles.css';

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layer from '@cxeweb/pattern-library/lib/components/layer';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Mask from '@cxeweb/pattern-library/lib/components/mask';
import { useNavigate } from 'react-router-dom';
import Images from '../../images';
import { ROASTING_PLANT_CODES } from '../../constants/utilFuctions';
import { toggleCameraScanningAction, resetScanIdleAction, enterLotIdManually } from '../../actions/scannerAction';
import { CAMERA_NOT_WORKING_ERROR_PATH } from '../../constants/paths';
import { useTranslation } from 'react-i18next';

const CameraOverlay = () => {
    let navigate = useNavigate();
    const [state, setState] = useState({
        isScanning: true,
        isOpen: false,
        isIdle: false,
        isEnterDisabled: true,
        typedLotId: '',
    });
    const myRef = useRef(null);

    const scanIdle = useSelector((state) => state.scanner.scanIdle);
    const dispatch = useDispatch();

    useEffect(() => {
        if (scanIdle) {
            setState((prevState) => ({ ...prevState, isIdle: scanIdle }));
        }
    }, [scanIdle]);

    const toggleCameraView = () => {
        setState((prevState) => ({ ...prevState, isScanning: !prevState.isScanning }));
        dispatch(toggleCameraScanningAction(!state.isScanning));
    };

    const enterManually = () => {
        if (state.isScanning) {
            setState({ ...state, isScanning: false });
            dispatch(toggleCameraScanningAction(false));
        }
    };

    const toInputUppercase = (e) => {
        e.target.value = ('' + e.target.value).toUpperCase();
    };

    const countUpperCaseChars = (str) => {
        let count = 0;
        for (let i = 0; i < str.length; i++) {
            if (/[A-Z]/.test(str.charAt(i))) count++;
        }
        return count;
    };

    const toggleOpen = () => setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
    const toggleIdle = () => {
        setState((prevState) => ({ ...prevState, isIdle: !prevState.isIdle }));
        if (!state.isIdle) {
            dispatch(resetScanIdleAction());
        }
    };

    const handleTextChange = (text) => {
        if (text.length === 9) {
            let lotIdIndex = -1;
            let lotId = '';
            let charCount = countUpperCaseChars(text);
            if (charCount > 3) {
                setState((prevState) => ({ ...prevState, isEnterDisabled: true }));
            } else {
                for (let i = 0; i < ROASTING_PLANT_CODES.length; i++) {
                    lotIdIndex = text.indexOf(ROASTING_PLANT_CODES[i]);
                    if (lotIdIndex !== -1) {
                        lotId = text.substring(lotIdIndex, lotIdIndex + 9);
                        break;
                    }
                }
                setState((prevState) => ({ ...prevState, isEnterDisabled: lotId === '', typedLotId: lotId }));
            }
        } else {
            setState((prevState) => ({ ...prevState, isEnterDisabled: true }));
        }
    };

    const enterItemIdManually = () => dispatch(enterLotIdManually(state.typedLotId));

    const { isScanning, isOpen, isIdle, isEnterDisabled } = state;
    const { t } = useTranslation();

    const renderPopup = (isOpen, target, onClick, header, btnText) => (
        <Layer isOpen={isOpen} targetElement={target} style={{ zIndex: '2' }} className='sc-layer'>
            <div className='height-100 flex flex-column justify-center items-center'>
                <Mask isActive onClick={onClick} />
                <div
                    className='relative bg-white p5 flex flex-column items-center justify-between'
                    style={{
                        width: '90%',
                        height: '150px',
                        maxWidth: '500px',
                        zIndex: 1,
                    }}
                >
                    <div>{header}</div>
                    <Button onClick={onClick}>{btnText}</Button>
                </div>
            </div>
        </Layer>
    );

    return (
        <div ref={myRef}>
            {renderPopup(isOpen, myRef.current, toggleOpen, t('scan.overlay.correctId'), t('scan.overlay.closeMe'))}
            {renderPopup(isIdle, myRef.current, toggleIdle, t('scan.overlay.stillThere'), t('scan.overlay.stillHere'))}
            <div className='sc-overlay-top'>
                <div className='sc-top-section'>
                    <div className='sc-artifact-layer'>
                        <img className='sc-image' src={Images.scanTop} alt='scan' />
                        {isScanning ? (
                            <div className='sc-desc-text'>{t('scan.overlay.scanDesc')}</div>
                        ) : (
                            <div className='sc-desc-text'>{t('scan.overlay.enterDesc')}</div>
                        )}
                    </div>
                </div>
                {isScanning ? (
                    <div className='sc-cameraIndicator expand' />
                ) : (
                    <div className='sc-enter-container pr4 pl4'>
                        <div className='sc-text-field-container'>
                            <label className='mb2'>{t('scan.overlay.batchCode')}</label>
                            <input
                                autoFocus
                                className='sc-text-field'
                                id='lot-id'
                                type='text'
                                autoComplete='off'
                                placeholder={t('scan.overlay.placeholder')}
                                style={{ width: '100%' }}
                                maxLength='9'
                                onChange={(event) => handleTextChange(event.target.value)}
                                onInput={(event) => toInputUppercase(event)}
                            />
                        </div>
                        <Button
                            className='sc-enter-btn mt3'
                            onClick={enterItemIdManually}
                            visualStyle='positive'
                            disabled={isEnterDisabled}
                        >
                            {t('scan.overlay.enter')}
                        </Button>
                    </div>
                )}
            </div>
            <div className='sc-overlay-bottom'>
                {isScanning ? (
                    <div className='sc-toogle-container'>
                        <div className='sc-desc-text'>{t('scan.overlay.enterCode')}</div>
                        <div onClick={toggleCameraView} className='sc-scan-toggle'>
                            {t('scan.overlay.typeCode')}
                        </div>
                        <div className='sc-toogle-container mt5'>
                            <div
                                onClick={() => {
                                    dispatch(toggleCameraScanningAction(false));
                                    navigate(CAMERA_NOT_WORKING_ERROR_PATH);
                                }}
                                className='sc-camera-not-working-btn'
                            >
                                {t('scan.overlay.noCamera')}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='sc-toogle-container'>
                        <div className='sc-desc-text'>{t('scan.overlay.toMuchTyping')}</div>
                        <div onClick={toggleCameraView} className='sc-scan-toggle'>
                            {t('scan.overlay.scanTheBag')}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CameraOverlay;
