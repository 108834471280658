const images = {
    scanTop: require('./scan_coffee_bag.png'),
    journeyBarBean: require('./journeybar_bean.png'),
    journeyBarCherry: require('./journeybar_cherry.png'),
    journeyBarCup: require('./journeybar_cup.png'),
    farmIcon: require('./farm_icon.png'),
    downArrow: require('./down_arrow.png'),
    upArrow: require('./up_arrow.png'),
    spillCup: require('./spill-cup.jpg'),
    mapJourney: require('./map-journey.jpg'),
    globalAcdemy: require('./starbucks_global_academy.png'),
    cupMask: require('./cup_mask.png'),
    bagMask: require('./bag_mask.png'),
    plantCoffeeTree: require('./plant-coffee-tree.jpg'),
    brewIconPourOver: require('./brew_icon_pourover.png'),
    brewIconColdBrew: require('./brew_icon_coldbrew.png'),
    brewIconCoffeeBrewer: require('./brew_icon_coffeebrewer.png'),
    brewIconCoffeePress: require('./brew_icon_coffeepress.png'),
    brewIconIcedCoffee: require('./brew_icon_icedcoffee.png'),
    brewIconEspresso: require('./brew_icon_espresso.png'),
    defaultPoster: require('./default_poster.jpg'),
    defaultBag: require('./default_bag.png'),
    decaf: require('./green_bean_bag.jpg'),
    helpFarmers: require('./help_farmers.png'),
    helpFarmers2: require('./help_farmers2.jpg'),
    batchCodeLocation: require('./batch_code_location.jpg'),
    iconGlobe: require('./icon-globe.png'),
    //explore overview and region
    exploreOverviewBrew: require('./explore_overview_brew.jpg'),
    exploreOverviewGrow: require('./explore_overview_grow.png'),
    exploreOverviewRoast: require('./explore_overview_roast.jpg'),
    growRegionAfricaThumbnail: require('./grow_region_africa_thumbnail.jpg'),
    growRegionAsiaPacificThumbnail: require('./grow_region_asia_pacific_thumbnail.jpg'),
    growRegionLatinAmericaThumbnail: require('./grow_region_latin_america_thumbnail.jpg'),
    growRegionLatinAmerica: require('./exploreRegions/grow_region_latin_america.jpg'),
    growRegionLatinAmericaProfile: require('./exploreRegions/grow_region_latin_america_profile.jpg'),
    growRegionAsiaPacific: require('./exploreRegions/grow_region_asia_pacific.jpg'),
    growRegionAsiaPacificProfile: require('./exploreRegions/grow_region_asia_pacific_profile.jpg'),
    growRegionAfrica: require('./exploreRegions/grow_region_africa.jpg'),
    growRegionAfricaProfile: require('./exploreRegions/grow_region_africa_profile.jpg'),
    exploreRegionCherries: require('./exploreRegions/grow_region_cherries.jpg'),

    //explore latin america region countries
    mapJamaica: require('./exploreLatinAmericaCountries/map-jamaica.png'),
    mapElSalvador: require('./exploreLatinAmericaCountries/map-el-salvador.png'),
    mapEecuador: require('./exploreLatinAmericaCountries/map-ecuador.png'),
    mapBolivia: require('./exploreLatinAmericaCountries/map-bolivia.png'),
    mapBrazil: require('./exploreLatinAmericaCountries/map-brazil.png'),
    mapColombia: require('./exploreLatinAmericaCountries/map-colombia.png'),
    mapCostaRica: require('./exploreLatinAmericaCountries/map-costa-rica.png'),
    mapGalapagos: require('./exploreLatinAmericaCountries/map-galapagos.png'),
    mapGuatemala: require('./exploreLatinAmericaCountries/map-guatemala.png'),
    mapHonduras: require('./exploreLatinAmericaCountries/map-honduras.png'),
    mapMexico: require('./exploreLatinAmericaCountries/map-mexico.png'),
    mapNicaragua: require('./exploreLatinAmericaCountries/map-nicaragua.png'),
    mapPanama: require('./exploreLatinAmericaCountries/map-panama.png'),
    mapPeru: require('./exploreLatinAmericaCountries/map-peru.png'),
    mapPuertoRico: require('./exploreLatinAmericaCountries/map-puerto-rico.png'),

    //explore africa region countries
    mapBurundi: require('./exploreAfricaCountries/map-burundi.png'),
    mapDemocraticRepublicCongo: require('./exploreAfricaCountries/map-democratic-republic-congo.png'),
    mapEthiopia: require('./exploreAfricaCountries/map-ethiopia.png'),
    mapRepublicCaboVerde: require('./exploreAfricaCountries/map-republic-cabo-verde.png'),
    mapKenya: require('./exploreAfricaCountries/map-kenya.png'),
    mapMalawi: require('./exploreAfricaCountries/map-malawi.png'),
    mapStHelena: require('./exploreAfricaCountries/map-st-helena.png'),
    mapTanzania: require('./exploreAfricaCountries/map-tanzania.png'),
    mapRwanda: require('./exploreAfricaCountries/map-rwanda.png'),
    mapUganda: require('./exploreAfricaCountries/map-uganda.png'),
    mapZambia: require('./exploreAfricaCountries/map-zambia.png'),

    //explore asia region countries
    mapPapuaNewGuinea: require('./exploreAsiaPacificCountries/map-papua-new-guinea.png'),
    mapVietnam: require('./exploreAsiaPacificCountries/map-vietnam.png'),
    mapThailand: require('./exploreAsiaPacificCountries/map-thailand.png'),
    mapLaos: require('./exploreAsiaPacificCountries/map-laos.png'),
    mapIndia: require('./exploreAsiaPacificCountries/map-india.png'),
    mapIndonesia: require('./exploreAsiaPacificCountries/map-indonesia.png'),
    mapSumatraIndonesia: require('./exploreAsiaPacificCountries/map-sumatra-indonesia.png'),
    mapSulawesiIndonesia: require('./exploreAsiaPacificCountries/map-sulawesi-indonesia.png'),
    mapJavaIndonesia: require('./exploreAsiaPacificCountries/map-java-indonesia.png'),
    mapChina: require('./exploreAsiaPacificCountries/map-china.png'),
    mapEastTimor: require('./exploreAsiaPacificCountries/map-east-timor.png'),
    mapHawaii: require('./exploreAsiaPacificCountries/map-hawaii.png'),

    //explore roasting
    exploreRoastingBanner: require('./explore_roasting-banner.jpg'),
    exploreASPThumbnail: require('./explore_roasting-plant-augusta-thumbnail.png'),
    exploreCRPThumbnail: require('./explore_roasting-plant-carson-valley-thumbnail.png'),
    exploreKRPThumbnail: require('./explore_roasting-plant-kent-thumbnail.png'),
    exploreSRPThumbnail: require('./explore_roasting-plant-sandy-run-thumbnail.png'),
    exploreYRPThumbnail: require('./explore_roasting-plant-york-thumbnail.png'),
    exploreR06Thumbnail: require('./explore_roasting-reserve-chicago-thumbnail.png'),
    exploreNYRPThumbnail: require('./explore_roasting-reserve-NYC-thumbnail.png'),
    exploreRRPThumbnail: require('./explore_roasting-reserve-seattle-thumbnail.png'),
    exploreRoastingPlantsQuote: require('./explore_roasting_plants_quote.jpg'),

    //explore products
    productGeneralBanner: require('./product_general_banner.jpg'),

    //CAFE Practices
    cafeContinualImprovement: require('./cafePractices/cafe_continual_improvement.jpg'),
    cafeEconomicTransparency: require('./cafePractices/cafe_economic_transparency.jpg'),
    cafeEnvironmentalLeadership: require('./cafePractices/cafe_environmental_leadership.jpg'),
    cafeFourCriteria: require('./cafePractices/cafe_four_criteria.jpg'),
    cafePracticesBanner: require('./cafePractices/cafe_practices_banner.jpg'),
    cafeQuality: require('./cafePractices/cafe_quality.jpg'),
    socialResponsibility: require('./cafePractices/social_responsibility.jpg'),
    cafePracticesVideoBanner: require('./cafePractices/cafe_practices_video_banner.jpg'),

    //Updated images
    covidSupportThumbnail: require('./covid-support-thumbnail.jpg'),

    // Footer tile carousel images
    traceTile: require('./tileCarousel/trace-coffee.jpg'),
    seeJourneyTile: require('./tileCarousel/see-journey.jpg'),
    exploreTile: require('./tileCarousel/explore-coffee-production.jpg'),
    coffeeAcademyTile: require('./tileCarousel/coffee-academy.jpg'),
    traceabilityTile: require('./tileCarousel/traceability.jpg'),

    //Country banner images
    BDIBanner: require('./countryBanner/BDI.jpg'),
    BOLBanner: require('./countryBanner/BOL.jpg'),
    BRABanner: require('./countryBanner/BRA.jpg'),
    CHNBanner: require('./countryBanner/CHN.jpg'),
    CODBanner: require('./countryBanner/COD.jpg'),
    COLBanner: require('./countryBanner/COL.jpg'),
    CPVBanner: require('./countryBanner/CPV.jpg'),
    CRIBanner: require('./countryBanner/CRI.jpg'),
    DOMBanner: require('./countryBanner/DOM.jpg'),
    ECUBanner: require('./countryBanner/ECU.jpg'),
    ETHBanner: require('./countryBanner/ETH.jpg'),
    GPSBanner: require('./countryBanner/GPS.jpg'),
    GTMBanner: require('./countryBanner/GTM.jpg'),
    HNDBanner: require('./countryBanner/HND.jpg'),
    HTIBanner: require('./countryBanner/HTI.jpg'),
    IDNBanner: require('./countryBanner/IDN.jpg'),
    IDNAGSBanner: require('./countryBanner/IDNAGS.jpg'),
    IDNBALBanner: require('./countryBanner/IDNBAL.jpg'),
    IDNJAVBanner: require('./countryBanner/IDNJAV.jpg'),
    IDNSULBanner: require('./countryBanner/IDNSUL.jpg'),
    IDNSUMBanner: require('./countryBanner/IDNSUM.jpg'),
    INDBanner: require('./countryBanner/IND.jpg'),
    JAMBanner: require('./countryBanner/JAM.jpg'),
    KENBanner: require('./countryBanner/KEN.jpg'),
    LAOBanner: require('./countryBanner/LAO.jpg'),
    MEXBanner: require('./countryBanner/MEX.jpg'),
    MWIBanner: require('./countryBanner/MWI.jpg'),
    NICBanner: require('./countryBanner/NIC.jpg'),
    PANBanner: require('./countryBanner/PAN.jpg'),
    PERBanner: require('./countryBanner/PER.jpg'),
    PNGBanner: require('./countryBanner/PNG.jpg'),
    PRIBanner: require('./countryBanner/PRI.jpg'),
    RWABanner: require('./countryBanner/RWA.jpg'),
    SHNBanner: require('./countryBanner/SHN.jpg'),
    SLVBanner: require('./countryBanner/SLV.jpg'),
    THABanner: require('./countryBanner/THA.jpg'),
    TLSBanner: require('./countryBanner/TLS.jpg'),
    TZABanner: require('./countryBanner/TZA.jpg'),
    UGABanner: require('./countryBanner/UGA.jpg'),
    USABanner: require('./countryBanner/USA.jpg'),
    VNMBanner: require('./countryBanner/VNM.jpg'),
    YEMBanner: require('./countryBanner/YEM.jpg'),
    ZMBBanner: require('./countryBanner/ZMB.jpg'),
    keyElevation: require('./countryBanner/key-elevation.png'),
    keyFSC: require('./countryBanner/key-FSC.png'),
    keyGrowArea: require('./countryBanner/key-grow-area.png'),
    ethicalSourcingVideo: require('./EthicalSourcing.mp4'),

    //roasting plant map images
    ASP: require('./roastingPlantMap/ASP.jpg'),
    CRP: require('./roastingPlantMap/CRP.jpg'),
    GRP: require('./roastingPlantMap/GRP.jpg'),
    KRP: require('./roastingPlantMap/KRP.jpg'),
    NRP: require('./roastingPlantMap/NRP.jpg'),
    R06: require('./roastingPlantMap/R06.jpg'),    
    R04: require('./roastingPlantMap/R04.jpg'),
    RRP: require('./roastingPlantMap/RRP.jpg'),
    SRP: require('./roastingPlantMap/SRP.jpg'),
    YRP: require('./roastingPlantMap/YRP.jpg'),
};

export default images;
