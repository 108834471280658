import { SET_LANGUAGE } from '../actions/types';

const INITIAL_STATE = {
    language: 'en',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LANGUAGE: {
            return {
                language: action.lng,
            };
        }
        default:
            return state;
    }
};
