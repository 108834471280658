import {
    LOT_ID_RECOGNITION_SUCCESS,
    TEXT_FROM_IMAGE_FAIL,
    GET_TEXT_FROM_IMAGE,
    TEXT_FROM_IMAGE_RESPONSE_SUCCESS,
    TOGGLE_CAMERA_SCANNING,
    UPDATE_SCAN_COUNTER,
    TEXT_FROM_IMAGE_TIMEOUT,
    SCAN_IDLE,
    UPDATE_EMPTY_RESULT_COUNTER,
    RESET_SCAN_IDLE,
} from '../actions/types';
import { PURGE } from 'redux-persist/es/constants';

const INITIAL_STATE = {
    prodLotId: '',
    isRemoveCamera: false,
    scanDate: '',
    isFetchingText: false,
    isScanning: true,
    scanIdle: false,
    scanRetryCounter: 0,
    emptyResultCounter: 0,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_TEXT_FROM_IMAGE: {
            return { ...state, isFetchingText: true };
        }
        case TEXT_FROM_IMAGE_RESPONSE_SUCCESS: {
            return { ...state, isFetchingText: false };
        }
        case TEXT_FROM_IMAGE_TIMEOUT: {
            return { ...state, isFetchingText: false };
        }
        case LOT_ID_RECOGNITION_SUCCESS: {
            return {
                ...state,
                prodLotId: action.lotId,
                isRemoveCamera: action.isRemoveCamera,
                scanDate: action.scanDate,
                isFetchingText: false,
            };
        }
        case TEXT_FROM_IMAGE_FAIL: {
            return {
                ...state,
                isRemoveCamera: action.isRemoveCamera,
                scanError: true,
                isFetchingText: false,
            };
        }
        case TOGGLE_CAMERA_SCANNING: {
            return {
                ...state,
                isFetchingText: false,
                isScanning: action.isScanning,
                scanIdle: false,
                scanRetryCounter: 0,
                emptyResultCounter: 0,
            };
        }
        case UPDATE_SCAN_COUNTER: {
            return {
                ...state,
                scanRetryCounter: action.scanRetryCount,
            };
        }
        case UPDATE_EMPTY_RESULT_COUNTER: {
            return {
                ...state,
                emptyResultCounter: action.emptyResultCounter,
            };
        }
        case SCAN_IDLE: {
            return {
                ...state,
                scanIdle: true,
            };
        }
        case RESET_SCAN_IDLE: {
            return {
                ...state,
                scanIdle: false,
                isScanning: true,
                scanRetryCounter: 0,
                emptyResultCounter: 0,
                isFetchingText: false,
            };
        }
        //To be removed
        case 'TESTING_GET_TEXT': {
            return {
                ...state,
                prodLotId: 'SRP198349',
                isFetchingText: false,
                ///prodLotId: 'SRP19899349' //wrong id
            };
        }
        case PURGE: {
            return {
                prodLotId: '',
                scanDate: '',
                isRemoveCamera: false,
                isFetchingText: false,
                isScanning: true,
                scanError: false,
                scanIdle: false,
                scanRetryCounter: 0,
                emptyResultCounter: 0,
            };
        }

        default: {
            return state;
        }
    }
};
