import Images from '../images';

export default {
    'Coffee Press': {
        description: 'brewingMethod.coffeePress',
        icon: Images.brewIconCoffeePress,
        url: 'https://athome.starbucks.com/brewing-guide/how-brew-coffee-press',
    },
    'Pour Over': {
        description: 'brewingMethod.pourOver',
        icon: Images.brewIconPourOver,
        url: 'https://athome.starbucks.com/brewing-guide/how-make-pour-over-coffee',
    },
    'Coffee Brewer': {
        description: 'brewingMethod.coffeeBrewer',
        icon: Images.brewIconCoffeeBrewer,
        url: 'https://athome.starbucks.com/brewing-guide/how-brew-drip-brewer',
    },
    'Iced Coffee': {
        description: 'brewingMethod.icedCoffee',
        icon: Images.brewIconIcedCoffee,
        url: 'https://athome.starbucks.com/brewing-guide/how-make-iced-pour-over-coffee',
    },
    'Cold Brew': {
        description: 'brewingMethod.coldBrew',
        icon: Images.brewIconColdBrew,
        url: 'https://athome.starbucks.com/brewing-guide/how-brew-cold-brew-coffee-press',
    },
    Espresso: {
        description: 'brewingMethod.espresso',
        icon: Images.brewIconEspresso,
        url: 'https://stories.starbucks.com/stories/2016/espresso-vs-brewed-coffee/',
    },
    'Cafetière à piston': {
        description: 'brewingMethod.coffeePress',
        icon: Images.brewIconCoffeePress,
        url: 'https://fr.starbucks.ca/coffee/how-to-brew/coffee-press',
    },
    'Café filtre manuel': {
        description: 'brewingMethod.pourOver',
        icon: Images.brewIconPourOver,
        url: 'https://fr.starbucks.ca/coffee/how-to-brew/pour-over',
    },
    'Café filtre': {
        description: 'brewingMethod.coffeeBrewer',
        icon: Images.brewIconCoffeeBrewer,
        url: 'https://fr.starbucks.ca/coffee/how-to-brew/coffee-brewer',
    },
    'Café glacé': {
        description: 'brewingMethod.icedCoffee',
        icon: Images.brewIconIcedCoffee,
        url: 'https://fr.starbucks.ca/coffee/how-to-brew/iced-pour-over',
    },
    'Café infusé à froid': {
        description: 'brewingMethod.coldBrew',
        icon: Images.brewIconColdBrew,
    },
};
