import './styles.css';

import React from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';
import ImageLoader from '@cxeweb/pattern-library/lib/components/image-loader';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Images from '../../images';
import { Footer } from '../common/Footer';
import { useTranslation } from 'react-i18next';
import { TileCarousel } from '../common/TileCarousel';

const CAFEPracticesView = () => {
    const { t } = useTranslation();
    const [playVideo, setPlayVideo] = React.useState(false);

    return (
        <>
            <Card imagePosition='top' disableBoxShadow style={{ background: '#f7f7f7' }}>
                {playVideo ? (
                    <video width='100%' height='auto' autoPlay controls>
                        <source src={Images.ethicalSourcingVideo} type='video/mp4' />
                        Your browser does not support the video tag or the file format of this video.
                    </video>
                ) : (
                    <div
                        className='video-frame'
                        style={{ backgroundImage: `url(${Images.cafePracticesVideoBanner})` }}
                        onClick={() => setPlayVideo(true)}
                    ></div>
                )}
                <div>
                    <p className='text-sm p3 mb3'>{t('cafePractices.description')}</p>
                </div>
                <div>
                    <p className='heading-md text-semibold p3 mb3'>{t('cafePractices.fourComponents')}</p>
                </div>
                <div>
                    <div>
                        <ImageLoader src={Images.cafeQuality} alt='roasting' />
                    </div>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('cafePractices.quality.header')}
                    </Heading>
                    <div>
                        <p className='text-sm p3 mb3'>{t('cafePractices.quality.text')}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <ImageLoader src={Images.cafeEconomicTransparency} alt='roasting' />
                    </div>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('cafePractices.economic.header')}
                    </Heading>
                    <div>
                        <p className='text-sm p3 mb3'>{t('cafePractices.economic.text')}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <ImageLoader src={Images.socialResponsibility} alt='roasting' />
                    </div>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('cafePractices.social.header')}
                    </Heading>
                    <div>
                        <p className='text-sm p3 mb3'>{t('cafePractices.social.text')}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <ImageLoader src={Images.cafeEnvironmentalLeadership} alt='roasting' />
                    </div>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('cafePractices.env.header')}
                    </Heading>
                    <div>
                        <p className='text-sm p3 mb3'>{t('cafePractices.env.text')}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <ImageLoader src={Images.cafeContinualImprovement} alt='roasting' />
                    </div>
                    <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                        {t('cafePractices.CI.header')}
                    </Heading>
                    <div>
                        <p className='text-sm p3'>{t('cafePractices.CI.text')}</p>
                    </div>
                </div>
            </Card>
            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                <TileCarousel />
            </Card>
            <Footer />
        </>
    );
};

export { CAFEPracticesView };
