import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@cxeweb/pattern-library/lib/components/card';

import * as products from '../../constants/exploreProducts';
import { Detail } from '../common/DetailPage';
import { getProductDetailPath } from '../../constants/paths';
import cdnUrls from '../../constants/cdnUrls';
import { Footer } from '../common/Footer';
import { useTranslation } from 'react-i18next';
import { TileCarousel } from '../common/TileCarousel';

import './styles.css';

const getProductList = (name) => {
    if (!name) return [];
    switch (name) {
        case 'core':
            return products.CORE_PRODUCTS_LIST.map(({ name, sku }) => ({
                name,
                sku,
                image: cdnUrls.getBagImage(sku),
            }));
        case 'limited':
            return products.RETURNING_FAV_LIST.map(({ name, sku }) => ({
                name,
                sku,
                image: cdnUrls.getBagImage(sku),
            }));
        case 'reserve':
            return products.RESERVE_PRODUCTS_LIST.map(({ name, sku }) => ({
                name,
                sku,
                image: cdnUrls.getBagImage(sku),
            }));
        default:
            return;
    }
};

const getProductName = (name) => {
    switch (name) {
        case 'core':
            return 'product.coreCoffees.title';
        case 'limited':
            return 'product.limitedCoffees.title';
        case 'reserve':
            return 'product.reserveCoffees.title';
        default:
            return '';
    }
};

const ProductList = () => {
    window.scrollTo(0, 0);
    let { coffeeType } = useParams();
    let navigate = useNavigate();
    const { t } = useTranslation();
    const products = getProductList(coffeeType);
    const title = getProductName(coffeeType);

    return (
        <Detail title={t(title)} backTitle={' '}>
            <Card contentClasses='pl3 pr3 pt4 pb2 cd-card-content-center'>
                <div className='product-list-container'>
                    {products.map(({ name, image, sku }, idx) => (
                        <div className='flex flex-column items-center mb5 ml2 mr2' key={idx}>
                            <div
                                className='product-detail-carousel-image-container'
                                onClick={() => navigate(getProductDetailPath(sku))}
                            >
                                <img src={image} style={{ width: '50%' }} alt={t(name)}></img>
                            </div>
                            <div className='mt1 text-center'>{t(name)}</div>
                        </div>
                    ))}
                </div>
            </Card>
            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                <TileCarousel />
            </Card>
            <Footer />
        </Detail>
    );
};

export { ProductList };
