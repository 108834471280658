import './style.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@cxeweb/pattern-library/lib/components/button';
import { ai } from '../../../telemetryService';
import AICustomEvents from '../../../constants/appInsightEvents';
import { WELCOME_PATH, CAM_SCANNER_PATH } from '../../../constants/paths';
import { useTranslation } from 'react-i18next';

export const CameraErrorPage = () => {
    ai.trackEvent({ name: AICustomEvents.cameraNotWorkingErrorPage });
    let navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className='camera-error-page-container'>
            <div className='camera-error-page-text-container'>
                <div className='camera-error-page-header-text'>{t('scan.cameraError.help')}</div>
                <div className='camera-error-page-text-title'>{t('scan.cameraError.title')}</div>
                <ul className='camera-error-text-list'>
                    {[
                        t('scan.cameraError.one'),
                        t('scan.cameraError.two'),
                        t('scan.cameraError.three'),
                        t('scan.cameraError.four'),
                    ].map((s, idx) => (
                        <li key={idx}>{s}</li>
                    ))}
                </ul>
            </div>
            <div className='camera-error-trace-again-button-container'>
                <Button
                    visualStyle='positive'
                    color='white'
                    className='button-shadow'
                    onClick={() => navigate(WELCOME_PATH)}
                >
                    {t('traceabilityHome')}
                </Button>
                <Button visualStyle='positive' className='button-shadow' onClick={() => navigate(CAM_SCANNER_PATH)}>
                    {t('tryAgain')}
                </Button>
            </div>
        </div>
    );
};
