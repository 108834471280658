import { PURGE } from 'redux-persist/es/constants';
import {
    COUNTRY_DETAILS_FAIL,
    COUNTRY_DETAILS_PURGE,
    COUNTRY_DETAILS_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    name: '',
    quickFacts: [],
    code: ''
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case COUNTRY_DETAILS_SUCCESS: {
            return {
                ...state,
                name: action.name,
                quickFacts: action.quickFacts,
                code: action.code
            };
        }
        case COUNTRY_DETAILS_FAIL:
        case COUNTRY_DETAILS_PURGE:
        case PURGE: {
            return {
                ...state,
                name: '',
                quickFacts: [],
                code: ''
            };
        }
        default:
            return state;
    }
}
