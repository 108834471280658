import './styles.css';

import React, { Component } from 'react';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import PropTypes from 'prop-types';
import Images from '../../../images';
import Text from '../Text';
import { QUERY_FARMER, QUERY_PARTNER, QUERY_ROASTER } from '../../../constants/queryStrings';
import { withTranslation } from 'react-i18next';
import { HtmlContent } from '../HtmlContent';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullContent: false,
        };
    }

    toggleContent = () =>
        this.setState((prevState) => ({
            isFullContent: !prevState.isFullContent,
        }));

    renderStory = () => {
        if (this.state.isFullContent) {
            return (
                <>
                    <Text style={{ textAlign: 'left' }}>
                        <HtmlContent htmlString={this.props.story} />
                    </Text>
                    <div className='expand-btn' onClick={this.toggleContent}>
                        <img src={Images.upArrow} alt={'expand less button'} />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Text style={{ textAlign: 'left' }} className='block-with-text'>
                        <HtmlContent htmlString={this.props.story} />
                    </Text>
                    <div className='expand-btn' onClick={this.toggleContent}>
                        <img src={Images.downArrow} alt={'expand button'} />
                    </div>
                </>
            );
        }
    };

    render() {
        const { picture, story, preferredName = '', t } = this.props;

        return (
            <div className='story-container mb5 mt4' style={{ textAlign: 'center' }}>
                <Card
                    style={{ marginTop: '-10px', position: 'relative' }}
                    contentClasses='pl3 pr3 pt3 pb2 profile-card'
                    containerClasses={'ml2 mr2'}
                >
                    <div className='profile-image-container pl2 pr2'>
                        <img className='profile-image' src={picture} alt={'a pic of a person'} />
                    </div>
                    <Heading tagName='h4' className='mb3 pt3 heading-xs text-semibold text-left'>
                        {t('meet', { name: preferredName })}
                    </Heading>
                    {this.renderStory(story)}
                </Card>
            </div>
        );
    }
}

Profile.propTypes = {
    type: PropTypes.oneOf([QUERY_PARTNER, QUERY_ROASTER, QUERY_FARMER]).isRequired,
    picture: PropTypes.string.isRequired,
    story: PropTypes.string.isRequired,
};

export default withTranslation()(Profile);
