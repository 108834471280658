export const WELCOME_PATH = '/';
export const CAM_SCANNER_PATH = '/scan';
export const HOME_PATH = '/home/:lotId';
export const COUNTRY_DETAIL_PATH = '/country/:countryCode/:sku?';
export const PRODUCT_DETAIL_PATH = '/roasts/:id';
export const RP_DETAIL_PATH = '/roastingPlant/:orgCode';
export const ERROR_PAGE_PATH = '/error';
export const CAMERA_NOT_WORKING_ERROR_PATH = '/cameraError';
export const COUNTY_LIST_PATH = '/regions/:region/countries';
export const EXPLORE_OVERVIEW_PATH = '/explore';
export const EXPLORE_REGIONS_PATH = '/explore/regions/:region';
export const EXPLORE_LATIN_AMERICA_PATH = '/explore/regions/latinamerica';
export const EXPLORE_AFRICA_PATH = '/explore/regions/africa';
export const EXPLORE_ASIAPACIFIC_PATH = '/explore/regions/asiapacific';
export const EXPLORE_ROASTING_PATH = '/explore/roastingPlants';
export const EXPLORE_PRODUCTS_PATH = '/roasts';
export const EXPLORE_PRODUCTS_WITH_COFFEE_TYPE_PATH = '/explore/:coffeeType/roasts';
export const CAFE_PRACTICES_PATH = '/CAFEPractices';
export const HA_FARM_ROAST_PATH = '/hacienda-alsacia/:roastType';
// export const HA_FARM_BLONDE_ROAST_PATH = '/hacienda-alsacia/blonde-roast';
// export const HA_FARM_MEDIUM_ROAST_PATH = '/hacienda-alsacia/medium-roast';
// export const HA_FARM_DARK_ROAST_PATH = '/hacienda-alsacia/dark-roast';
// export const HA_FARM_LIMITED_RELEASE_PATH = '/hacienda-alsacia/limited-release';
export const HA_FARM_OVERVIEW_PATH = '/hacienda-alsacia/overview';

export const getRoastingPlantDetailPath = (id) => RP_DETAIL_PATH.replace(':orgCode', id);
export const getProductDetailPath = (id) => PRODUCT_DETAIL_PATH.replace(':id', id);
export const getCountryDetailPath = (id, sku = '') =>
    COUNTRY_DETAIL_PATH.replace(':countryCode', id).replace(':sku?', sku);
export const getCountryListPath = (id) => COUNTY_LIST_PATH.replace(':region', id);
export const getProductsWithCoffeeTypePath = (id) => EXPLORE_PRODUCTS_WITH_COFFEE_TYPE_PATH.replace(':coffeeType', id);
export const getHomePath = (id) => HOME_PATH.replace(':lotId', id);
export const getHAFarmRoastPath = (t) => HA_FARM_ROAST_PATH.replace(':roastType', t);
