import './styles.css';

import React from 'react';
import Images from '../../../images';
import Text from '../Text';
import { convertUnicode } from '../../../constants/utilFuctions';
import { HtmlContent } from '../HtmlContent';

const QuickFact = ({ text }) => {
    return (
        <tr>
            <td style={{ verticalAlign: 'top' }}>
                <img style={{ marginRight: '20px' }} src={Images.farmIcon} alt='pointer' />
            </td>
            <td style={{ paddingLeft: '5px' }}>
                <HtmlContent htmlString={convertUnicode(text)} />
            </td>
        </tr>
    );
};

export default QuickFact;
