import {
    GET_ALL_SECRET_KEY,
    GET_SECRET_KEY,
    SECRET_KEY_FAIL,
    SECRET_KEY_SUCCESS
} from '../actions/types';
import { GOOGLE_MAP_KEY, GOOGLE_VISION_KEY } from '../constants/keys';
import { PURGE } from 'redux-persist/es/constants';

const INITIAL_STATE =
    {
        [GOOGLE_MAP_KEY]: '',
        [GOOGLE_VISION_KEY]: '',
        isFetching: false
    };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_SECRET_KEY: {
            return {
                ...state,
                isFetching: true
            };
        }
        case GET_SECRET_KEY: {
            return {
                ...state,
                isFetching: true
            };
        }
        case SECRET_KEY_FAIL: {
            return {
                ...state,
                isFetching: false
            };
        }
        case SECRET_KEY_SUCCESS + GOOGLE_MAP_KEY:
        case SECRET_KEY_SUCCESS + GOOGLE_VISION_KEY: {
            return {
                ...state,
                [action.name]: action.value,
                isFetching: false
            };
        }
        case PURGE : {
            return {
                ...state,
                isFetching: false,
            };
        }
        default : {
            return state;
        }

    }

}