import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CountryDetailView } from './CountryDetailView.js';
import Countries from '../../constants/countryCodes';
import { getCountryDetails } from '../../actions/countryDetailAction';
import { getStoriesAction } from '../../actions/storiesAction';
import { getProfileDataAction } from '../../actions';
import { QUERY_FARMER } from '../../constants/queryStrings';
import { ai } from '../../telemetryService';
import AICustomEvents from '../../constants/appInsightEvents';

const CountryDetail = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { countryCode, sku } = useParams();
    const { loadingIndicator, countryData, posts, profile, filterOnProfile, language } = useSelector((state) => {
        const filter = state.roastInfo.coffeeName === "Siren's Blend";
        return {
            loadingIndicator: state.loadingIndicator.showActivityIndicator,
            countryData: state.countryInfo,
            posts: state.stories.posts,
            profile: state.profile,
            filterOnProfile: filter,
            language: state.translation.language,
        };
    });

    useEffect(() => {
        ai.trackEvent({ name: AICustomEvents.countryDetailPage });
        window.scrollTo(0, 0);

        const loadData = () => {
            const countryName = !!countryCode ? Countries[countryCode] : '';

            const profileCode = !!sku ? `${sku}_${countryCode}` : countryCode;
            dispatch(getProfileDataAction(QUERY_FARMER, profileCode, filterOnProfile));

            dispatch(getCountryDetails(countryCode));

            let origin = countryName;
            if (countryName.includes('(')) {
                origin = countryName.match(/\(([^)]+)\)/)[1];
            }
            dispatch(getStoriesAction(origin));
        };

        loadData();
    }, [countryCode, sku, language, filterOnProfile, dispatch]);

    const backAction = () => {
        navigate(-1);
    };

    const countryName = !!countryCode ? Countries[countryCode] : '';

    return (
        !!countryName && (
            <CountryDetailView
                countryCode={countryCode}
                goBack={backAction}
                countryName={countryName}
                countryData={countryData}
                posts={posts}
                profile={profile}
                loadingIndicator={loadingIndicator}
                isEnglish={language.includes('en')}
            />
        )
    );
};

export { CountryDetail };
