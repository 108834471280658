import { PURGE } from 'redux-persist';
import { GET_TEXT_FROM_IMAGE } from './types';
import { createWebserviceRequestParams } from '../webservices';

export function getTextFromImageAction (base64, key) {
    const requestData = {};
    requestData.type = GET_TEXT_FROM_IMAGE;
    requestData.base64 = base64;
    requestData.key = key;
    const requestParams = createWebserviceRequestParams(requestData);
    return {
        type: GET_TEXT_FROM_IMAGE,
        requestParams
    };
}

export function purgeStoredDataAction (){
    return{
        type: PURGE,
          // Whatever you chose for the "key" value when initialising redux-persist in the **persistCombineReducers** method - e.g. "root"
        result: () => null

    }
}
