import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ExploreOverviewPageView from './ExploreOverviewPageView';
import { Detail } from '../common/DetailPage';

class ExploreOverviewContainer extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <Detail title={this.props.t('explore.pageHeader')} backTitle={' '}>
                <ExploreOverviewPageView />
            </Detail>
        );
    }
}

const ExploreOverview = withTranslation()(ExploreOverviewContainer);

export { ExploreOverview };
