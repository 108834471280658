const isProdEnv = process.env.NODE_ENV === 'production';

export const logger = {
    log: (...arg) => {
        if (!isProdEnv) {
            console.log(...arg);
        }
    },
    debug: (...arg) => {
        if (!isProdEnv) {
            console.debug(...arg);
        }
    },
    error: (...arg) => {
        if (!isProdEnv) {
            console.error(...arg);
        }
    },
    info: (...arg) => {
        if (!isProdEnv) {
            console.info(...arg);
        }
    }
};
