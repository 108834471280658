import './styles.css';

import React from 'react';
import PropTypes from 'prop-types';

const Toast = ({title, message, show}) => {

    return (
        <div style={{backgroundColor:'rgb(241, 238, 231)', border:'1px solid #ffffff'}}>
            {show &&
            <div id='hideMe'
                 className="ts_messageContainer">
                <p className='mt4 mb1 mr5 ml5 ts_title'>{title}</p>
                <p className="mt2 mb4 mr5 ml5 ts_message">{message}</p>
            </div>
            }
        </div>
    );
};

Toast.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    show: PropTypes.bool.isRequired
};

Toast.defaultProps = {
    show: true
};

export { Toast };