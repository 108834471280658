import './styles.css';

import React from 'react';
import Images from '../../../images';
import Icon from '@cxeweb/pattern-library/lib/components/icon';
import iconPaths from '@cxeweb/pattern-library/lib/icons/__index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCountryDetailPath } from '../../../constants/paths';
import * as countryList from '../../../constants/exploreRegionCountryList';

const colors = ['#0e7949', '#55af60', '#09a356', '#63bf96', '#3fa276', '#85bc81', '#c7f2e1'];
const countries = [
    ...countryList.AFRICA_COUNTRIES,
    ...countryList.ASIA_PACIFIC_COUNTRIES,
    ...countryList.LATIN_AMERICA_COUNTRIES,
];
const getImageByCounryCode = (code) => {
    var find = countries.find((country) => country.code === code);
    return find;
};

const CupBagGraph = ({ dataList, onClick }) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const length = dataList.length;
    return (
        <>
            <div className={'cbg-container pl3'}>
                <div className={'cbg-percentage-container'}>
                    <div>100</div>
                    <div>0</div>
                </div>
                <div className={'cbg-barContainer'}>
                    <div className={'cbg-style-for-cup'}>
                        {dataList.map((data, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => onClick(data.countryCode)}
                                    style={{
                                        backgroundColor: colors[length - 1 - index],
                                        width: '100%',
                                        height: `${data.percent}%`,
                                    }}
                                />
                            );
                        }, {})}
                    </div>
                    <img
                        style={{
                            position: 'relative',
                            zIndex: '100',
                            width: '80%',
                            height: '280px',
                            pointerEvents: 'none',
                        }}
                        src={Images.cupMask}
                        alt='coffee bag'
                    />
                </div>
                <div className={'cbg-dataContainer'}>
                    {dataList.map((data, index) => {
                        const { image, name } = getImageByCounryCode(data.countryCode);
                        return (
                            <div
                                className={'cbg-singleData'}
                                onClick={() => navigate(getCountryDetailPath(data.countryCode, data.sku))}
                                key={index}
                            >
                                <div className='cbg-pointer' style={{ backgroundColor: colors[length - 1 - index] }} />
                                <img src={image} style={{ width: '45px', marginLeft: '10px' }} alt={t(name)}></img>
                                <div style={{ flex: 3, paddingLeft: '10px' }}>
                                    <div className='cbg-title'>{t(`country:${data.countryCode}`)}</div>
                                    {data.countryCode.includes('AGS') && (
                                        <div className={'text-xs'} style={{ color: 'rgba(0, 0, 0, .56)' }}>
                                            {t('home.agedInSingapore')}
                                        </div>
                                    )}
                                </div>
                                <div className='text-right' style={{ width: '5%' }}>
                                    <Icon
                                        path={iconPaths.chevronRight}
                                        size='16px'
                                        className='color-textBlackSoft text-right'
                                    />
                                </div>
                            </div>
                        );
                    }, {})}
                </div>
            </div>
            <div className='text-xxxs pl3 pr3 mt3' style={{ color: 'rgba(0, 0, 0, .56)' }}>
                {t('home.smallAmountsNote')}
            </div>
        </>
    );
};

export { CupBagGraph };
