export default {
    welcomePage: 'Welcome Page',
    scanPage: 'Scan Page',
    homePage: 'Home Page',
    countryDetailPage: 'Origin Detail Page',
    roastingPlantDetailPage: 'Roasting Plant Detail Page',
    productDetailPage: 'Product Detail Page',
    scanErrorPage: 'Scan Error Page',
    cameraNotWorkingErrorPage: 'Camera Not Working Error Page'
}
