import { call, put, takeLatest, select } from 'redux-saga/effects';
import { COUNTRY_DETAILS_FAIL, COUNTRY_DETAILS_SUCCESS, GET_COUNTRY_DETAILS } from '../actions/types';
import Webservice from '../webservices';
import cdnUrls from '../constants/cdnUrls';
import { logger } from '../utils';

function* getCountryDataService({ countryCode }) {
    try {
        const lng = yield select((state) => state.translation.language);

        const countryDetailUrl = cdnUrls.getCountryDetail(countryCode, lng);

        const response = yield call(Webservice.requestCDN, countryDetailUrl);

        if (Object.entries(response).length === 0 && response.constructor === Object) {
            yield put({ type: COUNTRY_DETAILS_FAIL });
        }

        yield put({
            type: COUNTRY_DETAILS_SUCCESS,
            name: response.name,
            quickFacts: response.quick_facts,
            code: countryCode,
        });
    } catch (e) {
        logger.log('Error : ', { e });
        yield put({ type: COUNTRY_DETAILS_FAIL });
    }
}

export function* watchCountryDetails() {
    yield takeLatest(GET_COUNTRY_DETAILS, getCountryDataService);
}
