import '@cxeweb/pattern-library/lib/styles/fonts.css';
import '@cxeweb/pattern-library/lib/styles/index.css';
import './main.css';
import './multiline.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { createLogger } from 'redux-logger';
import sagas from './sagas';
import App from './App';
import reducers from './reducers';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
    middlewares.push(createLogger());
}

const store = createStore(reducers, applyMiddleware(...middlewares));

sagaMiddleware.run(sagas);

const root = document.getElementById('root');

createRoot(root).render(
    <Provider store={store}>
        <App />
    </Provider>
);
