import './styles.css';

import React from 'react';
import { Toast } from '../common';
import { Journey } from '../Journey';
import ImageHeader from '../common/ImageHeader';
import Images from '../../images';
import { SocialShare } from '../common';
import Sticky from 'react-sticky-el';
import { Footer } from '../common/Footer';
import { TileCarousel } from '../common/TileCarousel';
import Card from '@cxeweb/pattern-library/lib/components/card';

import './styles.css';
import { useTranslation } from 'react-i18next';

const HomeView = ({
    productLotId,
    showMessage,
    coffeeBagImage,
    roastName,
    coffeeDetails,
    originTitle,
    roastedLocation,
    isEnglish,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Toast title={t('home.welcome.title')} message={t('home.welcome.message')} show={showMessage} />
            <ImageHeader
                image={coffeeBagImage}
                title={roastName}
                content={[
                    <span key={productLotId}>
                        <span className='text-semibold'>{t('home.batchCode', { code: productLotId })}</span>
                    </span>,
                ]}
                detail={t('home.ethicalText')}
                coffeeDetails={coffeeDetails}
            />

            <div className={'pt5'}>
                <Journey originTitle={originTitle} roastedLocation={roastedLocation} />

                <div className='hm-social-share'>
                    <Sticky
                        className='story-stick'
                        mode='bottom'
                        stickyClassName='story-stick--sticky'
                        positionRecheckInterval={25}
                    >
                        <SocialShare
                            postTitle={t('home.socialShare')}
                            postUrl={`https://traceability.starbucks.com/#/`}
                            image={Images.globalAcdemy}
                            style={{
                                paddingBottom: '60px',
                            }}
                        />
                    </Sticky>
                </div>

                <Card disableBoxShadow style={{ background: '#edebe9' }}>
                    <TileCarousel />
                </Card>
                <Footer />
            </div>
        </>
    );
};

export default HomeView;
