import React, { Component } from 'react';
import LoadingIndicator from '@cxeweb/pattern-library/lib/components/loading-indicator';
import { HAFarmRoastView } from './HAFarmRoastView';
import { Detail } from '../common/DetailPage';
import { useTranslation } from 'react-i18next';
import { mapRoastType } from '../../constants/utilFuctions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

const HAFarmRoast = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation();
    const { roastType } = useParams();
    const roast = mapRoastType(roastType);

    return (
        <div style={{ paddingBottom: '1px' }}>
            <Detail title={t(`HAFarm:coffeeNotes.${roast}.coffeeName`)} backTitle={' '}>
                <HAFarmRoastView roast={roast} />
            </Detail>
            <LoadingIndicator active={props.loadingIndicator} />
        </div>
    );
};

export { HAFarmRoast };
